.dashlets {
    max-width: 1132px; padding: 0 30px;

    &__inner {margin: -13px -20px; display: flex; flex-wrap: wrap;}

    &__box {
        border: solid 1px #E4E8EE; border-radius: 4px; position: relative; overflow: hidden; background: #fff; margin: 13px 20px; box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
        width: calc(50% - 40px);

        &--wide {width: calc(100% - 40px);}
    }

    &__head {padding: 12px 120px 12px 20px; position: relative; border-bottom: solid 1px #E4E8EE;}
    &__heading {@include wordup(16px, 23px, $medium, 0); color: #3C4C5A;}

    &__actions {
        display: flex; align-items: center; position: absolute; top: 50%; transform: translateY(-50%); right: 10px;
        button {
            outline: none; border: 0; padding: 5px; display: inline-block; background: none; margin: 5px; user-select: none; cursor: pointer;
            svg {display: block;}
        }
    }

    &__body {
        padding: 30px;

        &--table {padding: 10px 20px 20px;}
        &--right_now {padding: 20px;}
    }
}

.pipeline_block {
    display: flex; justify-content: space-between;
}

.right_now {
    display: flex; justify-content: space-between;

    &__box {border-radius: 4px; background: #DDEAFA; padding: 15px; margin-bottom: 20px;}
    &__ln {color: #156CD8; @include wordup(22px, 22px, $medium, 0.0125em); padding-bottom: 15px;}
    &__num {color: #156CD8; font-size: 44px; letter-spacing: 0.0125em;}

    &__tags {display: flex; flex-direction: column; margin: -4.5px;}
    &__tag {display: flex; margin: 4.5px; align-items: center;}

    &__stat {
        padding: 4px 5px; width: 40px; color: #fff; border-radius: 4px; @include wordup(12px, 16px, $medium, 0.014em); text-align: center;

        &--green {background: #7BB026;}
        &--orange {background: #F0B52C;}
        &--red {background: #F9703E;}
    }

    &__p {@include wordup(12px, 18px, $normal, 0.014em); padding-left: 9px;}

    &__left {width: 182px;}
    &__right {width: calc(100% - 202px);}
}

.dash_email {
    display: flex;
    &__img {}
    &__desc {
        padding-left: 10px;
        p {
            @include wordup(14px, 16px, $normal, 0); color: #63707E; padding: 8px 0;
            strong {font-weight: $medium;}
            a {color: $blue; text-decoration: underline; @include wordup(12px, 26px, $medium, 0.014em);}
        }
    }
}