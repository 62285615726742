$hover-colour: #2186EB;
$parent-active: #9CCCFF;
$child-active: #07449F;

$menu-height: 44px;

.head {

	background: $blue; width: 100%; min-width: 900px;

	&__logo {
		display: block; margin-right: 30px; position: relative;
		svg {display: block; @include trans(200ms);}
		&--hover {position: absolute; top: 0; left: 0; opacity: 0; visibility: hidden; @include trans(200ms);}
		&:hover {
			.head__logo--hover {opacity: 1; visibility: visible;}
			.head__logo--ripple {opacity: 0; visibility: hidden;}
		}
	}

	&__title {@include wordup(24px, 30px, $normal, 0); color: #fff;}

	&__actions {
		margin-left: auto;
		ul {
			display: flex; flex-wrap: wrap;
			li {
				padding-left: 26px;

				&:first-child {padding-left: 0;}

				a {
					display: inline-flex; color: #ffffff; text-decoration: none; align-items: center; height: $menu-height;
					transition: $transition;
				}

				ul {
					position: absolute; top: $menu-height; left: -30px; padding: 15px 0; background: $hover-colour; display: block; min-width: 117px; transition: $transition;
					opacity: 0; visibility: hidden;
					&:before {
						content: ''; display: block; position: absolute; top: -7px; left: 50px; width: 100%; height: 100%;
						width: 0; height: 0; border-style: solid; border-width: 0 6.5px 7px 6.5px; border-color: transparent transparent $hover-colour transparent;
					}
					li {
						display: block; padding-bottom: 10px; padding-left: 0;
						&:last-child {
							padding-bottom: 0;
						}
						a {
							@include wordup(13px, 16px, $normal, 0.015em); padding: 0 18px; height: auto; white-space: nowrap; display: block;
							&:hover {
								color: #07449F;
							}
						}
					}
				}

				&.has_children {
					position: relative;
					> a {
						position: relative; z-index: 2;
						&:after {
							content: ''; position: relative; display: inline-block; background: url('../img/nav-angle-down.svg') no-repeat center center; width: 10px; height: 20px; margin-left: 5px;
						}
					}
					&:hover {
						ul {opacity: 1; visibility: visible;}
					}
				}
			}
		}
	}

	.container {
		display: flex; flex-wrap: wrap; height: $menu-height; align-items: center;
	}

	&--admin {
		background: #000;
	}

	@media only screen and (max-width: 930px) {
		.head__logo {margin-right: 10px;}
	}

}

.action_links {
	&__notifications {
		svg g {opacity: 0;}

		&--active {
			svg g {opacity: 1;}
		}
	}
}

.main_nav {
	ul {
		display: flex; flex-wrap: wrap;

		> li {
			padding: 0 15px;

			&.has_children {
				position: relative;
				&.active {
					& > a {
						color: $parent-active;
						&:after {background-image: url('../img/nav-angle-down-active.svg');}
					}

					&:hover > a {
						color: #ffffff;
						&:after {background-image: url('../img/nav-angle-down.svg');}
					}
				}
				&:hover {
					background: $hover-colour;
					ul {opacity: 1; visibility: visible;}
				}
				> a:after {
					content: ''; position: relative; display: inline-block; background: url('../img/nav-angle-down.svg') no-repeat center center; width: 10px; height: 20px; margin-left: 7px; top: 2px;
				}
			}

			> a {
				position: relative; @include wordup(14px, 21px, $medium, 0.015em); padding: 0; height: $menu-height; z-index: 2;

			}


			a {
				display: inline-flex; color: #ffffff; text-decoration: none; align-items: center;
				transition: $transition;
			}

			ul {
				position: absolute; top: $menu-height; left: 0; padding: 10px 0 15px; background: $hover-colour; display: block;
				opacity: 0; visibility: hidden; min-width: 100%;
				transition: $transition;
				li {
					display: block; padding: 0 0 10px 0;
					&:hover, &.active {
						a {color: $child-active;}
					}
					&:last-child {
						padding-bottom: 0;
					}
					a {
						@include wordup(13px, 16px, $normal, 0.015em); padding: 0 15px; white-space: nowrap; height: auto;
					}
				}
			}
		}
	}
}
