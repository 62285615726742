.pipeline {
	padding: 0 30px;

	&__info {
		text-align: right; margin-left: 48px;
		&--label {@include wordup(14px, 17px, $normal, 0.014em); color: #63707E; padding-bottom: 2px;}
		&--value {@include wordup(20px, 30px, $normal, 0); color: #1F2933;}
	}

	&__boards {
		margin: -12px; display: flex; width: calc(100% + 45px); position: relative; overflow-x: scroll; padding-top: 30px;
	}

	&__board {margin: 12px; width: 288px; flex-shrink: 0;}

	&__add_board {
		padding-top: 12px; margin-top: 16px; border-top: solid 1px #CBD2D9;
		a {@include wordup(14px, 17px, $normal, 0.0125em); color: $blue; text-decoration: none;}
		svg {margin-right: 1px; display: inline-block;}
	}

}

.pipe_tile {
	border-radius: 2px; background: #F5F7FA; padding: 17px 12px 12px; position: relative;
	&:before {
		content: ''; position: absolute; top: 0; left: 0; width: 100%; height: 4px; border-top-left-radius: 2px; border-top-right-radius: 2px;
	}

	&__title {
		@include wordup(14px, 20px, $medium, 0.013em); color: #1F2933;
	}
	&__heading {
		@include wordup(16px, 21px, $medium, 0.013em); color: #1F2933; padding-bottom: 11px;
		&--price {padding-bottom: 0; padding-top: 11px; margin-bottom: -2px;}
	}
	&__contact {
		@include wordup(12px, 18px, $normal, 0.013em); color: #1F2933;
		a {color: $blue; text-decoration: none;}

		&--grey {font-weight: $medium; color: #B5BDC5;}
		&--status {margin-bottom: -2px;}
	}

	&__cards {
		padding-top: 15px; margin: -6px 0; display: flex; flex-direction: column;
	}

	&__card {
		background: #fff; border: solid 1px #CBD2D9; border-radius: 2px; padding: 16px 15px 11px; margin: 6px 0; position: relative;

		&:hover {
			.pipe_tile__delete {opacity: 1; visibility: visible;}
		}
	}

	&__status {
		position: absolute; bottom: 10px; right: 15px; text-align: right;

	}

	&__delete {
		position: absolute; top: 10px; right: 10px; cursor: pointer; user-select: none; padding: 2px;
		opacity: 0; visibility: hidden; transition: $transition;
		svg {display: block;}
	}

	&--green {
		&:before {background: $green;}

		.pipe_tile {
			&__title--sub, &__heading--price, &__contact--status {color: #5A6F34;}
		}
	}
	&--purple {
		&:before {background: $purple;}

		.pipe_tile {
			&__title--sub, &__heading--price, &__contact--status {color: #44076E;}
		}
	}
	&--yellow {
		&:before {background: #F0B52C;}

		.pipe_tile {
			&__title--sub, &__heading--price, &__contact--status {color: #B44D12;}
		}
	}
	&--grey {
		&:before {background: #9AA5B0;}

		.pipe_tile {
			&__title--sub, &__heading--price, &__contact--status {color: #63707E;}
		}
	}
	&--blue {
		&:before {background: $blue;}

		.pipe_tile {
			&__title--sub, &__heading--price, &__contact--status {color: #01337D;}
		}
	}
}

.pipe_manage {
	display: flex; flex-direction: column; margin: -25px 0;

	&__tile {
		margin: 25px 0; border: solid 1px #CBD2D9; border-radius: 2px; padding: 27px 30px 30px 30px; position: relative;
		box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16); transition: box-shadow 400ms cubic-bezier(0.17, 0.67, 0.64, 0.96);
		&:hover {
			box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.16);
		}
	}

	&__cards {
		margin: -8px; display: flex; flex-wrap: wrap;
	}
	&__card {
		margin: 8px; padding: 11px 11px 12px 39px; position: relative; border: solid 1px #CBD2D9; border-radius: 2px;
		width: calc((100% - (16px * 4)) / 4);
		&:before {
			content: ''; position: absolute; top: 7px; left: 7px; border-radius: 100%; width: 24px; height: 24px; display: block;
		}
		p {@include wordup(12px, 15px, $normal, 0.013em); color: #1F2933;}

		&--green:before {background: #7BB026;}
		&--purple:before {background: #7B1CCC;}
		&--yellow:before {background: #F0B52C;}
		&--grey:before {background: #9AA5B0;}
		&--blue:before {background: #49A4F3;}
		&--pink:before {background: #DA127D;}
		&--orange:before {background: #F9703E;}
		&--teal:before {background: #65D6AD;}
		&--violet:before {background: #B898EB;}
		&--pale_pink:before {background: #FF9B9B;}
	}

	&__title {
		padding-bottom: 19px;
		p {color: #1F2933; @include wordup(16px, 21px, $medium, 0.013em);}
	}

	&__controls {
		position: absolute; top: 25px; right: 29px; display: flex; align-items: center;
		p, a {color: $blue; @include wordup(14px, 16px, $normal, 0.0125em); text-decoration: none;}
	}

	&__remove {
		display: inline-block; padding: 2px; cursor: pointer; user-select: none; margin-left: 19px;
		svg {display: block;}
	}

}

.stage_radios {
	display: flex; margin: -7px; padding-top: 9px;
	&__input {
		margin: 7px;

		input {
			position: absolute; top: 0; left: 0; display: block; opacity: 0; visibility: hidden;
			&:checked + label:before {
				opacity: 1; visibility: visible;
			}
		}

		label {
			display: block; width: 26px; height: 26px; border-radius: 100%; position: relative; cursor: pointer; user-select: none;
			&:before {
				content: ''; position: absolute; top: 50%; left: 50%; width: 36px; height: 36px; border-radius: 100%; border: solid 2px #CBD2D9; transform: translate(-50%, -50%); opacity: 0; visibility: hidden; transition: $transition;
			}
		}

		&--checked {
			label:before {opacity: 1; visibility: visible;}
		}

		&--rainbow {background: url('../img/rainbow.png') no-repeat center center; background-size: cover;}

		&--green label {background: #7BB026;}
		&--purple label {background: #7B1CCC;}
		&--yellow label {background: #F0B52C;}
		&--grey label {background: #9AA5B0;}
		&--blue label {background: #49A4F3;}
		&--pink label {background: #DA127D;}
		&--orange label {background: #F9703E;}
		&--teal label {background: #65D6AD;}
		&--violet label {background: #B898EB;}
		&--pale_pink label {background: #FF9B9B;}
	}
}
