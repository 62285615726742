.rcon {
	display: inline-block; background-repeat: no-repeat; background-position: center;

	&--pencil {
		background-image: url('../img/rcon-pencil.svg'); width: 13px; height: 13px;
	}

	&--cog {
		background-image: url('../img/rcon-cog.svg'); background-size: 19px 19px; width: 19px; height: 19px;
	}

	&--trash {
		// Email Builder Properties
		background-image: url('../img/rcon-trash.svg'); background-size: 13.952px 16.216px; width: 13.952px; height: 16.216px;
	}

	&--trash_alt {
		background-image: url('../img/rcon-trash_alt.svg'); background-size: 13.575px 14.972px; width: 13.575px; height: 14.972px;
	}

	&--move {
		background-image: url('../img/rcon-move.svg'); background-size: 17px 17px; width: 17px; height: 17px;
	}

	&--copy {
		background-image: url('../img/rcon-copy.svg'); background-size: 15.222px 17px; width: 15.222px; height: 17px;
	}

	&--move_dots {
		background-image: url('../img/rcon-move_dots.svg'); background-size: 20px 38px; width: 20px; height: 38px;
	}

	&--more_dots {
		background-image: url('../img/rcon-more_dots.svg'); background-size: 21px 5px; width: 21px; height: 21px;
	}

	&--search {
		background-image: url('../img/rcon-search.svg'); background-size: 16px 16px; width: 16px; height: 16px;
	}

	&--arr_down {
		background-image: url('../img/rcon-arr_down.svg'); background-size: 9px 5px; width: 9px; height: 9px;
	}

	&--arr_right {
		background-image: url('../img/rcon-arr_right.svg'); background-size: 5px 9px; width: 5px; height: 9px;
	}

	&--tag_close {
		background-image: url('../img/rcon-tag_close.svg'); background-size: 10px 10px; width: 10px; height: 10px;
	}

	&--close {
		width: 26px; height: 26px; display: flex; justify-content: center; align-items: center; cursor: pointer; user-select: none; position: relative;
		&:before, &:after {
			content: ''; border-bottom: solid 2px #3C4C5A; position: absolute; top: 50%; left: 0; color: #ffffff; width: 100%; transform-origin: center center;
		}
		&:before {transform: translateY(-50%) rotate(-45deg);}
		&:after {transform: translateY(-50%) rotate(45deg);}
	}

	&--cursor {cursor: pointer; user-select: none;}
}
