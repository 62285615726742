.pagination {
	display: flex; align-items: center; justify-content: space-between; width: 100%; padding: 30px 0 0; margin-top: 40px; border-top: solid 1px #E4E7EA;

	&__prev, &__next, &__pages, &__p, &__go {
		@include wordup(14px, 17px, $normal, 0.04em);
	}

	&__controls {
		width: 306px; display: flex; justify-content: space-between;
	}

	&__prev, &__next, &__go {
		color: $blue; position: relative; text-decoration: underline;

		&:before {
			content: ''; background-position: center center; background-repeat: no-repeat; position: absolute; top: 5px; width: 5px; height: 7px; background-size: 5px 7px;
		}
	}

	&__prev {
		padding-left: 10px;
		&:before {background: url('../img/pagination-arrow-left.svg'); left: 0;}
	}

	&__next, &__go {
		padding-right: 10px;
		&:before {background: url('../img/pagination-arrow-right.svg'); right: 0;}
	}

	&__goto {
		display: flex; align-items: center;
		.form_elem__input {width: 42px; text-align: center; margin: 0 10px;}
	}

	&--no_border {border-top: 0; margin-top: 0;}
}
