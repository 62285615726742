.tooltip {
	color: #fff; @include wordup(12px, 15px, $normal, 0.0125em); padding: 4px 8px 12px; z-index: 1; transition: $transition; position: absolute; white-space: nowrap;
	// opacity: 0; visibility: hidden; transform: translateX(-50%) translateY(5px);
	&:before {
		content: ''; width: 0; height: 0; border-style: solid; border-width: 7px 9px 0 0; position: absolute; bottom: 0; left: 0; border-color: #1F2933 transparent transparent transparent;
	}
	&:after {
		content: ''; width: 100%; height: calc(100% - 7px); border-radius: 3px; border-bottom-left-radius: 0; border-top-left-radius: 2px; display: block; position: absolute; top: 0; left: 0; z-index: -1; background: #1F2933;
	}

	&--error {
		&:before {border-color: #CF1224 transparent transparent transparent;}
		&:after {background: #CF1224;}
	}

	&--large {
		font-size: 16px; line-height: 21px; padding: 13px 16px 22px;
		&:before {border-width: 9px 10px 0 0;}
		&:after {height: calc(100% - 9px);}
	}

	&--right {
		&:before {
			left: auto; right: 0;
			border-color: transparent #1F2933 transparent transparent;
			border-width: 0 9px 7px 0;
		}
		&:after {
			border-bottom-left-radius: 3px;
			border-bottom-right-radius: 0;
		}
	}

	&--left_automation {
		padding: 8px 12px 8px 18px;
		&:before {
			top: 50%; transform: translateY(-50%);
			border-width: 5px 7px 5px 0; border-color: transparent #1F2933 transparent transparent;
		}
		&:after {border-radius: 3px; left: auto; right: 0; height: 100%; width: calc(100% - 7px);}
	}

	&--orange {
		&:before {border-color: $orange transparent transparent transparent;}
		&:after {background: $orange;}

		&.tooltip {
			&--right {
				&:before {border-color: transparent $orange transparent transparent;}
			}
		}
	}
}
