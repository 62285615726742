.health {
    display: inline-block; border-radius: 2px; padding: 5px; @include wordup(14px, 16px, $bold, 0.0125em); color: #fff; min-width: 117px;
    &--green {background: #ABDB5E;}
    &--yellow {background: $yellow;}
    &--red {background: $red;}

    &--icon {
        position: relative; padding-left: 35px; text-transform: uppercase; min-width: 0;
        svg {position: absolute; top: 50%; left: 5px; transform: translateY(-50%);}
    }
}

.client_info {
    margin: -30px; display: flex; flex-wrap: wrap;
    &__item {
        margin: 30px;
    }
    &__p {font-size: 14px; margin-top: -5px;}

    &__s_title {@include wordup(14px, 17px, $medium, 0.014em); color: #63707E; padding-bottom: 5px;}

    &__large {@include wordup(24px, 30px, $normal, 0); color: #1F2933;}

    &--small {
        margin: -15px;
        .client_info__item {margin: 15px;}
    }

    &--text_right {text-align: right;}
}

.domain_wrap {
    display: flex; flex-wrap: wrap; align-items: flex-end;
    .form_elem__wrap {margin-bottom: 0;}

    &__text {display: flex; padding: 0 0 8px 8px;}
}

.admin_email_templates {
    display: flex;
    &__right  {width: calc(100% - 270px);}
}