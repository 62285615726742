.settings {
	&__heading {
		@include wordup(18px, 23px, $medium, 0.0125em); padding-bottom: 15px;
	}
	&__p {
		p {@include wordup(14px, 21px, $normal, 0.0125em); color: #6E7A87; padding-bottom: 10px;}
	}

	&__block {
		padding-bottom: 75px;
	}
}

.permissions_set {

	padding: 34px 0; max-width: $max-width;

	&__all {
		padding-left: 25px; padding-bottom: 25px;
		label {color: #3D4C59;}
	}

	&__item_wrap {margin-bottom: 10px;}

	&__item {
		border: solid 1px #CBD2D9; padding: 15px 25px; width: 100%; transition: $transition; display: flex; justify-content: space-between; align-items: center;

		.form_elem__wrap {margin-bottom: 0}
		.form_elem__checkbox + label {font-size: 16px; line-height: 16px; font-weight: $medium;}

		&--selected {
			border-color: #C7E8D9; background: $green-light; transition: $transition;
			.form_elem__label {color: #014D40;}
			.permissions_set__btn {color: #014D40; border-color: #064D40;}
		}

		&--open {
			.permissions_set__btn svg {transform: rotate(180deg);}
		}
	}

	&__btn {
		span {display: inline-block; padding-left: 2px; margin-bottom: 1px;}
		svg {transform-origin: center; display: block;}
	}

	&__select_text {
		margin-left: auto; margin-right: 30px; @include wordup(16px, 28px, $normal, 0); color: #014D40;
		strong {font-weight: $medium;}
	}

	&__content {
		position: relative; overflow: hidden; padding: 0 0 20px 50px; margin-bottom: 20px; border-bottom: solid 2px #DBE1E8;

		&:last-child {
			border-bottom: 0; padding-bottom: 0; margin-bottom: 0;
		}

		&--fields {
			.permissions_set__perm {
				margin-bottom: 48px; position: relative;
				&:after {
					content: ''; position: absolute; bottom: -24px; right: 0; width: 150%; border-bottom: solid 1px #DBE1E8;
				}

				&:last-child {
					margin-bottom: 0;
					&:after {display: none;}
				}
			}
		}
	}

	&__actions {
		display: flex; padding: 32px 0;

		.form_elem__wrap {
			margin-left: 20px;
			&:first-child {margin-left: 0;}
		}

		&--main {
			margin-right: 20px;
			.form_elem__checkbox + label {font-size: 16px; line-height: 16px; font-weight: $medium; color: #1F2933;}
		}
	}

	&__perm {
		padding-left: 30px; //padding-bottom: 45px;

		.form_elem__wrap {
			padding: 15px 0;
		}

		&--item {
			display: flex;

			.form_elem__wrap {
				margin-left: 20px;
				&:first-child {margin-left: 0; margin-right: 20px;}
			}

			p.permissions_set__p {padding: 20px 0 0 25px;}
		}

		&--parent_item {
			.form_elem__checkbox + label {font-size: 16px; line-height: 16px; color: #1F2933;}
		}

		&--input {
			.permissions_set__actions--grey {margin-top: 34px;}
		}

		&--input_wrap {
			display: flex; align-items: flex-start; margin-right: 0!important;
			& > .form_elem__label {margin-top: 34px;}
			.form_elem__label {width: auto;}
		}
	}

	&__perm_children {
		padding-left: 30px;
		.form_elem__checkbox + label {color: #3D4C59;}
	}

	&__p {
		@include wordup(14px, 22px, $normal, 0.014em);
	}

	&__form_elem {
		.form_elem__label {margin-bottom: 5px;}
		.form_elem__select {min-width: 267px;}
	}

	&--selected {
		position: relative; z-index: 1;
		&:before {content: ''; display: block; position: absolute; top: 0; right: 0; width: 150%; height: 100%; background: #F5F7FA; z-index: -1;}
	}

	&--modal_padding {padding: 10px 0 0 0;}

}

.permission_filter {
	position: relative; min-width: 268px; display: inline-block;

	&__delete, &__arr {
		position: absolute; cursor: pointer; user-select: none; padding: 5px;
		svg {display: block;}
	}

	&__delete {
		top: 9px; right: 30px; opacity: 0; visibility: hidden; transition: $transition;
	}

	&__arr {
		top: 10px; right: 9px;
		svg {transform: rotate(180deg);}
	}

	&__dropdown {
		position: absolute; top: 100%; left: 0; width: 100%; height: 250px; display: flex; flex-direction: column; overflow-x: hidden; overflow-y: auto; border: solid 1px #B5BDC5; border-top: 0; z-index: 999;
		opacity: 0; visibility: hidden; transition: $transition;
	}

	&__item {
		border-bottom: solid 1px #CBD2D9; padding: 10px 14px; transition: $transition;
		&:hover, &--selected {background: #E4E7EA;}
	}

	&__title {display: block; @include wordup(14px, 17px, $normal, 0.014em); color: #1F2933; padding-bottom: 9px; cursor: default;}

	&__options {display: flex;}
	&__option:first-child {margin-right: 20px;}

	.form_elem__input {
		font-size: 14px;
		&:focus + .permission_filter__delete {
			opacity: 1; visibility: visible; transition: $transition;
		}
	}

	&--open {
		.permission_filter {
			&__dropdown {opacity: 1; visibility: visible; transition: $transition;}
			&__arr {transform: rotate(0);}
		}
		.form_elem__input {border-bottom-left-radius: 0; border-bottom-right-radius: 0;}
	}

	&--populated {
		.permission_filter {
			&__delete {
				opacity: 1; visibility: visible; transition: $transition;
			}
		}
	}
}

.role_access {

	&__item {
		width: 100%; max-width: 452px; margin-bottom: 12px; position: relative;
		p {@include wordup(14px, 23px, $medium, 0.0125em); color: #3C4C5A;}
		label {cursor: pointer; user-select: none;}

		.f_builder__add, .td_edit__actions {z-index: 4;}

		&:hover, &--hover {
			z-index: 9;
			.role_access__actions {opacity: 1; visibility: visible; transition: $transition;}
			.f_builder__add {opacity: 1; visibility: visible;}
		}

		&--open {
			.role_access {
				&__arr svg {transform: rotate(0deg);}
			}
		}

		&--active_grandparent {
			p {color: $blue;}
		}
	}

	&__move {
		position: absolute; top: 13px; left: 14px;
		svg {display: block;}
	}

	&__inner {
		position: relative; z-index: 3; padding: 9px 15px 9px 39px; height: 45px; border: solid 1px #CBD2D9; display: flex; align-items: center;
	}

	&__arr {
		margin-right: 10px;
		svg {display: block; transform-origin: center; transform: rotate(-90deg);}
	}

	&__actions {
		border: dashed 1px #47A3F3; position: absolute; top: -8px; left: -8px; width: calc(100% + 16px); height: calc(100% + 16px); opacity: 0; visibility: hidden; transition: $transition;

		&--adding {
			.role_access__pop {display: block;}
			.f_builder__search {opacity: 1; visibility: visible;}
		}
	}

	&__edit {
		background: #47A3F3; border-top-right-radius: 5px; border-top-left-radius: 5px;
	}

	&__pop {
		position: absolute; top: 100%; width: 100%; padding-top: 23px; display: none;
		.f_builder__search {
			padding: 25px 15px 15px; max-width: 370px; left: 50%; transform: translateX(-50%); position: relative; top: 0;
			.btn__group {margin-top: 12px;}
		}
	}

	&__children {
		padding-left: 22px;
		&--grandchildren {
			.role_access__item p {font-weight: normal;}
		}
	}

	&__modal_item {
		margin-bottom: 10px; display: flex; align-items: center; justify-content: space-between;
		p {@include wordup(14px, 16px, $normal, 0.0125em); width: 230px;}
		.form_elem__select {width: calc(100% - 230px);}
	}

	&__radio {position: absolute!important; top: 13px; left: 14px;}

}

.user_management {
	&__block {
		padding-bottom: 45px; margin-bottom: 45px; border-bottom: solid 1px #DBE1E8;
		&:last-child {padding-bottom: 0; margin-bottom: 0; border-bottom: 0;}
	}

	&__block_title {
		margin-bottom: 20px; position: relative;
		p {padding-left: 8px;}

		.form_elem__search {position: absolute; top: 50%; transform: translateY(-50%); right: 0; width: 348px;}
	}

	&__heading {@include wordup(18px, 23px, $medium, 0.0125em);}
}

.settings_cf {
	&__fade {
		filter: blur(4px);
		.block {padding: 30px;}
	}

	&__add_section {
		position: absolute; top: 0; left: 0; width: 100%; border-top: solid 2px #94C844;
		span {
			position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border-radius: 20px; background: #94C844; padding: 3px 10px; border-radius: 25px; color: #ffffff; @include wordup(12px, 15px, $medium, 0); cursor: pointer; user-select: none;
			svg {margin-right: 2px;}
		}

		&--bottom {
			top: auto; bottom: 0;
		}
	}

	&__heading {margin-bottom: 35px; color: #1F2933;}

	&__col_wrap {max-width: 865px;}
	&__cols {display: flex; flex-wrap: wrap; margin: 0 -16px; }

	&__col {
		width: calc((100% - (3 * 32px)) / 3); margin-left: 16px; margin-right: 16px;

		.f_builder__item--placeholder {
			padding: 12px;
			p {@include wordup(14px, 26px, $normal, 0.014em);}
		}
	}

	&__item {
		position: relative; z-index: 1; margin-bottom: 40px;
		&:hover {
			.settings_cf__actions {opacity: 1; visibility: visible;}
		}

		.form_elem__wrap {margin-bottom: 0; position: relative; z-index: 1;}
	}

	&__actions {
		position: absolute; top: 0; left: 0; width: calc(100% + 32px); height: calc(100% + 32px); margin: -16px; border: dashed 1px $blue; opacity: 0; visibility: hidden; transition: $transition;
		.f_builder__add {opacity: 1; visibility: visible;}
	}

	&__buttons {
		position: absolute; display: flex; right: -1px; top: 0; transform: translateY(-24px);
		.tb_edit__button {margin-left: 10px;}
	}

	&__field_width {max-width: 274px;}

	&__preview {background: #F5F7FA;}
}

.repeat_inputs {

	&__remove {
		position: absolute; bottom: 12px; right: 0; cursor: pointer; user-select: none; padding: 2px; opacity: 0; visibility: hidden; transition: $transition;
		svg {display: block;}
	}

	&__add {
		display: inline-block; color: $blue; @include wordup(14px, 17px, $normal, 0.0125em); cursor: pointer; user-select: none; margin-top: 10px;
	}

	.form_elem__wrap {
		max-width: 285px; padding-right: 25px;
		&:hover {
			.repeat_inputs__remove {opacity: 1; visibility: visible;}
		}
	}

	.form_elem__label--small {margin-top: 0;}

}

.scf_permissions {
	display: flex; justify-content: space-between;

	&__sidebar {width: 286px; padding-top: 14px;}

	&__all {
		border-radius: 2px; background: #E4E7EA; text-align: center; padding: 10px; margin-bottom: 30px;
		.form_elem__label {margin-bottom: 0; display: inline-block; width: auto; font-weight: $medium; color: #3C4C5A;}
	}

	&__search {margin-bottom: 30px;}

	&__item {
		padding-bottom: 20px;
		.modal_bulk_email__p {padding-bottom: 6px;}
		.form_elem__wrap {margin-bottom: 0;}
	}

	&__btn {
		color: #63707E; font-weight: $normal; padding: 6px 12px 7px; letter-spacing: 0.014em;
		span {display: inline-block; margin-left: 6px; position: relative; top: -1px;}
		svg path {fill: #63707E;}
	}

	.table {width: calc(100% - 326px);}

}

.lcf_lead_list {
	display: flex; margin: 0 -6px;

	&__col {margin: 0 6px;}

	&__add {
		cursor: pointer; user-select: none; padding-top: 12px;
	}

	.role_access {
		&__item {
			max-width: 100%; min-width: 169px;
			.tag_input__p {font-size: 14px; line-height: 17px; font-weight: 400; color: #fff;}
		
		}

		&__pop .f_builder__search {width: 345px;}
	}
}

.freq_used_tiles {
	margin: -7px; display: flex; flex-wrap: wrap;
	&__item {
		border-radius: 4px; border: solid 1px #E4E8EE; margin: 7px; width: calc(33.33% - 14px);
		a, a:hover {text-decoration: none;}
		a {display: block; padding: 20px;}
	}
	&__title {@include wordup(18px, 23px, $medium, 0.0125em); color: #3C4C5A; padding: 7px 0;}
	&__p {@include wordup(14px, 21px, $normal, 0.0125em); color: #6E7A87;}
}

.rep_bar {
	position: relative;
	&__inner {width: 100%; height: 20px; position: relative; margin: 10px 0 13px;}
	&__bar {width: 33.33%; height: 100%; border-bottom: solid 2px transparent;}
	&__front {
		position: absolute; top: 0; left: 0; width: 100%; height: 100%; display: flex; border-radius: 2px; overflow: hidden;
		.rep_bar__bar {
			&:nth-child(1) {background: #84CF44; border-color: #63921A;}
			&:nth-child(2) {background: #F7C948; border-color: #DE911D;}
			&:nth-child(3) {background: #F35627; border-color: #C52706;}
		}
	}
	&__over {
		position: absolute; top: 50%; transform: translateY(-50%); right: 0; height: 37px; background: rgba(255, 255, 255, 0.84); border-left: solid 4px #3C4C5A;
	}
	&__labels {display: flex;}
	&__label {width: 33.33%; text-align: center; @include wordup(12px, 18px, $normal, 0.014em);}
	&__heading {width: 100%; text-align: center; @include wordup(12px, 18px, $normal, 0.014em); color: #7E8996; padding-top: 22px;}
}

.rep_met {
	&__perc {@include wordup(16px, 18px, $normal, 0.014em);}
	&__title {@include wordup(12px, 18px, $normal, 0.014em); padding-left: 5px;}
	&__row {margin: 0 -19px; display: flex; flex-wrap: wrap; padding: 13px 0;}
	&__item {
		padding-left: 10px; position: relative; display: flex; margin: 0 19px; width: 170px;
		&:before {
			content: ''; border-left: solid 4px transparent; height: 13px; top: 50%; transform: translateY(-50%); left: 0; position: absolute;
		}
		&--green {
			&:before {border-color: #63921A;}
			.rep_met__perc {color: #63921A;}
		}
		&--red {
			&:before {border-color: #C52713;}
			.rep_met__perc {color: #C52713;}
		}
		&--yellow {
			&:before {border-color: #DE9122;}
			.rep_met__perc {color: #DE9122;}
		}
		&--pink {
			&:before {border-color: #D9137C;}
			.rep_met__perc {color: #D9137C;}
		}
		&--purple {
			&:before {border-color: #711ABC;}
			.rep_met__perc {color: #711ABC;}
		}
		&--orange {
			&:before {border-color: #DE9122;}
			.rep_met__perc {color: #DE9122;}
		}

		&--link {
			padding-left: 0;
			&:before {display: none;}
			a {color: $blue; @include wordup(12px, 18px, $medium, 0); text-decoration: underline;}
		}
	}
}

.storage {
	&__heading {
		position: relative; padding-right: 100px;
	}
	&__remaining {position: absolute; top: 0; right: 0;}

	&__bar {
		border: solid 1px #E4E8EE; height: 30px; border-radius: 4px; overflow: hidden; display: flex; margin-bottom: 20px;
		.storage__block {
			&:last-child {border-top-right-radius: 4px; border-bottom-right-radius: 4px;}
		}
	}

	// &__block {}

	&__legend {margin: -16px; display: flex; flex-wrap: wrap;}

	&__item {
		width: calc(33.33% - 32px); margin: 16px; position: relative; padding-left: 32px;
		p {color: #6E7A87; @include wordup(14px, 21px, $normal, 0.0125em);}
	}

	&__key {position: absolute; top: 2px; left: 0; border-radius: 4px; width: 20px; height: 17px;}

	&--green {background: #84CF44;}
	&--blue {background: #07449F;}
}