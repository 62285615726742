.foot {
	background: #E4E7EA; padding: 8px 0; width: 100%; height: 28px; min-width: 900px; margin-top: auto;

	p, a {color: #63707E; @include wordup(10px, 12px, $normal, 0.015em);}
	a {
		text-decoration: none;
		&:hover {text-decoration: underline;}
	}

	.container {
		display: flex; flex-wrap: wrap; justify-content: space-between;
	}
}
