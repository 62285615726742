.email_provider {
	display: flex; flex-wrap: wrap; margin: 30px 0 10px;
	&__item {
		width: 200px; margin-left: 44px; cursor: pointer; user-select: none; position: relative;
		&:first-child {margin-left: 0;}

		&:before {content: ''; display: block; position: absolute; top: 0; left: 0; width: 100%; height: 100%; border: solid 1px $border; border-radius: 2px; transition: $transition;}

		figure {
			height: 136px; display: flex; flex-wrap: wrap; justify-content: center; align-items: center;
		}

		p {border-top: solid 1px $border; text-align: center; padding: 5px 0;}

		&:hover {
			&:before {border: solid 2px $green; transition: $transition;}
		}
	}

	&__selection {
		margin-left: 44px; display: flex; align-items: center;
	}

	@media only screen and (max-width: 1080px) {
		&__item {
			width: 31%; margin-left: 3.5%;
		}
		&__selection {
			width: 65.5%; margin-left: 3.5%;
		}
	}

}
