.engagement {

	&__intro {
		display: flex; flex-wrap: wrap; justify-content: space-between; padding-bottom: 58px;
		&--left {width: 60%;}
		&--right {width: 38%;}
	}

	&__tick_box {
		border-radius: 2px; background: #F2FDE0; padding: 10px; margin-bottom: 40px; border: solid 1px #94C844;
		.form_elem__label {
			margin-bottom: 0; display: inline-block; width: auto; color: #5A6F34;
			strong {font-weight: $medium;}
		}
	}

	&__sidebar_heading {@include wordup(14px, 23px, $medium, 0.014em); color: #3C4C5A; padding-bottom: 15px;}

	&__dash {
		border-top: solid 1px #DBE1E8;
		&--pad_top {padding-top: 90px;}
	}

	&__actions {
		display: flex; align-items: center; justify-content: space-between; padding-bottom: 55px;
	}

	&__legend {
		display: flex; align-items: center;

		p {color: #1F2933; padding-right: 32px;}
		p, li {@include wordup(14px, 24px, $normal, 0);}
		ul {
			display: flex; margin: -16px;
			li {
				margin: 16px; padding-left: 12px; position: relative;
				&:before {content: ''; display: block; position: absolute; top: 5px; left: 0; width: 4px; height: 14px;}
			}
		}

		&--yellow {
			color: #B44D12;
			&:before {background: #F0B52C;}
		}
		&--purple {
			color: #44056E;
			&:before {background: #7B18CC;}
		}
		&--green {
			color: #2C4004;
			&:before {background: #94C844;}
		}
		&--blue {
			color: #01337D;
			&:before {background: #48A2F1;}
		}
	}
}

.engage_tiles {
	display: flex; flex-wrap: wrap; margin: -8px;

	&__tile {
		width: calc(50% - 20px); margin: 8px; border: solid 1px #CBD2D9; border-radius: 2px; box-shadow: 0 3px 5px rgba(31, 41, 51, 0.16);
	}

	&__head {
		padding: 19px 23px; background: #F5F7FA; position: relative;

		.ld_oval {position: absolute; top: 23px; right: 23px;}

		.ld_contact {
			&__name {
				display: inline-block; position: relative;
			}
			&__role {margin-bottom: -2px; margin-top: -2px;}

			&--online {
				.ld_contact__name {
					&:before {
						content: ''; position: absolute; top: 9px; right: -9px; width: 5px; height: 5px; border-radius: 100%; background: #94C844;
					}
				}
			}
		}
	}

	&__body {
		padding: 23px; height: 260px; overflow-y: scroll;
	}

	&__title {
		@include wordup(16px, 30px, $normal, 0.014em); color: #1F2933; padding-bottom: 7px; padding-left: 3px;
	}

	&__history {
		display: flex; flex-direction: column; margin: -10px;
		.ld_leads__item {margin: 10px;}
		.ld_leads__title {padding-bottom: 7px;}

		&--green {
			.ld_leads__details:before {background: #94C844;}
		}
		&--purple {
			.ld_leads__details:before {background: #7B18CC;}
		}
		&--yellow {
			.ld_leads__details:before {background: #F0B52C;}
		}
	}
}
