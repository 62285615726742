.modal {

	position: fixed; left: 50%; top: 50%; transform: translate(-50%, -50%); min-width: 420px; border-radius: 2px; overflow: hidden; z-index: 109;
	box-shadow: 0 3px 8px 0 rgba(#1F2933, 0.3);

	&__bar {
		padding: 10px 20px; background: #3C4C5A; position: relative;
	}

	&__body {
		padding: 40px 36px 24px; background: #ffffff;
	}

	&__close {
		position: absolute; top: 14px; right: 14px; width: 26px; height: 26px; display: flex; flex-wrap: wrap; justify-content: center; align-items: center; cursor: pointer; user-select: none;
		&:before, &:after {
			content: ''; border-bottom: solid 2px #ffffff; position: absolute; top: 50%; left: 0; color: #ffffff; width: 100%; transform-origin: center center;
		}
		&:before {transform: translateY(-50%) rotate(-45deg);}
		&:after {transform: translateY(-50%) rotate(45deg);}
	}

	&__bg {
		position: fixed; top: 0; left: 0; width: 100%; height: 100%; user-select: none; cursor: pointer; z-index: 99; background: #1F2933; opacity: 0.5;
	}

	&__heading {@include wordup(20px, 34px, $normal, 0); color: #ffffff;}

	&__profile_image {
		display: flex; align-items: center;
	}
}

// Blur elements when active
.modal_active {
	.site_wrap {filter: blur(4px);}
}

.modal_full {
	position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: #FFFFFF; z-index: 99; overflow-y: scroll; padding-top: 70px;

	&__nav {
		background: #3C4C5A; padding: 19px 30px; display: flex; align-items: center; position: relative; height: 70px; position: fixed; top: 0; left: 0; width: 100%; z-index: 99;
		.head__logo {margin-right: 20px;}
		.x_close {margin-left: auto;}

		p {color: #fff; line-height: 21px;}

		&--actions {
			.x_close {margin-left: 30px;}
		}
	}

	&__nav_actions {
		margin: -5px 0 -5px auto;
		.btn {
			margin-left: 10px;
			&:first-child {margin-left: 0;}
		}
	}

	&__header {
		padding: 30px; border-bottom: solid 1px #DBE1E8;
		&--no_border {border-bottom: 0;}
	}

	&__message {
		padding: 25px 30px 25px 64px; position: relative;

		p {@include wordup(20px, 28px, $normal, 0);}
		svg {display: block; position: absolute; left: 30px; top: 30px;}

		&--warning {
			background: #FFFBEA;
			p, p a {color: #831003;}
		}
	}

	&__head_desc {
		@include wordup(14px, 26px, $normal, 0.014em); color: #3D4C59;
	}

	&__segment_info {
		border-radius: 2px; background: #F5F7FA; border: solid 1px #DBE1E8; padding: 25px 30px; margin-top: 30px;
	}

	&__segment_input {
		margin-bottom: 8px;
		&:last-child {margin-bottom: 0;}
	}

	&__segment_head {
		@include wordup(14px, 23px, $medium, 0.014em); color: #3C4C5A; padding-bottom: 23px;
	}

	&__body {
		display: flex; justify-content: space-between; padding: 50px 0;
	}

	&__sidebar {
		width: 331px; padding: 0 25px 0 30px;
	}

	&__search {
		padding-bottom: 45px;
		.form_elem__toggle--small {margin-top: 15px;}
	}

	&__sidebar_p {
		@include wordup(14px, 17px, $medium, 0.014em); padding-bottom: 20px;
	}

	&__main {
		width: calc(100% - 365px); padding-right: 30px;
	}

	&__cont {
		padding: 0 30px; width: 100%;
		&--pad {padding-top: 30px; padding-bottom: 30px;}
	}

	&__two_col {
		display: flex; justify-content: space-between; width: 100%; padding: 0 30px;
		.modal_full__col {width: 48%;}

		&--add_leads {
			.modal_full__col {width: 50.0388%;}
			.modal_full__col + .modal_full__col {width: 45.671%;}
		}
	}

	&--pull_up {margin-top: -13px;}

	&--lead_detail {
		.modal_full__cont {position: relative; overflow: hidden; padding: 0;}
	}

	&--settings_blur {
		.modal_full__header,
		.ld_contact,
		.ld_tabs__tab:not(.ld_tabs__tab--active),
		.ld_accordion:not(.ld_accordion--not_blur),
		.ld_secondary_contacts {filter: blur(4px);}

		.ld_tabs__tab--active {border-left: solid 1px #DBE1E8;}

		.modal_full__header {padding-left: 56px;}
		.ld_wrap {
			.ld_sidebar {width: 348px; padding: 0 24px 0 56px;}
			.ld_tabs {position: absolute; top: 0; right: 0; height: 100%;}
		}

		.ld_tabs__body {
			border: 0; margin-left: -24px;
			.ld_record_detail {
				padding-left: 24px; position: relative;
				&:before {
					content: ''; position: absolute; top: -1px; left: 24px; width: calc(100% - 24px); height: 100%; border: solid 1px #DBE1E8;
				}
			}
		}
	}
}

.mf_accordion {
	padding-bottom: 20px;

	&__trigger {
		@extend .modal_full__sidebar_p; padding-bottom: 0; padding-right: 20px; cursor: pointer; user-select: none; position: relative;
		svg {position: absolute; top: 5px; right: 0; pointer-events: none;}
	}

	ul {
		display: none;
		li {
			padding-bottom: 13px;
			&:last-child {padding-bottom: 0;}
		}
	}

	&--open {
		.mf_accordion__trigger svg {transform: rotate(180deg);}
		ul {display: block; padding-top: 20px;}
	}
}

.mf_media {
	display: flex; height: calc(100vh - 70px);

	&__image {
		padding: 0 30px; width: calc(100% - 523px);
		figure {
			display: flex; width: 100%; height: 100%; align-items: center; justify-content: center;
			img {display: block; max-width: 100%; height: auto;}
		}
	}

	&__sidebar {
		width: 523px; background: #F5F7FA;

		.ls_tabs__body {overflow-x: hidden; overflow-y: auto;}
	}

	&__filename {padding-bottom: 10px;}
	&__description {width: 100%;}

	&__location {
		display: flex; align-items: center; flex-wrap: wrap;
	}
	&__location_folder {
		color: #3D4C59; @include wordup(12px, 18px, $medium, 0.0125em);
	}
	&__location_move {
		padding-left: 15px; color: $blue; @include wordup(12px, 18px, $medium, 0.0125em); position: relative; cursor: pointer; user-select: none;
	}
	&__location_arr {
		padding: 0 11px;
		svg {display: block; position: relative; top: -1px;}
	}

	&__attributes {
		padding-top: 20px;
	}

	&__attribute {
		padding-bottom: 20px;
	}

	&__attribute_label {color: #7E8996; @include wordup(12px, 15px, $normal, 0.02857em); padding-bottom: 4px;}

	&__attribute_value {color: #1F2933; @include wordup(14px, 17px, $normal, 0.0125em);}

	&__label {
		margin-bottom: 20px; font-weight: $medium; color: #1F2933;
	}

	&__edit {
		.form_elem__label {font-size: 14px; letter-spacing: 0.0125em;}
		.form_elem__wrap:last-child {margin-bottom: 0;}
	}

	&__edit, &__quality {
		padding-bottom: 40px;
	}

	&__quality {
		.form_elem__select {max-width: 268px;}
	}

	&__dimensions {
		.ls_slider__input input {width: 74px;}
	}

	&__units {
		display: flex; align-items: center; padding-bottom: 12px;
	}

	&__link {
		height: 100%; display: flex; align-self: flex-end; padding: 0 4px 4px; margin: 0 12px 12px; cursor: pointer; user-select: none;
		svg {
			display: block;
			g {transition: $transition;}
		}

		&--active {
			svg g {fill: $blue; transition: $transition;}
		}
	}

	&__revert {
		text-decoration: none; @include wordup(12px, 15px, $normal, 0.02857em); color: #6E7A87; cursor: pointer; user-select: none;
	}
}

.modal_bulk_email {
	position: fixed; top: 0; left: 0; width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; z-index: 99; opacity: 0; visibility: hidden; transition: $transition;

	&__bg {
		position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(#1F2933, 0.8); cursor: pointer; user-select: none;
	}

	&__modal {
		width: 100%; max-width: 512px; box-shadow: 0 3px 8px 0 rgba(#1F2933, 0.3); background: #ffffff; position: relative; z-index: 1; border-radius: 2px; overflow: hidden;

		&--wide {max-width: 688px;}
		&--wider {max-width: 1228px;}
	}

	&__header {
		padding: 22px 32px; background: #E5F6FF; position: relative;
		p {color: #00215A;}
		.heading__secondary {line-height: 28px; letter-spacing: 0;}
	}

	&__head_action {
		position: absolute; right: 0; top: 0; height: 100%; padding-right: 55px; display: flex; align-items: center;
		.btn {margin-left: 10px;}
	}

	&__close {
		@extend .x_close; position: absolute; top: 16px; right: 16px; width: 12px; height: 12px;
		&:before, &:after {border-color: #63707E; border-bottom-width: 1px;}
	}

	&__body {
		padding: 20px 32px;
		.form_elem__wrap:last-child {margin-bottom: 0;}

		&--max_height_scroll {
			max-height: 330px; overflow-x: hidden; overflow-y: auto;
		}

		&--scroll {
			overflow-x: hidden; overflow-y: auto;
		}

		&--border {
			border-bottom: solid 1px #E4E7EA;
		}
	}

	&__p {
		@include wordup(14px, 24px, $normal, 0.014em); padding-bottom: 15px;
		&:last-child {padding-bottom: 0;}

		&--medium {font-weight: $medium;}
	}

	&__footer {
		padding: 14px 32px; border-top: solid 1px #E4E7EA;

		.btn__group--align_right {
			.btn {margin-left: 5px;}
		}
	}

	&__message {
		padding: 30px 30px 30px 65px; position: relative;

		svg {display: block; position: absolute; top: 30px; left: 23px;}

		p {@include wordup(14px, 24px, $normal, 0.01429em);}

		&--warning {
			background: $yellow-fill;
			p {color: $yellow-text;}
		}
	}

	&__category {
		max-height: 330px; overflow-x: hidden; overflow-y: auto;
	}

	&__category_add {
		display: flex; align-items: flex-end; padding-bottom: 30px; margin-bottom: 10px; border-bottom: solid 1px #E4E7EA;
		.browse_media__new_folder {width: 45px; padding: 0; border: 0; margin-left: 13px; margin-bottom: 8px;}
	}

	&__category_add_inner {
		width: calc(100% - 80px);
	}

	&--active {
		body {overflow: hidden;}
		.site_wrap {filter: blur(4px);}
		.modal_bulk_email {opacity: 1; visibility: visible; transition: $transition;}
	}

	&--warning {

		.modal_bulk_email {
			&__header {
				background: $yellow-fill; padding-left: 56px; padding-right: 56px;
				p {color: $yellow-text;}
				svg {position: absolute; top: 50%; transform: translateY(-50%); left: 23px; display: block;}
			}
			&__body {
				padding-left: 56px; padding-right: 56px;
			}
		}

	}

	&--browse_media {
		.modal_bulk_email {
			&__modal {max-width: 80%; height: 80%;}

			&__body {display: flex; justify-content: space-between;}
		}
	}

	&--full {
		.modal_bulk_email {
			&__modal {max-width: 80%; max-height: 80%;}
		}
	}

	&--add_call, &--email {
		.modal_bulk_email {
			&__modal {max-width: 688px; max-height: 80%;}
		}
	}

	&--deals {
		.modal_bulk_email {
			&__modal {max-width: 644px; max-height: 80%;}
		}
	}
}
