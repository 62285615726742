.la_checklist {
	border: solid 1px #E1E4E8;

	&__header {
		padding: 25px; position: relative;

		&--warning {
			background: $yellow-fill; padding-left: 64px; padding-right: 64px;
			p {color: $yellow-text; line-height: 28px;}
			svg {position: absolute; top: 30px; left: 30px; display: block;}
		}
	}

	&__body {
		padding: 30px;

		ul {
			li {
				position: relative; padding-left: 25px; margin-bottom: 15px; z-index: 1;
				&.blue_box {
					margin-top: 27px; margin-bottom: 47px;
					&:before {
						content: ''; background: #F2F8FD; position: absolute; top: -23px; left: -16px; width: calc(100% + 32px); height: calc(100% + 46px); z-index: -1;
					}
				}
				p {
					@include wordup(14px, 26px, $normal, 0.014em); padding-bottom: 15px;
					&:last-child {padding-bottom: 0;}
				}
				svg {position: absolute; top: 5px; left: 0;}
				a {text-decoration: none; color: $blue;}
			}
		}
	}

}

.la_block {
	margin-bottom: 40px;

	&__migration {
		border: solid 1px #E1E4E8; position: relative; padding: 25px 64px;
		.heading__secondary {line-height: 28px; padding-bottom: 10px; color: #00215A;}
		a {text-decoration: none; color: $blue;}
		svg {position: absolute; top: 30px; left: 30px; display: block;}
	}

	&__s2 {
		border: solid 1px #E1E4E8; padding: 45px; min-height: 250px; margin-bottom: 30px;
	}

	&__upload {
		display: flex; align-items: center;
		span {margin-left: 15px; @include wordup(14px, 20px, $normal, 0.016em); color: #7E8996;}
	}

	&--border {
		border: solid 1px #E1E4E8; padding: 25px;
	}
}

@keyframes la_loader {
	from {transform: rotate(-45deg);}
	to {transform: rotate(315deg);}

}

.la_csv {
	display: flex; align-items: center; margin-top: 30px;
	.media__file {width: auto;}
	.media__file_type {color: #BC0B6F;}

	&__filename {
		padding-left: 10px; padding-right: 45px;
		&--file, &--records {@include wordup(14px, 16px, $normal, 0.0125em); color: #3D4C59;}
	}

	&__loader {
		display: block; width: 18px; height: 18px; position: relative;
		&:before, &:after {
			content: ''; position: absolute; top: 0; left: 0; width: 100%; height: 100%; border-radius: 100%;
		}
		&:before {
			 border: solid 2px transparent; border-right-color: #3EBD93; border-radius: 100%; z-index: 1; animation: la_loader infinite 1s linear;
		}
		&:after {
			border: solid 2px #CBD2D9;
		}
	}

	&__remove {
		cursor: pointer; user-select: none; margin-left: 24px; padding: 5px;
		svg {display: block;}
	}

	&--uploaded {
		.la_csv__filename--file {color: $blue;}
		.la_csv__loader {opacity: 0; visibility: hidden; transition: $transition;}
	}
}

.la_required_fields {
	p {
		@include wordup(14px, 26px, $normal, 0.014em); padding-bottom: 10px;
		&.la_required_fields__title {font-weight: $medium;}
		a {color: $blue; text-decoration: none;}
	}

	&__list {margin-top: 7px;}

	&__list_item {
		padding-left: 27px; margin-bottom: 17px; position: relative; @include wordup(14px, 17px, $normal, 0.0125em); color: #63707E;
		svg {position: absolute; top: 1px; left: 0; display: block;}

		&--mapped {
			color: #3D4C59; font-weight: $medium;
			.la_table_check {
				g {fill: #3EBD93; stroke: #3EBD93;}
				path {stroke: #fff;}
			}
			.la_required_fields__list_count {color: #199473;}
		}
		&--error {
			color: #3D4C59; font-weight: $medium;
			.la_required_fields__list_count {color: #AB091E;}
		}
	}

	.form_elem__search {margin-bottom: 30px;}
}

.la_duplicates {
	display: flex; justify-content: space-between;
	.form_elem__wrap {display: inline-block;}
	.form_elem__label {width: 16px;}
}

.la_table_notice {
	padding: 16px 24px; background: #E5F6FF; display: flex; justify-content: space-between; align-items: center;
	p {
		color: #00215A;
		strong {font-weight: $medium;}
	}

	&--block {display: block;}

	&--green {
		background: #EFFCF6;
		p {color: #064D40;}
	}
}

.la_table {
	&--th_invalid {color: #AB091E;}
	&--th_valid {color: #199473;}
	&--td_invalid {background: #FFE3E3;}
	&--td_valid {background: #EFFCF6;}
	&--overflow_scroll {overflow-x: scroll; position: relative; width: calc(100% + 30px);}
}

.la_duplicate_action {
	&__heading {color: #1F2933; padding-left: 23px; padding-bottom: 28px;}

	&__option {
		cursor: pointer; user-select: none; position: relative; margin-bottom: 12px;
		label {
			display: block; padding: 24px 65px; border-radius: 2px; transition: $transition; box-shadow: 0px 0 0 1px #CBD2D9;
			&:before, &:after {display: none;}
		}
		svg {
			position: absolute; left: 23px; top: 42px;
			g {fill: #fff; transition: $transition;}
		}

		.form_elem__radio:checked + label {
			box-shadow: 0px 0 0 2px #3EBD93; transition: $transition;
			svg g {fill: #3EBD93; transition: $transition;}
		}
	}

	&__wrap {
		display: flex; margin: -6px; flex-wrap: wrap;
		.la_duplicate_action {
			&__option {
				width: calc(50% - 12px); height: 175px; margin: 6px;
				label {
					display: flex; align-items: center; justify-content: center; flex-direction: column; height: 100%; padding: 20px;
				}
				figure {margin-top: 20px;}
				svg {top: 0; left: 0; position: relative; display: block;}
			}
		}
	}

	&__scroll {
		display: flex; flex-direction: column; overflow-y: scroll; overflow-x: hidden; max-height: 440px;
		.la_duplicate_action {
			&__option {
				margin-left: 2px; margin-right: 2px;
				&:first-child {margin-top: 2px;}
			}
		}
	}

	p {
		@include wordup(16px, 24px, $normal, 0.014em); color: #63707E;
		&.la_duplicate_action__title {@include wordup(16px, 24px, $medium, 0); color: #1F2933; padding-bottom: 5px;}
	}

	&--modal {
		margin: 0 -23px; padding-top: 24px;
	}

	&--workflow {
		padding: 10px 0;
		.la_duplicate_action {
			&__heading {padding-left: 0; font-weight: $medium; color: #1F2933;}
		}
	}
}
