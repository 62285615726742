.calls_sidebar {
	padding-top: 25px;

	&__block {padding-bottom: 20px;}

	&__heading {
		position: relative; padding-right: 65px; padding-bottom: 17px;
		p {@include wordup(14px, 17px, $medium, 0.0125em);}
		span {color: $blue;}
		p, span, p {
			&.calls_sidebar__heading--blue {color: $blue;}
			&.calls_sidebar__heading--red {color: #AB091E;}
		}
		a {text-decoration: none; color: inherit;}

		&--link {
			position: absolute; top: 2px; right: 0; color: $blue!important; text-decoration: none; @include wordup(12px, 15px, $medium, 0.0125em);
		}
		&--accordion_trigger {
			position: absolute; top: 0; right: 0;
		}

		&--blue {color: $blue;}
		&--red {color: #AB091E;}
	}

	&__items {
		&--no_pr {
			.calls_sidebar__item {padding-right: 0;}
		}
	}
	&__item {
		position: relative; padding-right: 25px; padding-bottom: 17px;
		p {@include wordup(14px, 17px, $normal, 0.0125em); color: #63707E;}
		.btn--icon {
			position: absolute; top: 6px; right: 0;
			svg {display: block;}
		}
		.form_elem__label {margin-bottom: 0;}
	}

	&--no_pt {padding-top: 0;}
}

.calls_modal_block {
	border: solid 1px #E1E4E8; padding: 30px 24px;

	&__header {
		border-bottom: solid 1px #E1E4E8; position: relative;
		.calls_modal_block__matches {position: absolute; top: 33px; right: 24px;}
	}

	&__title {@include wordup(14px, 17px, $normal, 0.0125em); color: #3D4C59; padding-bottom: 25px;}

	&__body {
		padding: 24px; border-bottom: solid 1px #E1E4E8;
	}

	&__footer {
		padding: 20px 24px;
		&--align_right {text-align: right;}
	}

	&__matches {color: $blue; @include wordup(14px, 24px, $normal, 0);}

	&__tab_head {
		display: flex; align-items: center; border-bottom: solid 1px #E1E4E8; padding: 0 24px;
	}
	&__tab_item {
		margin-left: 60px; padding: 16px 0; position: relative;
		&:before {
			content: ''; border-bottom: solid 2px $blue; position: absolute; bottom: 0; left: 0; width: 100%; opacity: 0; visibility: hidden; transition: $transition;
		}
		&:first-child {margin-left: 0;}

		.form_elem__wrap {margin-bottom: 0;}

		&--active {
			&:before {opacity: 1; visibility: visible; transition: $transition;}
		}

		&--green {color: #199473;}
	}

	&__checkbox {
		&:last-child {
			.form_elem__wrap {
				margin-bottom: 0;
				.form_elem__label {margin-bottom: 0;}
			}
		}
	}

	&__schedule_fields {
		padding-left: 20px; padding-top: 20px;
		.ntier2__block {border-bottom: 0; padding: 10px 0 10px 22px;}
	}

	.heading__secondary {padding-bottom: 13px;}

	&--multi {
		padding: 0;
		.calls_modal_block {
			&__header {
				padding: 30px 24px 5px;
			}
		}
	}

	&--max_width {max-width: 955px;}
}

.calls_filter_criteria {
	background: #F5F7FA; padding: 26px 16px; margin-bottom: 18px; position: relative; display: flex; justify-content: space-between;

	&__close {
		position: absolute; top: 10px; right: 10px; cursor: pointer; user-select: none; padding: 2px;
		svg {display: block;}
	}

	.form_elem__wrap {width: 48%; margin-bottom: 0;}
	.tb_edit__add_here {bottom: 0; left: 0; transform: translateY(10px);}
}
