.login_welcome {
	display: flex; flex-wrap: wrap; height: 100%; height: 100vh; min-width: 900px; background: $bg_grey;

	&__grey {
		background: #F5F7FA; width: 63.396%; display: flex; flex-wrap: wrap; justify-content: center; align-items: center; position: relative;
	}

	&__form {
		display: flex; flex-direction: column; width: 100%; max-width: 348px;
	}

	&__greeting {
		color: #52606D; text-align: center; margin-bottom: -5px;
	}

	&__heading {
		@include wordup(28px, 36px, $normal, 0); text-align: center; border-bottom: solid 1px $border; padding-bottom: 16px; margin-bottom: 20px;
		&--left_no_border {
			text-align: left; border-bottom: 0; padding-bottom: 5px; margin-bottom: 0;
		}
	}

	&__message {
		@include wordup(14px, 22px, $normal, 0.015em); color: #3D4C59; padding-bottom: 35px;
		a {
			padding-left: 15px; color: $blue; font-size: 12px; line-height: 16px; letter-spacing: 0.025em; text-decoration: none;
		}
	}

	&__blue {
		background: $blue; padding: 5%; width: 36.604%; color: #ffffff; display: flex; justify-content: center; flex-direction: column; position: relative; z-index: 0;
		&:before {
			content: ''; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: url('../img/login-waves.svg') repeat; z-index: -1; background-size: 90px;
		}
	}

	&__quote {
		@include wordup(24px, 34px, $normal, 0); font-style: italic; padding: 20px 0 10px; max-width: 345px; color: #ffffff;
	}

	&__author {
		@include wordup(14px, 30px, $normal, 0); position: relative; padding-left: 25px; color: #ffffff;
		&:before {
			content: ''; display: block; position: absolute; top: 50%; left: 0; width: 20px; border-bottom: solid 1px #ffffff;
		}
	}

	&__link_wrap {
		text-align: center; padding-top: 20px; display: block;
	}

	&__link {
		color: $blue; @include wordup(12px, 16px, $normal, 0.025em); text-decoration: none;
	}

	&__copyright {
		position: absolute; bottom: 20px; left: 0; width: 100%; display: block; text-align: center; @include wordup(10px, 12px, $normal, 0.0); color: #7B8794;
		a {color: #7B8794;}
	}

	&__password_reset {
		padding-top: 20px; display: flex; justify-content: space-between; align-items: center; flex-wrap: wrap;
	}

	.form_elem__6digit {padding-bottom: 30px;}

	&--remember_padding {
		padding: 4px 0 8px;
	}
}
