.t_builder {
	position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: #F5F7FA; z-index: 99;

	&__nav {
		background: #3C4C5A; padding: 15px 30px; display: flex; align-items: center; position: relative;

		.head__logo {margin-right: auto;}
	}

	&__cont {
		display: flex; height: 100%;
	}

	&__body {
		width: calc(100% - 386px);
	}

	&__sidebar {
		width: 386px; box-shadow: -3px -3px 6px 0 #D9DADB;
	}

	&__heading {
		padding: 20px 56px; border-bottom: solid 1px #DBE1E8; position: relative;

		.heading__primary {padding-bottom: 0;}
	}

	&__devices {
		position: absolute; top: 14px; right: 56px; display: flex;
		svg {display: block; cursor: pointer; user-select: none; transition: $transition;}
	}
	&__device {
		position: relative;
		&--active {
			.t_builder__device_state--active {opacity: 1; visibility: visible; transition: $transition;}
			.t_builder__device_state--inactive {opacity: 0; visibility: hidden; transition: $transition;}
		}
	}
	&__device_state {
		&--active {opacity: 0; visibility: hidden;}
		&--inactive {opacity: 1; visibility: visible; position: absolute; top: 0; left: 0;}
	}
	&__desktop {
		top: 9px; position: relative;
	}

	&__email {
		height: 100%; padding: 30px 26px; position: relative; display: flex; flex-direction: column;
	}
	&__email_cont {overflow-y: auto; overflow-x: hidden; padding: 0 30px; min-height: 100%;}
	&__email_frame {border: solid 1px #DBE1E8; min-height: 100%; background: #EEECEC;}

	.form_elem__input {font-size: 14px; color: #63707E;}
}

.t_builder_tab {
	&__header {
		display: flex; align-items: center;

		a {
			display: flex; flex-grow: 1; align-items: center; justify-content: center; height: 46px; @include wordup(14px, 20px, $normal, 0.015em); text-decoration: none; padding: 0 20px; border: solid 1px #BDBEBF; background: #E2E5EA;

			&:not(:first-child) {border-left: 0}

			&:last-child {padding-right: 25px;}

			&.active {
				background: #F5F7FA; border-color: #F5F7FA; border-right-color: #BDBEBF;
			}
		}
	}

	&__content {
		display: flex; flex-direction: column; position: relative;
	}

	&__scroll {
		overflow-y: auto; overflow-x: hidden;
	}
}

.tb_content_tiles {
	display: flex; flex-wrap: wrap; padding: 25px;

	figure {
		width: 29.766%; height: 100px; margin-bottom: 25px; border: solid 1px #CBD2D9; display: flex; justify-content: center; align-items: center; flex-direction: column; background: #ffffff; border-radius: 2px; margin-left: 5.351%; cursor: pointer; user-select: none;
		box-shadow: $tb_box_shadow;
		transition: $transition-slow;

		&:nth-child(3n+1) {margin-left: 0;}

		&:hover {
			box-shadow: $tb_box_shadow-hover;
		}

		span {@include wordup(10px, 15px, $normal, 0); text-transform: uppercase; padding-top: 5px;}
	}
}

.tb_rows {
	padding: 25px;

	&__item {
		display: flex; justify-content: space-between; padding: 10px; border: solid 1px #CBD2D9; height: 69px; background: #ffffff; margin-bottom: 20px; cursor: pointer; user-select: none;
		box-shadow: $tb_box_shadow; transition: $transition-slow;

		&:hover {
			box-shadow: $tb_box_shadow-hover;
		}
		span {background: #E4E7EA; display: block; height: 100%;}
	}

	&--full {width: 100%;}
	&--half {width: 48.5%;}
	&--quarter {width: 23%;}
	&--third_1 {width: 31.5%;}
	&--third_2 {width: 65%;}

}

.tb_props {
	&__item {
		padding: 25px; border-bottom: solid 1px $border; position: relative;

		.heading__secondary {padding-bottom: 20px;}

		.options_trigger {
			position: absolute; top: 35px; right: 25px; @include wordup(12px, 15px, $normal, 0.015em); color: #63707E; cursor: pointer; user-select: none;
		}

		& > .form_row:last-child {
			.form_elem__wrap {margin-bottom: 0;}
		}

		.form_add_new__code {background: #fff; padding: 20px; height: 300px;}

		&--columns {

			padding: 0;

			& > .heading__secondary {padding: 25px; background: #E2E5EA;}

			.tb_props__item {padding: 25px; border-bottom: 0;}

		}
	}

	&__row {
		padding-bottom: 30px;
		&:last-child {padding-bottom: 0;}
		.form_elem__wrap {margin-bottom: 0;}
	}

}

.tb_edit {
	display: block; border: dashed 2px transparent; width: 100%; position: relative; padding: 3px;

	&__button, &__label {
		background: $blue-light; border-top-right-radius: 5px; border-top-left-radius: 5px; height: 24px; display: flex; justify-content: center; align-items: center; cursor: pointer; user-select: none;
	}
	&__button {
		width: 24px; position: relative; display: flex; justify-content: center; align-items: center;
	}
	&__label {color: #ffffff; @include wordup(12px, 15px, $medium, 0); padding: 0 12px;}

	&__actions {
		position: absolute; display: flex; right: -2px; top: 0; transform: translateY(-24px);

		.tb_edit__button {margin-left: 10px;}
	}

	&__move {
		position: absolute; top: 50%; left: 0; transform: translate(-30px, -50%);
		.tb_edit__button {border-top-right-radius: 0; border-bottom-left-radius: 5px; height: 30px; width: 30px;}

		&--top {top: 2px; transform: translateX(-30px);}
	}

	&__add_here {
		display: flex; justify-content: center; position: absolute; z-index: 1; width: 100%; bottom: 100%; transform: translateY(-5px); cursor: pointer; user-select: none;
		&:before {
			content: ''; display: block; position: absolute; top: 9px; left: 0; width: 100%; border-bottom: solid 2px #94C844; z-index: -1;
		}

		span {
			background: #94C844; padding: 3px 10px; border-radius: 25px; color: #ffffff; @include wordup(12px, 15px, $medium, 0);
		}

		&--bottom {
			bottom: 0; transform: none; left: 0;
			&:before {top: 100%;}
			span {transform: translateY(50%); display: inline-block;}
		}
	}

	&--hover {background: rgba(254, 208, 181, 0.29); border: dashed 2px $blue-light;}

	&--border {border: dashed 2px $blue-light;}

	&--inner {
		position: absolute; top: 0; left: 0; height: 100%;
	}

}

.tb_drag {
	background: #E5F6FF; margin: 0 auto; width: 100%; max-width: 650px; min-height: 80px; display: flex; justify-content: center; align-items: center; padding: 5px; flex-wrap: wrap;
	p {color: #4A84D3; @include wordup(16px, 21px, $normal, 0);}
}

.tb_text {
	// p {@include wordup(12px, 26px, $normal, 0.015em); color: #3D4C59; font-family: Arial;}
}

.tb_placeholder {
	display: flex; flex-wrap: wrap; max-width: 650px; width: 100%; margin: 0 auto; position: relative;

	&__item {
		position: relative;

		&--drag, &--img, &--video, &--html {
			height: 200px; display: flex; justify-content: center; align-items: center; position: relative;
		}
		&--drag {
			background: #E5F6FF;
			p {color: #4A84D3; @include wordup(16px, 21px, $normal, 0);}
		}
		&--img, &--video, &--html {
			 background: #D8D8D8;
		}
		&--img_cta {
			background: #D8D8D8; height: 200px;
			svg {display: block; position: absolute; top: 20%; left: 50%; transform: translateX(-50%);}
			a {text-decoration: none;}
		}

		img {max-width: 100%; display: block;}
	}

	&__img_cta_wrap {

	}

	&--one_col {
		.tb_placeholder__item {
			width: 100%;
			&--img {height: 406px;}
			&--video {height: 365px;}
			&--html {height: 170px;}
			&--img_cta {height: 406px;}
		}
	}

	&--two_col {
		.tb_placeholder__item {
			width: 50%;
			&--img_cta {
				svg {width: 42px; height: 29.52px; top: 15%;}
			}
		}
	}

	&--three_col {
		.tb_placeholder__item {
			width: 33.33%;
			&--img, &--video {
				height: 135px;
			}
			&--drag {height: 162px;}
			&--html {
				height: 134px;
				svg {width: 56px; height: 42.3px;}
			}
			&--img_cta {height: 133px;}
			&--img svg {width: 56px; height: 39.38px;}
			&--video svg {width: 48px; height: 45.09px;}
		}
	}

	&--four_col {
		.tb_placeholder__item {
			width: 25%;
			&--img, &--video, &--img_cta, &--drag, &--html {
				height: 100px;
			}
			&--img svg {width: 56px; height: 39.38px;}
			&--video svg {width: 48px; height: 45.09px;}
			&--html svg {width: 56px; height: 42.3px;}
		}
	}

	&--three_one_col {
		.tb_placeholder__item {
			&:first-child {
				width: 75%;
				&.tb_placeholder__item {
					&--img, &--img_cta {height: 305px;}
					&--img svg {width: 94px; height: 66.114px;}
					&--video {
						height: 274px;
						svg {width: 80px; height: 75.17px;}
					}
					&--drag {height: 162px;}
					&--html {
						height: 170px;
						svg {width: 94px; height: 71px;}
					}

					&--img_cta svg {top: 17%;}

					.tb_block__menu ul {flex-direction: row;}
				}
			}
			width: 25%;
			&--img, &--video, &--img_cta, &--html {
				height: 100px;
			}
			&--drag {height: 162px;}
			&--img svg {width: 56px; height: 39.38px;}
			&--video svg {width: 48px; height: 45.09px;}
			&--html svg {width: 56px; height: 42.3px;}

			.tb_block__menu ul {flex-direction: column;}

		}
	}

	&--one_three_col {
		.tb_placeholder__item {
			&:first-child {
				width: 25%;

				&.tb_placeholder__item {
					&--img, &--video, &--img_cta, &--html {
						height: 100px;
					}
					&--drag {height: 162px;}
					&--img svg {width: 56px; height: 39.38px;}
					&--video svg {width: 48px; height: 45.09px;}
					&--html svg {width: 56px; height: 42.3px;}
				}

				.tb_block__menu ul {flex-direction: column;}
			}

			width: 75%;

			&--img, &--img_cta {height: 305px;}
			&--img svg {width: 94px; height: 66.114px;}
			&--video {
				height: 274px;
				svg {width: 80px; height: 75.17px;}
			}
			&--drag {height: 162px;}
			&--html {
				height: 170px;
				svg {width: 94px; height: 71px;}
			}

			&--img_cta svg {top: 17%;}

			.tb_block__menu ul {flex-direction: row;}
		}
	}

	&--half_2_quarters {
		.tb_placeholder__item {
			width: 25%;
			&--img, &--video, &--img_cta, &--drag, &--html {
				height: 100px;
			}
			&--img svg {width: 56px; height: 39.38px;}
			&--video svg {width: 48px; height: 45.09px;}
			&--html svg {width: 56px; height: 42.3px;}

			.tb_block__menu ul {flex-direction: column;}

			&:first-child {
				width: 50%;
				&.tb_placeholder__item {
					&--img, &--video, &--html, &--img_cta {height: 200px;}

					&--img svg {width: 94px; height: 66.114px;}
					&--video svg {width: 80.055px; height: 75.224px;}
					&--img_cta svg {width: 42px; height: 29.52px; top: 15%;}
					&--html svg {width: 94px; height: 71px;}
				}

				.tb_block__menu ul {flex-direction: row;}
			}
		}
	}

	&--2_quarters_half {
		.tb_placeholder__item {
			width: 25%;
			&--img, &--video, &--img_cta, &--drag, &--html {
				height: 100px;
			}
			&--img svg {width: 56px; height: 39.38px;}
			&--video svg {width: 48px; height: 45.09px;}
			&--html svg {width: 56px; height: 42.3px;}

			.tb_block__menu ul {flex-direction: column;}

			&:last-child {
				width: 50%;
				&.tb_placeholder__item {
					&--img, &--video, &--html, &--img_cta {height: 200px;}

					&--img svg {width: 94px; height: 66.114px;}
					&--video svg {width: 80.055px; height: 75.224px;}
					&--img_cta svg {width: 42px; height: 29.52px; top: 15%;}
					&--html svg {width: 94px; height: 71px;}
				}

				.tb_block__menu ul {flex-direction: row;}
			}
		}
	}

	&--one_third_two_thirds {
		.tb_placeholder__item {
			width: 33.33%;
			&--img, &--video {
				height: 135px;
			}
			&--drag {height: 162px;}
			&--html {
				height: 134px;
				svg {width: 56px; height: 42.3px;}
			}
			&--img_cta {height: 133px;}
			&--img svg {width: 56px; height: 39.38px;}
			&--video svg {width: 48px; height: 45.09px;}

			&:last-child {
				width: 66.66%;
				&.tb_placeholder__item {
					&--img, &--video, &--html, &--img_cta {height: 200px;}

					&--img svg {width: 94px; height: 66.114px;}
					&--video svg {width: 80.055px; height: 75.224px;}
					&--img_cta svg {width: 42px; height: 29.52px; top: 15%;}
					&--html svg {width: 94px; height: 71px;}
				}
			}
		}
	}

	&--two_thirds_one_third {
		.tb_placeholder__item {
			width: 33.33%;
			&--img, &--video {
				height: 135px;
			}
			&--drag {height: 162px;}
			&--html {
				height: 134px;
				svg {width: 56px; height: 42.3px;}
			}
			&--img_cta {height: 133px;}
			&--img svg {width: 56px; height: 39.38px;}
			&--video svg {width: 48px; height: 45.09px;}

			&:first-child {
				width: 66.66%;
				&.tb_placeholder__item {
					&--img, &--video, &--html, &--img_cta {height: 200px;}

					&--img svg {width: 94px; height: 66.114px;}
					&--video svg {width: 80.055px; height: 75.224px;}
					&--img_cta svg {width: 42px; height: 29.52px; top: 15%;}
					&--html svg {width: 94px; height: 71px;}
				}
			}
		}
	}
}

.t_builder__email_frame--mobile_preview {
	.tb_placeholder {
		max-width: 360px;

		&--two_col .tb_placeholder__item {width: 100%;}
		&--three_col .tb_placeholder__item {width: 100%;}
		&--four_col .tb_placeholder__item {width: 100%;}
		&--one_three_col .tb_placeholder__item {
			width: 100%;
			&:first-child {width: 100%;}
			.tb_block__menu ul {flex-direction: column;}
		}
		&--three_one_col .tb_placeholder__item {
			width: 100%;
			&:first-child {
				width: 100%;
				.tb_block__menu ul {flex-direction: column;}
			}
		}
		&--half_2_quarters .tb_placeholder__item {
			width: 100%;
			&:first-child {
				width: 100%;
				.tb_block__menu ul {flex-direction: column;}
			}
		}
		&--2_quarters_half .tb_placeholder__item {
			width: 100%;
			&:first-child {
				width: 100%;
			}
			.tb_block__menu ul {flex-direction: column;}
		}
		&--one_third_two_thirds .tb_placeholder__item {
			width: 100%;
			&:first-child {
				width: 100%;
			}
			.tb_block__menu ul {flex-direction: column;}
		}
		&--two_thirds_one_third .tb_placeholder__item {
			width: 100%;
			&:first-child {
				width: 100%;
			}
			.tb_block__menu ul {flex-direction: column;}
		}

	}
}

.tb_block {
	&__button {
		a {text-decoration: none; display: inline-block;}
	}

	&__social {
		display: flex; justify-content: center; flex-wrap: wrap;
		a {margin: 5px;}
	}

	&__menu {
		ul {
			display: flex; justify-content: center; flex-wrap: wrap;
			a {display: block;}
		}
	}
}

.social_channel {

	&__item {
		padding: 15px 15px 15px 10px; background: #ffffff; position: relative; margin-bottom: 20px;

		&:last-child {margin-bottom: 0;}
	}

	&__heading {
		display: flex; align-items: center;
		.rcon {margin-right: 10px; margin-left: -2px;}
		figure {
			margin-right: 10px;
			img {display: block;}
		}
	}

	&__title {letter-spacing: 0.0125em;}

	&__options {
		margin-left: auto; @include wordup(12px, 15px, $normal, 0.015em); color: #63707E; cursor: pointer; user-select: none;

		&--remove {
			color: $red; font-weight: $medium; margin-right: -28px; text-align: right;
		}
	}

	&__fields {
		padding: 17px 28px;
	}
}

.html_warning {
	border-radius: 2px; border: solid 1px $yellow-border; background: $yellow-fill; padding: 8px 36px; position: relative;

	&__title {@include wordup(16px, 26px, $medium, 0.025em);}
	&__p {@include wordup(14px, 22px, $normal, 0);}

	svg {position: absolute; top: 13px; left: 10px; display: block;}
	p {color: $yellow-text;}
}

.legal_edit {
	position: relative; padding: 5px 80px 0 0;

	&__value {
		p {@include wordup(14px, 26px, $normal, 0); color: #3D4C59;}
	}

	.btn {position: absolute; top: 0; right: 0; min-width: 76px;}
}

.small_tabs {
	&__tabs {
		display: flex; flex-wrap: wrap;
	}
	&__tab {
		flex-grow: 1; text-align: center; cursor: pointer; user-select: none; padding: 10px 10px 15px 10px; background: #E2E5EA; transition: $transition;
		&--active {background: #F5F7FA; transition: $transition;}
		p {@include wordup(14px, 17px, $normal, 0.014em);}
	}
}
