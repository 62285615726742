/* RESET */
/* ----------------------------------------- */
/* Global reset */
/* Based upon 'reset.css' in the Yahoo! User Interface Library: http://developer.yahoo.com/yui */
*, html, body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, label, fieldset, input, p, blockquote, th, td {margin: 0; padding: 0;}
table { border-collapse:collapse; border-spacing:0 }
h1, h2, h3, h4, h5, h6, ul, ol, li, form, p, filedset, figure, img{ margin:0; padding:0;  border:0; font-weight:normal;}
fieldset, img { border:0 }
address, caption, cite, code, dfn, em, strong, th, var { font-style:normal; font-weight:normal }
ol, ul, li {list-style: none;}
caption, th {text-align: left;}
h1, h2, h3, h4, h5, h6 {font-size: 100%; font-weight: normal;}
q:before, q:after { content:''}
input[type="submit"], input[type="text"], input[type="email"], input[type="number"], input[type="password"], button, textarea {-webkit-appearance: none;}
select::-ms-expand {display: none;}

b, strong {font-weight: $bold;}
em {font-style: italic;}
abbr[title], acronym[title] {text-decoration: none;}
a img {border: none; display: block;} /* Gets rid of IE's blue borders */

*, *:before, *:after {
  box-sizing: inherit;
}

html {-webkit-text-size-adjust: none; -webkit-appearance: none; box-sizing: border-box; background: #ffffff; font-size: 10px;}

body, html {
	margin: 0; padding: 0; height: 100%;
}
body {@include main_font; color: $body; -webkit-font-smoothing: antialiased;}
p {@include wordup(16px, 30px, $normal, 0); color: $text;}
a {outline: none; text-decoration: underline; color: $body; transition: $transition;}
a:hover {text-decoration: underline;}

.preload * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

/*--- Grid ---*/
.container {
	padding: 0 30px; width: 100%;
}
