.table {
	width: 100%;

	&__link {
		text-decoration: none; color: $blue; @include wordup(14px, 16px, $normal, 0.0125em);
		&--underline {text-decoration: underline;}
	}

	&__arr_down {
		display: inline-block; vertical-align: middle; padding: 3px; cursor: pointer; user-select: none;
		svg {display: block;}
	}

	&__icon {
		display: inline-block;
		svg {display: block;}
		&--middle {vertical-align: middle;}
		&--top {vertical-align: top;}
	}

	&__more_dots {
		padding: 5px; cursor: pointer; user-select: none; display: flex; align-items: center; justify-content: center;
		svg {display: block;}
	}

	&__star_wrap {
		display: flex; align-items: center; position: relative;
		.form_elem__wrap {margin-right: 14px;}
		.btn--icon svg {display: block;}

		&--star {
			svg {
				display: block;
				g, path {transition: $transition;}
			}
		}

		&--star_active, &--star:hover {
			svg {
				g, path {fill: #F0B429; transition: $transition;}
			}
		}

		&--tick {
			svg {
				g, path, circle {transition: $transition;}
			}
		}
		&--tick_active, &--tick:hover {
			svg {
				g, path, circle {transition: $transition;}
				g {
					stroke: none;
					circle:first-of-type {fill: #3EBD93;}
				}
				path {stroke: #fff;}
			}
		}

		&--todo {width: 82px;}
	}

	table {
		width: 100%;
		thead {
			&.table__header {
				th {
					&.table__header--title {
						@include wordup(16px, 26px, $normal, 0); color: #1F2933; border-bottom: 0; padding: 17px 15px;
					}
				}
				tr:last-child th {border-bottom: solid 2px #CBD2D9;}
			}
			th {
				@include wordup(14px, 16px, $normal, 0.0125em); border-bottom: solid 2px #CBD2D9; padding: 13px 8px;

				.rcon {margin-left: 3px; cursor: pointer; user-select: none;}

				.form_elem__wrap {
					display: flex; align-items: center;
					.form_elem__label {width: auto;}
					.rcon, .table__arr_down {margin-left: 5px;}
				}
			}
		}
		thead, tbody {
			th, td {
				&.status {
					width: 94px;
				}
				&.actions {
					width: 14px;
					.rcon, svg {opacity: 0; @include trans(200ms); cursor: pointer; user-select: none;}
					.btn--icon {display: flex; align-items: center; justify-content: center;}

					.actions__inner {display: flex; align-items: center;}
				}
			}
		}
		tbody {
			tr {
				box-shadow: 0px 3px 5px 0 rgba(#1F2933, 0); transition: $transition;

				&:hover {
					box-shadow: 0px 3px 5px 0 rgba(#1F2933, 0.16); transition: $transition;
					td.actions {
						.rcon, svg {opacity: 1;}
					}
				}

				&.table__row_spacer {
					height: 40px;
					&:hover {box-shadow: none;}
				}

				&.table__row_header {
					background: #F2F8FD;
					td {@include wordup(16px, 21px, $normal, 0.013em); color: #1F2933;}
				}

				&.lead_selected {
					background: #F5F7FA;
				}
			}
			td {
				@include wordup(14px, 16px, $normal, 0.0125em); color: #3D4C59; padding: 16px 8px; border-bottom: solid 1px #E4E7EA;
				p {
					padding-bottom: 5px; @include wordup(14px, 16px, $normal, 0.0125em);
					&:last-child {padding-bottom: 0;}
					&.body_text--info {font-size: 12px;}
				}

				&.grey {color: #7B8794;}
				&.green {color: #507712;}
				&.purple {color: #690CB0;}

				&.ld_green {
					color: #507712;
					p {color: #507712;}
				}
				&.ld_purple {
					color: #44076E;
					p {color: #44076E;}
				}
				&.ld_orange {
					color: #B44D12;
					p {color: #B44D12;}
				}
				&.ld_grey {
					color: #63707E;
					p {color: #63707E;}
				}
				&.ld_blue {
					color: #03337D;
					p {color: #03337D;}
				}

				&.disabled {opacity: 0.5;}
			}
		}
	}

	&--disabled {
		td {background: #F5F7FA;}
	}

	&--selected {
		td {background: $green-light;}
		.la_table_check {
			g {fill: #3EBD93; stroke: #3EBD93;}
			path {stroke: #fff;}
		}
	}

	// &--lead_selected {
	// 	td {background: #F5F7FA;}
	// }

	&--top_border {border-top: solid 2px #CBD2D9;}

	&--border {
		border: solid 1px #E1E4E8;
		table tbody tr:last-child td {border-bottom: 0;}
	}

	&--align_right {text-align: right;}
	&--valign_top {vertical-align: top;}

	&--grey {color: #7B8794;}

	&--blue {
		border: solid 1px #E4E7EA; background: #F2F8FD;
		table {
			thead {
				th {padding-left: 15px; padding-right: 15px;}
			}
			tbody {
				td {
					padding-left: 15px; padding-right: 15px;
					&.actions {padding-left: 8px;}
				}
			}
		}
	}

	&--large_padding {
		table {
			thead {
				th {padding-left: 15px; padding-right: 15px;}
			}
			tbody {
				td {
					padding-left: 15px; padding-right: 15px;
					&.actions {padding-left: 8px;}
				}
			}
		}
	}

	&--la_review {
		table {
			thead {
				th {
					padding-left: 16px; padding-right: 16px; font-weight: $medium; color: #3D4C59; border-color: #D9DCDD;
				}
			}
			tbody {
				td {
					padding: 16px; border-right: solid 1px #D9DCDD; border-bottom-color: #D9DCDD; width: 272px!important;
					&:last-child {border-right: 0;}
				}
			}
		}
	}

	&--small {
		table {
			thead {
				th {padding: 11px 15px;}
			}
			tbody {
				td {padding: 8px 15px;}
			}
		}
	}

	&--medium_header table thead th {font-weight: $medium;}

	&--todo_check {width: 44px; padding-right: 0!important;}

	&--no_pad {padding: 0;}
}
