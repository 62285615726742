.sidebar {
	min-width: 200px; max-width: 320px; width: calc(22% - 15px);

	&__heading {
		@include wordup(14px, 18px, $medium, 0.0125em); color: $blue; padding-bottom: 20px;
	}

	&__links {
		& > li > a {@include wordup(14px, 23px, $medium, 0.0125em); color: #3C4C5A;}

		li {
			padding-bottom: 19px;
			&.active > a {color: $blue;}
		}

		ul {
			padding-left: 10px; padding-top: 4px;
			li {
				padding-bottom: 5px;
			}
		}

		a {
			@include wordup(14px, 20px, $normal, 0.0125em); margin-bottom: 10px; text-decoration: none; color: #6E7A87;
			&:hover {
				color: $blue;
			}
		}
	}
}

@media only screen and (max-width: 1040px) {

	.sidebar {
		width: 200px;
	}

}

.sidebar_ns {
	// &__lhs {width: 280px; padding-right: 20px;}
	&__lhs {width: 270px;}
	&__rhs {width: calc(100% - 310px); margin-left: 40px;}
}

.dataview {

	&__bg {
		position: fixed; top: 0; left: 0; width: 100%; height: 100%; user-select: none; cursor: pointer; z-index: 2; background: #1F2933;
		opacity: 0; visibility: hidden; transition: $transition;
	}

	&__inner {
		position: relative; z-index: 2; background: #fff; height: 100%;
	}

	&__sidebar {
		position: fixed; top: 0; right: 0; width: 320px; height: 100%; transform: translate3d(100%, 0, 0); transition: $transition; z-index: 99; background: #fff;
	}

	&__trigger {
		position: absolute; bottom: 50px; left: -55px; display: block; cursor: pointer; user-select: none;
	}

	&__head {
		background: #F5F7FA; padding: 24px; border-bottom: solid 1px #E4E7EA;
		p {color: #3C4C5A; @include wordup(20px, 30px, $normal, 0); padding-bottom: 20px;}
	}

	&__close {
		padding: 2px; cursor: pointer; user-select: none; position: absolute; top: 20px; right: 20px;
		svg {display: block;}
	}

	&__body {
		padding: 24px;
	}

	&__checkbox {
		display: flex; align-items: center; margin-bottom: 5px;
		.form_elem__label {width: auto;}

		&--active {
			.dataview__title {color: $blue;}
		}
		&--open {
			.dataview__arr svg {transform: rotate(0deg); transition: $transition;}
		}
	}

	&__users {
		transition: $transition;
	}

	&__user {
		&--has_children {
			// & > .dataview__checkbox .dataview__title {color: #3C4C5A; font-weight: $medium;}
		}
		.dataview {
			&__users {padding-left: 20px; opacity: 0; height: 0; overflow: hidden;}
			&__checkbox {
				&--open + .dataview__users {opacity: 1; height: auto; transition: $transition;}
			}
		}
	}

	&__arr {
		margin-left: 10px; padding: 2px; cursor: pointer; user-select: none;
		svg {display: block; transform-origin: center; transform: rotate(-90deg); transition: $transition;}
	}

	&__title {
		padding-left: 10px; @include wordup(14px, 26px, $normal, 0.0125em); color: #6E7A87;
		&--em {font-weight: $medium; color: #3C4C5A;}
	}

	&--open {
		.site_wrap {filter: blur(4px);}
		body {position: relative; overflow: hidden;}

		.dataview {
			&__bg {opacity: 0.5; visibility: visible;}

			&__sidebar {
				transform: translate3d(0%, 0, 0);
			}
		}
	}
}
