$max-width: 1750px;

$bg_grey: #F5F7FA;
$border: #B5BDC5;
$border-grey: #E4E7EA;
$border1: #CBD2D9;

$red: #CF1224;
$red-fill: #FFE3E3;

$purple: #8718E0;
$purple-fill: #E9DDFD;

$green: #7BB026;
$green-border: #3EBD93;
$green-light: #EFFCF6;
$green-fill: #F2FDE0;

$pink: #DA127D;
$pink-fill: #FFE3EC;

$blue: #156CD8;
$blue-light: #47A3F3;
$blue-border: #7CC4FA;
$blue-fill: #E5F6FF;
$blue-text: #00215A;

$orange: #F35627;

$yellow: #F7C948;
$yellow-border: #FADB5F;
$yellow-fill: #FFFBEA;
$yellow-text: #831003;

$rcal_video_call: $yellow;
$rcal_video_call_light: #ffecb8;

$body: #1F2933;
$text: #3D4C59;

$thin: 200;
$light: 300;
$normal: 400;
$regular: $normal;
$book: $normal;
$medium: 500;
$semi: 600;
$bold: 700;

@mixin main_font {font-family: aktiv-grotesk, sans-serif;}

$btn-default: #E4E7EA;
$btn-default-hover: #cbd2d9;
$btn-default-color: #3C4C5A;

$btn-primary: $orange;
$btn-primary-hover: #CC3E12;

$btn-delete: $red;
$btn-delete-hover: #831003;

$transition: all 200ms cubic-bezier(.17,.67,.64,.96);
$transition-slow: all 400ms cubic-bezier(.17,.67,.64,.96);

$input-error: $red;
$input-error-bg: #FFE3E3;

$input-valid: $green;
$input-valid-bg: $green-light;

$tb_box_shadow: 0 2px 10px 0 rgba(#000000, 0);
$tb_box_shadow-hover: 0 8px 20px 0 rgba(0, 0, 0, 0.16);
