%form_elem__label {
	@include wordup(14px, 16px, $normal, 0.025em); color: #63707E;
}

.form_elem {

	&__wrap {
		margin-bottom: 18px; position: relative; display: block;

		&--error {
			&:after {
				content: ''; position: absolute; bottom: 10px; right: -27px; width: 18px; height: 18px; background: url('../img/input-invalid.svg') no-repeat center center; background-size: 18px;
			}
		}

		&--valid {
			&:after {
				content: ''; position: absolute; bottom: 10px; right: -27px; width: 18px; height: 18px; background: url('../img/input-valid.svg') no-repeat center center; background-size: 18px;
			}
		}

		&--max_width {max-width: 348px;}
		&--extension {max-width: 88px;}

		&--no_mar {
			margin-bottom: 0;
			.form_elem__label {margin-bottom: 0;}
		}

		&--flex_wrap {
			display: flex; flex-wrap: wrap;
			.form_elem__label {width: auto; margin-bottom: 0;}
		}
		&--flex_center {align-items: center;}

		&--select_input {
			display: inline-flex;
			.form_elem__select {
				display: inline-block; background: #CBD2D9; border-top-right-radius: 0; border-bottom-right-radius: 0; margin-right: -1px;
				select {width: auto; display: inline-block; font-weight: $medium; color: #3C4C5A;}
			}
			.form_elem__input {width: 330px; border-top-left-radius: 0; border-bottom-left-radius: 0;}
		}
	}

	&__group {
		display: flex;
		.form_elem__wrap {width: 100%;}

		&--three_col {
			flex-wrap: wrap; margin: -10px;
			.form_elem__wrap {
				margin: 10px; width: calc(33.33% - 20px);
				&--spacer {margin-right: calc(33.33% + 10px);}
			}
		}
	}

	&__check_space {
		padding-top: 12px;
	}

	&__label {
		 width: 100%; display: block; padding: 0 2px; margin-bottom: 5px; @extend %form_elem__label; user-select: none; cursor: pointer;

		 &--small {font-size: 12px; color: #7E8996; margin-top: -4px;}
		 &--small_text {font-size: 12px; color: #7E8996;}
		 &--terms {
			 font-size: 10px!important; line-height: 17px!important; color: #63707E;
			 a {color: $blue;}
		 }
	}

	&__input {
		border: solid 1px $border; border-radius: 2px; display: block; width: 100%; height: 40px; padding: 0 10px; background: #ffffff; position: relative; outline: none; @include wordup(14px, 40px, $normal, 0.015em); @include main_font; color: #616E7C;
		transition: $transition;

		&[type="number"] {
			&::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
				-webkit-appearance: none;  margin: 0;
			}
		}

		&:focus, &.focus {
			box-shadow: 0px 0px 5px 0px rgba(21, 108, 216, 0.6);
		}

		&.invalid {
			border-color: $input-error; background: $input-error-bg;
		}
		&.valid {
			border-color: $input-valid; background: $input-valid-bg;
		}

		&--small {max-width: 56px;}

		&--dark {color: #1F2933;}
	}

	&__input_arr {
		position: relative;
		.btn--icon {position: absolute; top: 50%; transform: translateY(-50%); right: 10px;}
	}

	&__input_icon {
		position: relative;
		.btn--icon {position: absolute; top: 50%; transform: translateY(-50%);}

		&--left {
			.form_elem__input {padding-left: 34px;}
			.btn--icon {left: 10px;}
		}
		&--right {
			.form_elem__input {padding-right: 34px;}
			.btn--icon {right: 10px;}
		}
	}


	&__input_lrg {
		border: 0; border-bottom: solid 1px $border; border-radius: 0; display: block; width: 100%; height: 45px; padding: 0 2px; background: #ffffff; position: relative; outline: none; @include wordup(20px, 45px, $normal, 0); @include main_font;
	}

	&__input_clear {
		border: 0; border-radius: 0; display: block; height: 20px; padding: 0; background: transparent; position: relative; outline: none; @include wordup(14px, 20px, $normal, 0); @include main_font;
		&--small {
			font-size: 14px; color: #3D4C59;
		}
	}

	&__input_inline {
		display: inline-block; position: relative;

		.form_elem__input_clear {display: inline-block;}

		&--icon {
			padding-right: 20px;
			svg, .rcon {position: absolute; top: 50%; transform: translateY(-50%); right: 0;}
		}
	}

	&__checkbox {
		position: absolute; top: 0; left: 0; visibility: hidden;
		& + label {
			position: relative; padding-left: 23px; cursor: pointer; user-select: none; font-size: 12px; line-height: 15px; min-height: 16px;
			&:before {
				content: ''; width: 16px; height: 16px; border-radius: 2px; border: solid 1px $border; display: block; position: absolute; top: 0; left: 0; background: #ffffff;
			}
			&:after {
				content: ''; background-image: url('../img/light-check.svg'); background-position: center; background-repeat: no-repeat; position: absolute; top: 2px; left: 2px; width: 12px; height: 12px;
			}
		}

		&:checked {
			& + label {
				&:before {
					background: $blue;
				}
			}
		}

		&--single {
			& + label {margin-bottom: 0; padding-left: 14px;}
		}

		&--large {
			& + label {
				padding-left: 27px; font-size: 14px; letter-spacing: 0.0125em;
				&:after {background-image: url('../img/large-check.svg');}
			}
		}

		&--purple {
			&:checked + label:before {background: $purple;}
		}
		&--green {
			&:checked + label:before {background: $green;}
		}
		&--pink {
			&:checked + label:before {background: $pink;}
		}
		&--yellow {
			&:checked + label:before {background: $rcal_video_call;}
		}
	}

	&__check_list {margin: -9px 0;}
	&__check_list_item {margin: 9px 0;}

	&__toggle {
		display: flex; width: 100%; position: relative;

		input {
			position: absolute; top: 0; left: 0; visibility: hidden;
			&:checked {
				& + label {
					&:before {background: $blue;}
					&:after {transform: translateX(20px);}

					span:before {content: attr(data-active);}
				}
			}
		}

		span {
			display: inline-block; @include wordup(14px, 14px, $normal, 0.015em); position: absolute; top: 9px; left: 50px;
			&:before {content: attr(data-inactive);}
		}

		label, span {cursor: pointer; user-select: none;}

		label {
			@include wordup(20px, 34px, $normal, 0.02em); position: relative; padding-left: 105px; min-height: 34px;

			&:before {
				content: ''; display: inline-block; width: 42px; height: 22px; border-radius: 13px; background: #9AA5B0; position: absolute; top: 6px; left: 0; transition: $transition;
			}

			&:after {
				content: ''; position: absolute; top: 9px; left: 3px; border-radius: 100%; width: 16px; height: 16px; background: #fff; transition: $transition;
			}
		}

		&--small {
			input:checked + label:after {transform: translateX(13px);}
			span {top: 7px; left: 38px;}
			label {
				padding-left: 40px; @include wordup(14px, 29px, $normal, 0.028em); min-height: 29px;
				&:before {height: 16px; width: 29px;}
				&:after {width: 12px; height: 12px; top: 8px; left: 2px;}
			}
		}

		&--no_label {
			label {
				padding-left: 76px; min-height: 22px;
				&:before {top: 0;}
				&:after {top: 3px;}
			}
			span {top: 3px;}
		}
	}

	&__radio {
		position: absolute; top: 0; left: 0; visibility: hidden;
		& + label {
			position: relative; padding-left: 23px; cursor: pointer; user-select: none; font-size: 12px; line-height: 15px; min-height: 16px;
			&:before {
				content: ''; width: 16px; height: 16px; border-radius: 100%; border: solid 1px $border; display: block; position: absolute; top: 0; left: 0; background: #ffffff;
			}
			&:after {
				content: ''; position: absolute; top: 4px; left: 4px; width: 8px; height: 8px; background: $blue; border-radius: 100%; opacity: 0;
			}
		}

		&:checked {
			& + label {
				&:after {
					opacity: 1;
				}
			}
		}
	}

	&__select {
		border: solid 1px $border; border-radius: 2px; height: 40px; position: relative; background: #ffffff; z-index: 1;
		&:after {
			content: ''; background-image: url('../img/select-arrow.svg'); background-position: center; background-repeat: no-repeat; position: absolute; top: 16px; right: 11px; width: 12px; height: 7px; background-size: 12px 7px; z-index: -1;
		}
		select {
			 display: block; width: 100%; height: 38px; padding: 0 30px 0 10px; background: #ffffff; position: relative; outline: none; @include wordup(14px, 38px, $normal, 0.015em); border: 0; border-radius: 0; appearance: none; color: #616E7C; @include main_font; background: transparent; cursor: pointer; user-select: none;
		}

		&--small {
			height: 32px;
			&:after {top: 12px;}
			select {height: 30px; line-height: 30px;}
		}
	}

	&__colour {
		position: relative; max-width: 136px;
		.form_elem__input {padding-left: 50px;}
		.colour_circle {
			position: absolute; top: 6px; left: 14px; width: 28px; height: 28px; border-radius: 100%; cursor: pointer; user-select: none; display: block;
			&__arr {
				margin-left: 8px; cursor: pointer; user-select: none;
				svg {display: block;}

			}
			&--light {border: solid 1px $border;}
		}

		&--small {
			display: flex; align-items: center;
			.colour_circle {
				position: relative; top: 0; left: 0;
			}
		}
	}

	&__alignment {
		display: flex; padding-top: 15px;
	}
	&__alignment_button {
		margin-left: 36px; cursor: pointer; user-select: none;

		&:hover, &.active {
			svg path {fill: $blue-light;}
		}

		&:first-child {margin-left: 0;}

		svg path {transition: $transition;}
	}

	&__quantity {
		max-width: 115px; position: relative;
		input {text-align: center;}
		.qty_inc, .qty_dec {
			height: 100%; border-radius: 2px; border: solid 1px $border; position: absolute; height: 100%; width: 40px; top: 0; background: #F5F7FA; cursor: pointer; user-select: none;
			&:before {font-size: 14px; color: #63707E; position: absolute; top: 0; left: 0; width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;}
		}
		.qty_inc {
			right: 0;
			&:before {content: '+';}
		}
		.qty_dec {
			left: 0;
			&:before {content: '-';}
		}
	}

	&__unit {
		max-width: 115px; position: relative;
		.unit {
			height: 100%; border-radius: 2px; border: solid 1px $border; position: absolute; height: 100%; width: 40px; top: 0; right: 0; background: #F5F7FA; display: flex; align-items: center; justify-content: center; font-size: 14px; letter-spacing: 0.025em; color: #63707E;
		}
	}

	&__social_img {
		display: flex; align-items: center; margin-bottom: 12px;
		figure {
			border-radius: 2px; border: solid 1px $border; margin-right: 10px; position: relative; overflow: hidden;
			img {display: block; max-width: 100%;}
		}

		.social_img_text {
			&__label {@include wordup(12px, 15px, $normal, 0.015em);}
			&__p {
				@include wordup(11px, 20px, $normal, 0.0125em);
				a {font-weight: $medium; color: $blue; text-decoration: none;}
			}
		}

		&--block {
			display: block;
			figure {display: inline-block; margin-right: 0; margin-bottom: 5px;}
		}
	}

	&__range {
		display: flex; align-items: center; justify-content: space-between;
		.range {
			&__bar {
				width: calc(100% - 40px); height: 2px; position: relative; border-top: solid 1px #B5BDC5; border-bottom: solid 1px #7E8996;
			}
			&__progress {
				border-top: solid 1px #47A3F3; border-bottom: solid 1px #03449F; width: 45%; position: absolute; top: -1px; left: 0; height: 2px;
			}
			&__value {width: 30px; @include wordup(14px, 17px, $normal, 0.025em); color: #63707E;}
			&__slider {
				position: absolute; top: 50%; left: 45%; transform: translateY(-50%); width: 16px; height: 16px; background: #7E8996; box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.16); border-radius: 100%;
			}
		}
	}

	&__search {
		position: relative;
		.form_elem__input {font-size: 14px; padding-right: 30px;}
		button {
			position: absolute; top: 0; right: 0; border: 0; outline: none; height: 40px; width: 40px; display: flex; align-items: center; justify-content: center; background-color: transparent; cursor: pointer; user-select: none;
		}

		&--max_width {max-width: 348px; width: 100%;}
	}

	&__error_message, &__info {
		display: block; @include wordup(12px, 16px, $normal, 0.025em);  padding: 0 2px; margin-bottom: 5px; display: block;
	}

	&__error_message {
		color: $input-error;
	}
	&__info {
		color: #7E8996;
	}

	&__6digit {
		display: flex; flex-wrap: wrap;
		.form_elem__wrap {
			width: 40%; margin-bottom: 0;
		}
		.btn {align-self: flex-end; margin-left: 10px;}
	}

	&__chars {
		@include wordup(12px, 16px, $normal, 0.025em); color: #7E8996; text-align: right; padding-top: 6px; margin-bottom: -22px;
	}

	&__upload {
		display: flex; align-items: center;
		span {@include wordup(12px, 20px, $normal, 0.016em); padding-left: 16px; color: #7E8996;}
	}

	&--center {text-align: center;}

	&--max_width {max-width: 310px;}

}

textarea.form_elem__input {
	resize: none; height: 98px;

	&.form_elem__input--tall_textarea {height: 166px;}
}

.form_group {
	display: flex; flex-wrap: wrap;

	.form_elem__wrap {
		width: 274px; margin-right: 30px;
		&:last-child {margin-right: 0;}
	}

}

.form_row {

	&--imap {
		display: flex; justify-content: space-between;
		.form_elem__wrap {
			width: calc(100% - 105px);
			& + .form_elem__wrap {width: 75px;}
		}
	}

	&--size_weight_colour {
		display: flex; justify-content: space-between;

		.form_elem__wrap {
			&:nth-child(1) {width: 56px;}
			&:nth-child(2) {width: 120px;}
			&:nth-child(3) {width: 136px;}
		}
	}

	&--font_weight {
		display: flex; justify-content: space-between;

		.form_elem__wrap {
			&:nth-child(1) {width: calc(100% - 140px);}
			&:nth-child(2) {width: 120px;}
		}
	}

	&--inline {
		display: flex; flex-wrap: wrap;
	}
}

.form_elem__wrap--error .show_pass__text {background: $input-error-bg;}
.show_pass {
	position: relative;

	&__text {
		position: absolute; bottom: 1px; right: 1px; cursor: pointer; user-select: none; color: $blue; @include wordup(12px, 16px, $normal, 0.025em); text-decoration: none; background: #ffffff; height: 38px; padding: 0 15px; display: flex; align-items: center;
	}
}

.pass_strength {
	position: absolute; left: 105%; bottom: -2px; width: 225px;
	p {color: #7B8794; @include wordup(12px, 16px, $normal, 0.025em); padding-bottom: 3px;}

	ul {
		display: flex; flex-wrap: wrap; justify-content: space-between; width: 100%;
		li {
			width: 71px; @include wordup(10px, 16px, $normal, 0.01em); color: #B4BABF; padding-top: 7px; position: relative;
			&:before {
				content: ''; display: block; position: absolute; top: 0; left: 0; width: 100%; height: 6px; border-radius: 3px; background: #DDD;
			}

			&:nth-child(1).active:before {background: #CF1224;}
			&:nth-child(2).active:before {background: #F7C948;}
			&:nth-child(3).active:before {background: #3EBD93;}
		}
	}

	@media only screen and (max-width: 960px) {
		position: relative; left: 0; bottom: 0; margin-top: 15px;
	}
}

.form_output {
	display: flex; justify-content: space-between; width: 100%; flex-wrap: wrap;

	&__col {width: 48%;}

	&__wide {width: 100%;}

	.form_elem__wrap {margin-bottom: 37px;}
}

.ft_btn_grid {
	display: flex; flex-wrap: wrap; margin: -12px;

	&__item {
		height: 100px; display: flex; margin: 12px; position: relative; width: calc((100% - (24px * 3)) / 3);
		input {
			opacity: 0; visibility: hidden; position: absolute; top: 0; left: 0;
			&:checked + label {
				box-shadow: 0px 0px 0 2px #3EBD93;
			}
		}
		label {
			display: flex; flex-grow: 1; flex-direction: column; align-items: center; justify-content: center; height: 100%; border-radius: 2px; box-shadow: 0px 0px 0 1px #CBD2D9; cursor: pointer; user-select: none; transition: $transition;
		}
		figure {height: 27px; display: flex; align-items: center;}
		p {@include wordup(10px, 20px, $normal, 0); text-transform: uppercase; color: #52606D;}
	}
}

.tag_input {
	display: block; border: solid 1px $border; border-radius: 2px; width: 100%; background: #ffffff; position: relative; outline: none; @include wordup(14px, 40px, $normal, 0.015em); @include main_font; color: #616E7C; transition: $transition; box-shadow: 0px 0px 0px 0px rgba(21, 108, 216, 0);

	&__p {@include wordup(14px, 17px, $normal, 0.0125em); color: #fff;}

	&__wrap {display: flex; flex-wrap: wrap; margin: -3px; padding: 6px;}

	&__item {
		display: inline-flex; border-radius: 2px; background: #52606D; position: relative; padding: 4px 30px 5px 8px; margin: 3px;
	}

	&__remove {
		position: absolute; top: 0; right: 5px; display: flex; align-items: center; height: 100%; padding: 3px; cursor: pointer; user-select: none;
		svg {display: block;}
	}

	&--focus {
		box-shadow: 0px 0px 5px 0px rgba(21, 108, 216, 0.6); transition: $transition;
	}
}
