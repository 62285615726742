.browse_media {
	&__folder_name {
		color: #3C4C5A; @include wordup(14px, 23px, $normal, 0.0125em); cursor: pointer; user-select: none; padding-right: 20px; display: block;
		text-overflow: ellipsis; white-space: nowrap; overflow: hidden;
	}

	&__new_folder {
		position: relative; margin-bottom: 10px; padding-bottom: 10px; border-bottom: solid 1px #E4E7EA;
		svg {position: absolute; top: 6px; left: 0; display: block;}
	}

	&__new_folder_text {
		color: $blue; @include wordup(14px, 23px, $medium, 0.0125em); cursor: pointer; user-select: none; padding-left: 15px; display: block;
	}

	&__folders {
		// width: 240px;
		width: 270px;
		display: flex; flex-direction: column;
		p.browse_media__heading {
			color: $blue; padding-bottom: 19px; @include wordup(14px, 23px, $medium, 0.0125em);
			&.grey {color: #3C4C5A;}
			&.lower {padding-top: 23px;}
		}
	}

	&__folders_scroll {
		position: relative; overflow-y: auto; overflow-x: hidden;
	}

	&__files {
		//width: calc(100% - 280px);
		width: calc(100% - 310px);
		display: flex; flex-direction: column;
		.form_elem__search {max-width: 268px; margin-bottom: 47px;}
	}

	&__files_scroll {
		position: relative; overflow-y: auto; overflow-x: hidden;
	}

	&__folder_item {
		position: relative; padding-left: 53px; margin-bottom: 10px; width: 100%;

		input[type="radio"] {
			position: absolute; opacity: 0; visibility: hidden;

			& + label {
				position: relative;
				&:before {
					content: ''; width: 16px; height: 16px; border-radius: 100%; border: solid 1px $border; display: block; position: absolute; top: 3px; right: 0; background: #ffffff;
				}
				&:after {
					content: ''; position: absolute; top: 7px; right: 4px; width: 8px; height: 8px; background: $blue; border-radius: 100%; opacity: 0;
				}
			}

			&:checked + label:after {opacity: 1;}
		}

		&--open {
			.browse_media {
				&__arr {
					svg {transform: rotate(90deg);}
				}
			}
		}

		&--parent {
			.browse_media__folder_name {font-weight: $medium;}
		}

		&--border {
			padding-bottom: 10px; border-bottom: solid 1px #E4E7EA;
		}

		&--border {
			padding-bottom: 10px; border-bottom: solid 1px #E4E7EA;
		}

		&--category {padding-left: 0;}

		&--child {padding-left: 11px;}

		&:hover {
			.browse_media__more_dots {opacity: 1; visibility: visible; transition: $transition;}
		}
	}

	&__arr {
		position: absolute; top: 2px; left: 2px; padding: 5px 5px 5px 0; cursor: pointer; user-select: none;
		&--hidden {
			svg {opacity: 0; visibility: hidden;}
		}
		svg {display: block;}
	}

	&__folder_icon {
		position: absolute; top: 3px; left: 19px; cursor: pointer; user-select: none;
		svg {display: block;}
	}

	&__files_wrap {
		display: flex; flex-wrap: wrap; margin: 0 -13px;
	}

	&__file_item {
		background: #F5F7FA; width: 175px; height: 175px; margin: 0 13px 26px; cursor: pointer; user-select: none; position: relative;
		img {width: 100%; height: 100%; object-fit: contain;}
		input {
			position: absolute; top: 0; left: 0; opacity: 0;
			&:checked + label {
				span {opacity: 1; visibility: visible; transition: $transition;}
			}
		}
		label {
			position: absolute; top: 0; left: 0; width: 100%; height: 100%;
			&:before {
				content: ''; position: absolute; top: 0; left: 0; width: 100%; height: 100%; border: solid 2px $blue; z-index: 2; opacity: 0; visibility: hidden; transition: $transition;
			}

			&:hover {
				&:before {
					opacity: 1; visibility: visible; transition: $transition;
				}
			}

			span {
				width: 16px; height: 16px; border-radius: 2px; background: $blue; border: solid 1px #9AA5B0; position: absolute; top: 8px; left: 8px; display: flex; align-items: center; justify-content: center;
				opacity: 0; visibility: hidden; transition: $transition;
				svg {display: block;}
			}
		}
	}

	&__disk_space {
		margin-top: auto;
		p {
			@include wordup(12px, 15px, $normal, 0.0125em); color: #1F2933;
			span {@include wordup(14px, 15px, $medium, 0.02225em);}
		}
	}

	&__more_dots {
		display: block; padding: 5px 0; cursor: pointer; user-select: none; position: absolute; top: 5px; right: 0; opacity: 0; visibility: hidden; transition: $transition; z-index: 2;
		svg {display: block;}
	}
}

.disk_space {
	display: block; width: 100%; position: relative; background: #CBD2D9; height: 4px; border-radius: 2px; overflow: hidden; max-width: 213px; margin-bottom: 10px; margin-top: 20px;
	&__used {background: #F0B429; position: absolute; top: 0; left: 0; height: 100%;}
}

.drop_files {
	margin-bottom: 5px; display: block;

	&__inner {
		display: flex; align-items: center; justify-content: center; flex-direction: column; height: 100px; background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%237E8996' stroke-width='2' stroke-dasharray='4' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e"); cursor: pointer; user-select: none; padding-top: 5px;
	}

	&__drag_text {
		position: relative; padding-left: 40px; display: inline-block;
		svg {
			position: absolute; top: 50%; left: 0; transform: translateY(-50%);
		}
		p {@include wordup(16px, 26px, $normal, 0); color: #7E8996;}
	}

	&__or {
		@include wordup(14px, 26px, $normal, 0); color: #7E8996;
		a {color: $blue;}
	}

	&__info {
		display: flex; justify-content: flex-end; align-items: center; padding-top: 10px;
		svg {display: block; margin-right: 5px;}
		p {color: #06449F; @include wordup(12px, 22px, $normal, 0.0125em);}
	}
}

.media {
	display: flex; justify-content: space-between; flex-wrap: wrap; padding-top: 30px; width: 100%;

	&__files {
		// width: calc(100% - 320px);
		width: calc(100% - 310px);

		.table {width: 100%;}
	}

	&__thumbnail {
		width: 48px; height: 48px; display: inline-block;
		img {width: 100%; height: 100%; object-fit: contain;}
	}

	&__file {
		position: relative; display: inline-block; width: 48px;
		svg {display: block; margin: 0 auto;}
	}
	&__file_type {
		display: block; font-size: 8px; text-transform: uppercase; color: #6E7A87; font-weight: 700; position: absolute; bottom: 2px; left: 0; width: 100%; text-align: center;
	}

	.browse_media {
		&__folders {padding-top: 30px; height: 80vh;}
		&__folders_scroll {height: 100%;}
	}

}

.media_table {
	&__image {
		width: 48px; text-align: center;
	}
}

.upload_info {
	&__filename {
		@include wordup(14px, 16px, $normal, 0.0125em); color: #3C4C5A; padding-bottom: 1px;
	}

	&__size {
		@include wordup(12px, 16px, $normal, 0.01667em); color: #7E8996;
	}

	&__failed_text {color: #AB091E; display: inline-block; margin-left: 5px;}

	&__item {
		padding-bottom: 10px; padding-right: 60px; margin-bottom: 10px; border-bottom: solid 1px #E4E7EA; position: relative;

		&--error {
			.upload_info {
				&__filename {color: #AB091E;}
			}
		}

		&--waiting {
			.upload_info {
				&__filename, &__size {opacity: 0.4;}
			}
		}
	}

	&__status_icon {
		position: absolute; top: 6px; right: 36px; cursor: pointer; user-select: none; z-index: 2;
		svg {display: block;}
	}

	&__close {
		position: absolute; top: 9px; right: 0; cursor: pointer; user-select: none; z-index: 2;
		svg {display: block;}
	}
}
