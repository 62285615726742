@use 'sass:string';

$layout-border: #DBE1E8;

.site_wrap {
	display: flex; flex-wrap: wrap; min-width: 900px; min-height: 100%; width: 100%; flex-direction: column;

	&__container {width: 100%; padding: 30px 0;}

	&__main {
		width: calc(78% - 15px); margin-left: 30px; max-width: 1400px;
		&--no_mar {margin-left: 0;}
	}

	&__max {max-width: 1750px;}

	.container {
		display: flex; flex-wrap: wrap;
	}

	&--admin {background: #F5F5F5;}

	&--grey {background: #F7F7F7;}

	@media only screen and (max-width: 1040px) {
		&__main {width: calc(100% - 230px);}
	}

	@media only screen and (min-width: 1583px) {
		&__main {width: calc(100% - 350px);}
	}
}


.intro_content {

	padding-bottom: 35px;

	&__description {
		@include wordup(14px, 26px, $normal, 0.015em);
		a {color: $blue;}
	}

}

// PADDING
.pb {
	&-20 {padding-bottom: 20px;}
}
// MARGIN
.mt {
	&-10 {margin-top: 10px;}
	&-20 {margin-top: 20px;}
	&-55 {margin-top: 55px;}
}
.mr {
	&-20 {margin-right: 20px;}
	&-45 {margin-right: 45px;}
}
.mb {
	&-20 {margin-bottom: 20px;}
	&-25 {margin-bottom: 25px;}
	&-40 {margin-bottom: 40px;}
}
.ml {
	&-30 {margin-left: 30px;}
}

@function add-unit-to-value($value, $unit){
	@return if($value != 0, $value + $unit, 0);
}

@function increment-by-5($i, $unit){
	@return add-unit-to-value(0 + ($i * 5), $unit);
}

$l: left;
$r: right;
$t: top;
$b: bottom;
$pad: padding;
$mar: margin;

$repeatable-class-slugs: $pad $l, $pad $r, $pad $t, $pad $b, $mar $l, $mar $r, $mar $t, $mar $b;

@each $slug in $repeatable-class-slugs {
    $key: nth($slug, 1);
	 $key_short: string.slice($key, 1, 1);
    $value: nth($slug, 2);
	 $value_short: string.slice($value, 1, 1);

    /* #{$slug} */

	 @for $i from 0 through 10 {
    	.#{$key_short}#{$value_short}-#{$i} {
    		#{$key}-#{$value}: add-unit-to-value($i, px);
    	}
    }

	 @for $i from 0 through 10 {
	  .#{$key_short}#{$value_short}-#{$i * 5} {
		  #{$key}-#{$value}: increment-by-5($i, px);
	  }
	 }
}

.block {
	padding: 20px 40px; border: solid 1px $layout-border; border-top: 0; position: relative;

	&__main {
		flex-grow: 1;
	}

	&__profile_img {
		margin-right: 30px; border-radius: 100%; overflow: hidden; display: block; width: 154px; height: 154px; position: relative; user-select: none; cursor: pointer;
		&:before {
			content: 'Change Image'; display: flex; align-items: center; justify-content: center; position: absolute; top: 0; left: 0; width: 100%; height: 100%; border-radius: 100%; background: rgba($blue, 0.62); color: #fff; z-index: 1; @include wordup(12px, 16px, $normal, 0.025em); opacity: 0; transition: $transition;
		}
		&:hover {
			&:before {opacity: 1; transition: $transition;}
		}
		img {display: block; height: 100%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);}
	}

	&__actions {
		padding-top: 7px;
	}

	&__psmall {@include wordup(12px, 26px, $normal, 0.015em); margin-bottom: 10px;}

	&__label {width: 230px;}
	&__fields {width: calc(100% - 280px); margin-left: 50px;}

	&--bt {border-top: solid 1px $layout-border;}

	&--vertical_center {align-items: center;}

	&--flex_wrap {display: flex; flex-wrap: wrap;}

	&--custom_fields {
		padding-top: 30px; padding-bottom: 50px;
		.heading__primary {padding-bottom: 50px; line-height: 34px;}

		.block__row {
			padding-bottom: 30px;
			&:last-child {padding-bottom: 0;}
		}
	}
	&--add_user {max-width: $max-width;}

	&--settings_cf {
		padding: 30px; border-bottom: 0;	
	}

	&--admin {padding: 40px 0; border-left: 0; border-right: 0;}

	&--no_pt {padding-top: 0;}
}

.pipe {
	display: inline-block; padding: 0 3px;
	&--lrg {padding: 0 10px;}
}

.panel {
	display: block; padding: 15px 28px; border: solid 1px #CBD2D9; border-left: 0; border-radius: 2px; position: relative; box-shadow: 0 3px 5px 0 rgba(31, 41, 51, 0.16);
	&:before {
		content: ''; position: absolute; top: -1px; left: 0; width: 4px; height: calc(100% + 2px); border-top-left-radius: 2px; border-bottom-left-radius: 2px;
	}

	&__heading {
		color: #1F2933; padding-bottom: 6px;
	}

	&__address {
		@include wordup(14px, 26px, $normal, 0.014em); color: #3C4C5A; position: relative; padding-left: 20px;
		span {
			position: absolute; top: 4px; left: 0;
			svg {display: block;}
		}
	}

	&__dots {
		position: absolute; top: 50%; right: 25px; transform: translateY(-50%);
		svg {display: block;}
	}

	&__wrap {
		display: block;
		.panel {
			margin-bottom: 16px;
			&:last-child {margin-bottom: 0;}
		}
	}

	&--blue {
		&:before {background: $blue;}
	}
}
