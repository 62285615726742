.heading {

	&__primary, &__secondary {color: $body;}

	&__primary {
		@include wordup(24px, 31px, $normal, 0); padding-bottom: 7px;
	}

	&__secondary {
		@include wordup(20px, 34px, $normal, 0.015em);
	}

	&__wrap {
		position: relative; width: 100%;
		.btn, .heading__help, .heading__actions {position: absolute; top: 50%; right: 0; transform: translateY(-50%);}
	}

	&__help {
		text-decoration: none; color: #00215A; @include wordup(12px, 28px, $medium, 0); display: inline-flex; align-items: center;
		&:hover {text-decoration: none;}
		svg {margin-right: 4px;}
	}

	&__actions {
		display: flex; align-items: center;
		.btn, .heading__help {position: relative; top: 0; transform: none;}
		a {margin-left: 10px;}
	}

	&__keyline {padding-bottom: 35px; border-bottom: solid 1px #DBE1E8;}

	&--keyline {padding-bottom: 35px; margin-bottom: 40px; border-bottom: solid 1px #DBE1E8;}

	&--grey {color: #B5BDC5; padding-left: 5px;}

	&--no_pad {padding-bottom: 0!important;}
}

.inline_middle {display: inline-block; vertical-align: middle;}

.body_text {
	p, &__p, address {
		@include wordup(14px, 26px, $normal, 0.015em);
		a {color: $blue;}
	}

	&--info {color: #7E8996; font-size: 12px;}
	&--grey {color: #7E8996;}

	&--fw_medium {font-weight: $medium;}
}

.rounded_label {
	@include wordup(11px, 14px, $normal, 0.011em); display: inline-block; border-radius: 11px; border: solid 1px transparent; padding: 2px 10px;

	&.orange {border-color: $orange;}
}

.text_oval {
	display: inline-block; @include wordup(13px, 16px, $normal, 0); padding: 2px 10px 4px; border-radius: 12px; border: solid 1px transparent; min-width: 76px; text-align: center;

	&.red {border-color: $red; background: $input-error-bg; color: #610316;}
	&.green {border-color: $green; background: $input-valid-bg; color: #014D40;}
	&.blue {border-color: $blue-border; background: $blue-fill; color: $blue-text;}
	&.yellow {border-color: $yellow-border; background: $yellow-fill; color: $yellow-text;}

	&--no_min {min-width: 0;}
}

.tag {
	display: inline-block; padding: 4px 24px 5px 7px; color: #ffffff; margin: 0 4px; background: #52606D; border-radius: 2px;
	@include wordup(14px, 17px, normal, 0.0125em); position: relative;

	&:after {
		content: ''; @extend .rcon--tag_close; position: absolute; top: 8px; right: 8px; cursor: pointer; user-select: none;
	}
	&__wrap {
		display: block; margin: 0 -4px;
	}
}

.message {
	@include wordup(12px, 16px, $medium, 0.014em);
	&__icon {
		display: inline-block; margin-right: 5px;
		svg {display: block;}
	}
	&--error {color: $red;}
	&--center {display: flex; align-items: center;}
}
