.ld_header {
	display: flex; align-items: center; min-height: 85px;

	&__score {
		margin-right: 32px;
	}

	&__info {
		display: flex;
		a {color: $blue; text-decoration: none;}

		&--lead, &--created, &--shared {@include wordup(14px, 20px, $normal, 0.0125em); padding-right: 16px;}

		&--created, &--shared {
			position: relative; padding-right: 13px; margin-right: 13px; color: #7E8996;
			&:before {
				content: ''; position: absolute; top: 0; right: 0; height: 100%; border-left: solid 1px #CBD2D9;
			}
		}

		&--shared {color: #1F2933;}

		p:last-child {
			padding-right: 0; margin-right: 0;
			&:before {display: none;}
		}
	}

	&__status {
		display: flex; align-items: center; margin-left: auto;
		.form_elem__select {width: 168px;}
	}

	&--auto_height {min-height: auto;}
}

.ld_wrap {
	padding-top: 30px; height: 100%; display: flex; justify-content: space-between;
	.ld_sidebar {width: 322px; height: 100%; overflow-y: scroll; padding: 0 24px 0 30px;}
	.ld_tabs {width: calc((100% - 322px) - 40px); padding-right: 30px;}
}

.ld_tabs {
	&__tabs {
		display: flex; border: solid 1px #DBE1E8; background: #F2F8FD;
	}

	&__tab {
		display: flex; align-items: center; padding: 20px 40px 17px 24px; border-right: solid 1px #DBE1E8; position: relative; transition: $transition; cursor: pointer; user-select: none; position: relative;
		&:before {
			content: ''; position: absolute; bottom: -1px; left: 0; width: 100%; border-bottom: solid 1px #fff; opacity: 0; visibility: hidden; transition: $transition;
		}
		p {@include wordup(16px, 17px, $normal, 0.0125em); margin-left: 10px; color: #1F2933;}

		&--active {
			background: #fff; transition: $transition;
			&:before {
				transition: $transition; opacity: 1; visibility: visible;
			}
		}
	}

	&__nav {
		display: flex; justify-content: space-between; padding: 23px; align-items: center;

		&--left, &--right {display: flex;}
		&--left {
			> div {margin-right: 15px;}
		}
		&--right {
			> div {margin-left: 15px;}
		}
		.form_elem__search {width: 267px;}

		.btn--small {padding: 5px 17px 6px}
	}

	&__deals {
		padding: 20px 23px; border-bottom: solid 1px #E4E7EA; display: flex; align-items: center;
		.btn {margin-left: auto;}
	}
	&__deal_info {
		margin-right: 91px;
		&--label {color: #63707E; @include wordup(14px, 17px, $normal, 0.014em); padding-bottom: 2px;}
		&--value {color: #1F2933; @include wordup(20px, 30px, $normal, 0);}
	}

	&__body {
		border: solid 1px #DBE1E8; border-top: 0; height: calc(100% - 56px); overflow-y: scroll; position: relative;
	}
}

.ld_leads {
	padding: 0 23px 23px 23px;

	&__month {
		margin-bottom: 40px;
		&:last-child {margin-bottom: 0;}
	}

	&__heading {margin-left: 58px; @include wordup(16px, 21px, $normal, 0.013em); padding-bottom: 11px;}

	&__item {
		display: flex; margin-bottom: 20px;

		&--call {
			.ld_leads {
				&__details:before {background: #8EEDC7;}
			}
		}
		&--note {
			.ld_leads {
				&__details:before {background: #FADB5F;}
			}
		}
		&--note, &--event, &--email {
			.ld_leads__icon {
				align-items: flex-start; padding-top: 15px;
			}
		}
		&--event {
			.ld_leads {
				&__details {
					padding-top: 22px;
					&:before {background: #B898EB;}
				}
			}
		}
		&--email {
			.ld_leads {
				&__details {
					padding-top: 56px;
					&:before {background: #FEB7D4;}
				}
			}
		}

	}

	&__icon {
		display: flex; align-items: center; margin-right: 24px;
		svg {display: block;}
	}

	&__details {
		padding: 48px 20px 23px 28px; border: solid 1px #CBD2D9; border-left: 0; border-radius: 2px; flex-grow: 1; position: relative;
		&:before {
			content: ''; position: absolute; top: -1px; left: 0; width: 4px; height: calc(100% + 2px); border-top-left-radius: 2px; border-bottom-left-radius: 2px;
		}
	}

	&__date, &__owner {
		color: #7E8996; @include wordup(12px, 20px, $normal, 0.016em); position: absolute; top: 22px;
	}
	&__date {left: 28px;}
	&__owner {right: 23px;}

	&__title {
		@include wordup(16px, 21px, $normal, 0.012em); color: #1F2933;
		&--small {
			@include wordup(14px, 17px, $normal, 0.014em); color: #52606D;
			a {color: $blue; text-decoration: none;}
		}
		&--recipient {
			color: #52606D; margin-top: -5px;
			.btn--icon {display: inline-block;}
			svg {display: block;}
		}
		&--black {color: #1F2933;}
	}

	&__note {
		padding-top: 10px;
		p {
			@include wordup(14px, 26px, $normal, 0.014em); color: #3C4C5A; padding-bottom: 10px;
			&:last-child {padding-bottom: 0;}
		}
	}

	&__event_info {
		padding-top: 11px;
	}

	&__event_item {
		position: relative; margin-bottom: 4px;
		&:last-child {margin-bottom: 0;}

		p {padding-left: 20px; @include wordup(14px, 26px, $normal, 0.014em); color: #3C4C5A;}

		&--at {color: #63707E; padding: 0 5px;}
		&--icon {
			position: absolute; top: 50%; left: 0; transform: translateY(-50%);
			svg {display: block;}
		}
	}

	&__email_body {
		padding: 6px 0 20px;
		p {@include wordup(14px, 26px, $normal, 0.014em); color: #3C4C5A; padding-bottom: 8px;}
	}

	&__email_actions {
		display: flex; align-items: center;
		a {
			@include wordup(14px, 17px, $normal, 0.014em); color: $blue; margin-right: 16px; text-decoration: none;
			&.ld_leads__email_actions--right {margin-left: auto; margin-right: 0;}
		}
	}
}

.ld_oval {
	display: inline-block; @include wordup(12px, 15px, $medium, 0); padding: 2px 7px 2px; border-radius: 12px; border: solid 1px transparent; text-align: center;
	// min-width: 76px;

	&--green {border-color: #CFE8A8; background: #F2FDE0; color: #5A6F34;}
	&--blue {border-color: #47A3F3; background: #E5F6FF; color: #03449F;}
}

.ld_sidebar {
	&__block {
		padding-bottom: 24px; margin-bottom: 24px; border-bottom: solid 1px #E4E7EA;
		&--no_border {border-bottom: 0;}
	}
	&__contact {
		> .ld_sidebar__block:last-child {padding-bottom: 0; margin-bottom: 0; border: 0;}
	}
}

.ld_contact {
	position: relative; padding-left: 68px;

	&__icon {
		display: flex; justify-content: center; align-items: center; position: absolute; top: 6px; left: 0; width: 56px; height: 56px;
		&:before, &:after {
			content: ''; position: absolute; border-radius: 100%;
		}
		&:before {
			background: #9AA5B0; width: 100%; height: 100%; left: 0px; top: 0px;
		}
		&:after {
			top: 0; left: 0; width: 100%; height: 100%; border: solid 2px #94C844; opacity: 0; visibility: hidden;
		}
		span {color: #fff; @include wordup(20px, 26px, $normal, 0.08em); position: relative; z-index: 1;}
	}

	&__dots {
		position: absolute; top: 0; right: 0;
	}

	&__type {
		color: #7E8996; @include wordup(11px, 20px, $medium, 0.014em); margin-bottom: -3px;
	}

	&__name {
		color: #1F2933; @include wordup(18px, 30px, $medium, 0); margin-bottom: -3px;
	}

	&__role {
		color: #63707E; @include wordup(14px, 24px, $normal, 0.014em); margin-bottom: 9px;
	}

	&__add_activity {
		display: inline-block; background: $blue; cursor: pointer; user-select: none; color: #fff; @include wordup(12px, 15px, $medium, 0.025em); padding: 3px 7px; border-radius: 15px;
		svg {display: inline-block; margin-right: 2px;}
	}

	&--online {
		.ld_contact {
			&__icon {
				&:before {
					width: 48px; height: 48px; left: 4px; top: 4px;
				}
				&:after {opacity: 1; visibility: visible;}
			}
			&__type--status {color: #63921A;}
		}
	}
}

.ld_accordion {
	&__heading {
		position: relative; cursor: pointer; user-select: none;
		p {@include wordup(14px, 17px, $medium, 0.014em); color: #3C4C5A;}
		.btn--icon {
			position: absolute; top: 0; right: 0;
			svg {transform: rotate(180deg); transition: $transition;}
		}
	}

	&__content {
		padding-top: 20px; display: none;
		.form_elem__label--small {margin-top: 0;}
	}

	&__list_add {
		text-align: right; padding-bottom: 15px; margin-top: -9px;
		a {
			color: $blue; text-decoration: none; @include wordup(12px, 15px, $medium, 0.0125em);
			svg {margin-right: 4px;}
		}
	}

	&__list_item {
		padding-bottom: 12px; position: relative;
		&:last-child {
			padding-bottom: 0;
		}
	}

	&--open {
		.ld_accordion {
			&__heading {
				.btn--icon svg {transform: rotate(0); transition: $transition;}
			}
			&__content {display: block;}
		}
	}
}

.ld_secondary_contacts {
	&__heading {
		position: relative; padding-bottom: 17px;
		p {color: #616E7C; @include wordup(14px, 17px, $normal, 0.025em);}
	}

	&__add {
		position: absolute; top: 2px; right: 0; color: $blue; text-decoration: none; @include wordup(12px, 15px, $medium, 0.0125em);
		svg {margin-right: 4px;}
	}

	&__trigger {
		padding-left: 28px; position: relative; z-index: 0; cursor: pointer; user-select: none;
		&:before {
			content: ''; position: absolute; top: -10px; right: -24px; width: 100vw; height: calc(100% + 20px); background: #F5F7FA; z-index: -1; opacity: 0; visibility: hidden; transition: $transition;
		}
	}
	&__arr {
		position: absolute; top: 50%; transform: translateY(-50%); right: 0;
		svg {transform: rotate(180deg); transition: $transition;}
	}
	&__move {position: absolute; top: 50%; transform: translateY(-50%); left: 0;}

	&__name {@include wordup(14px, 17px, $normal, 0.013em); color: #1F2933; padding-bottom: 3px;}
	&__role {@include wordup(12px, 15px, $normal, 0.013em); color: #63707E;}

	&__content {
		display: none; padding-top: 20px;
		.ld_sidebar__block:last-child {margin-bottom: 0;}
	}

	&__record {
		padding-bottom: 20px; position: relative;

		&--open {
			.ld_secondary_contacts {
				&__trigger:before {opacity: 1; visibility: visible; transition: $transition;}
				&__arr svg {transform: rotate(0); transition: $transition;}
				&__content {display: block;}
			}
		}
	}

	&__show_more {
		color: $blue; @include wordup(12px, 15px, $medium, 0.0125em); text-decoration: none; margin-left: 28px;
	}

}

.ld_record_detail {
	&__block {
		position: relative; border-bottom: solid 1px #DBE1E8; padding: 30px;
		&:last-child {border-bottom: 0;}

		.form_elem__label--small {font-size: 12px; color: #616E7C;}

		.tb_edit__add_here {left: 0; bottom: 0; transform: translateY(50%);}

		&--two_col {
			display: flex; justify-content: space-between;
		}

		&--no_margin {border:none;}

		&--hover {
			.ld_record_detail__edit_hover {border-color: #47A3F3; opacity: 1; visibility: visible;}
		}
	}
	&__block_left, &__block_right {
		width: 48%; 
		img {max-width: 100%;}
	}
	&__block_left {margin-top: -9px;}
	&__block_full {
		max-width: 480px;
	}

	&__inner {
		max-width: 865px; position: relative; z-index: 1;
		&.detail-nav-wrap {max-width:1050px;
			.detail-nav {padding:20px 30px 0px 30px; width:100%; position:relative; display:flex; 
				&:after {height:3px; width:100%; background:#dae1e9; position:absolute; bottom:0; left:30px; content:'';}
				li {cursor:pointer; @include wordup(13px, 22px, 500, 0); color: #1F2933; margin-right:25px; padding:0 0 15px 0; position:relative;
					&.active {
						&:after {height:3px; background:#006ee0; position:absolute; bottom:0; content:''; width:100%; left:0; z-index:1;}
					}
					&:hover {
						&:after {height:3px; background:#006ee0; position:absolute; bottom:0; content:''; width:100%; left:0; z-index:1;}
					}
				}
			}
		}
	}

	&__heading {
		@include wordup(16px, 30px, $normal, 0); color: #1F2933; padding-bottom: 10px;
	}
	&__info {
		@include wordup(14px, 22px, $normal, 0.014em); color: #3D4C59; padding-bottom: 20px; max-width: 565px;
	}

	&__fields {
		display: flex; flex-wrap: wrap; margin: 0 -16px;

		.f_builder__item {
			.form_elem__wrap {width: 100%; margin-left: 0; margin-right: 0;}

			&--placeholder {
				p {@include wordup(14px, 26px, $normal, 0.014em);}
			}
		}

		&--one_third {
			width: calc((100% - (3 * 32px)) / 3); margin-left: 16px; margin-right: 16px;
		}
		&--two_third {
			width: calc((100% - (2 * 32px)) - (100% - (3 * 32px)) / 3); margin-left: 16px; margin-right: 16px;
		}
		&--offset_one_third {
			margin-right: calc(((100% - (3 * 32px)) / 3) + 16px);
		}
		&--offset_two_third {
			margin-right: calc(((100% - (2 * 32px)) - (100% - (3 * 32px)) / 3) + 32px);
		}

		&--full {width: calc(100% - 32px); margin-left: 16px; margin-right: 16px;}

		// &--three_col {
		// 	.form_elem__wrap, .f_builder__item {
		// 		width: calc((100% - (3 * 32px)) / 3);
		// 	}
		// }
	}

	&__edit_hover {
		position: absolute; top: 0; left: 0; width: 100%; height: 100%; border: dashed 1px transparent; transition: $transition; opacity: 0; visibility: hidden; z-index: 0;
		.f_builder__moveable {opacity: 1; visibility: visible;}
	}
}

.ld_table {
	.table {
		tr {
			th:first-child, td:first-child {padding-left: 23px;}
			th:last-child, td:last-child {padding-right: 23px;}
		}
	}
}

.ld_block_tabs {
	border: solid 1px #E1E4E8;

	&__nav {
		border-bottom: solid 1px #E1E4E8; display: flex; padding: 0 23px;
		p {
			@include wordup(14px, 17px, $normal, 0.0125em); color: #3D4C59; padding: 15px 0; position: relative; cursor: pointer; user-select: none; margin-right: 50px;
			&:before {
				content: ''; position: absolute; bottom: 0; left: 0; width: 100%; border-bottom: solid 2px $blue; opacity: 0; visibility: hidden; transition: $transition;
			}

			&.ld_block_tabs--active {
				&:before {opacity: 1; visibility: visible;}
			}
		}
	}

	&__body {

		.table--border {border-top: 0; border-right: 0;}

		&--two_col {
			display: flex; justify-content: space-between;

			.ld_block_tabs {
				&__col {width: 48%;}
			}
		}
	}

	&__col {
		&--padding {padding: 23px; display: flex; align-items: center;}
	}
}

.ld_device_table {display: block; margin-left: auto;}

.ld_online_block {
	padding: 8px 45px 8px 10px; position: relative; border: solid 1px; border-radius: 2px;
	p, a {color: #5A6F34; @include wordup(12px, 16px, $normal, 0);}
	a {position: absolute; top: 50%; transform: translateY(-50%); right: 10px; font-weight: $medium; text-decoration: none;}

	&--green {
		border-color: #94C844; background: #F2FDE0;
	}
}

.ld_email {
	&__label, &__cc {@include wordup(14px, 17px, $normal, 0.028em); color: #1F2933;}

	&__to, &__subject {
		display: flex; align-items: center; border-bottom: solid 1px #E4E7EA; min-height: 48px;
		.event_attendees__person {margin: 6px 12px;}
	}

	&__to {position: relative; padding-right: 40px;}
	&__subject {
		.ld_email {
			&__label {margin-right: 12px;}
		}
		.form_elem__input_clear {flex-grow: 1;}
	}
	&__copy {position: absolute; right: 0; top: 50%; transform: translateY(-50%);}

	&__cc {margin-left: 12px;}

	&__body {
		min-height: 365px;
	}

	&__toolbar {
		display: flex; align-items: center; justify-content: space-between; margin-bottom: -8px;

		&--text_align, &--font_size {
			display: flex; align-items: center;
		}

		&--font_size {
			.form_elem__select {
				margin-right: 8px;
				&:last-child {margin-right: 0;}
			}
		}
	}
}

.ld_uploader {
	display: flex; align-items: center; justify-content: center; height: 128px; border: dashed 1px #7E8996; border-radius: 2px; margin: 15px 0 11px;
	p {
		@include wordup(16px, 30px, $normal, 0); text-align: center; color: #7E8996; padding-bottom: 10px;
		a {color: $blue;}
		span {
			padding-right: 7px;
			svg {position: relative; top: 9px;}
		}
	}
}

.ld_review {
	&__info {
		position: relative; padding-right: 30px;
		p {color: #1F2933;}
		&--qty {
			position: absolute; top: 50%; transform: translateY(-50%); right: 0; @include wordup(12px, 15px, $normal, 0.0125em); color: #06449F; display: flex; align-items: center;
			svg {margin-right: 5px;}
		}
	}
	&__add_copy {
		display: flex; align-items: center; border-bottom: solid 2px #CBD2D9; padding: 13px 14px;
		p {@include wordup(12px, 16px, $normal, 0.0125em);}
	}
	&--height {max-height: 260px;}
}

.ld_upload_item {
	display: flex; align-items: center; position: relative; border-bottom: solid 1px #E4E7EA; padding: 15px 30px 15px 14px;

	&__checkbox {
		padding-right: 9px;
	}

	&__remove {
		display: flex; align-items: center; justify-content: center; width: 28px; position: absolute; top: 0; right: 8px; height: 100%;
		svg {cursor: pointer; user-select: none;}
	}

	&--max_width {max-width: 278px;}

	p {@include wordup(14px, 16px, $normal, 0.0125em);}

}

.ld_deal {
	&__two_col {
		display: flex; flex-wrap: wrap;
		.form_elem__wrap {
			width: 47.5%; margin-left: 5%;
			&:nth-child(2n+1) {margin-left: 0;}
		}
	}
}
