.automation {

	&__heading {
		@include wordup(16px, 24px, $medium, 0); color: #1F2933;
	}

	&__input_wrap {max-width: 440px; padding-bottom: 10px;}

	&__toggle_wrap {
		position: relative;
		.form_elem__toggle {position: absolute; top: 0; right: 0; width: 94px;}

	}

	&__check_list {
		li {padding-bottom: 13px;}
	}

	&__p {
		color: #63707E; @include wordup(14px, 17px, $normal, 0.025em);
		a {color: $blue; text-decoration: none;}
	}

	&--workflow_scroll {
		padding-bottom: 0;
		.la_duplicate_action--workflow {padding-bottom: 0;}
	}

	&--padding {padding: 30px 32px;}

	&--flex_wrap {
		display: flex; flex-wrap: wrap;
	}

	&--input_max_width {
		max-width: 348px;
	}
	&--select_min_width {
		min-width: 112px;
	}

	&--if_else {
		padding: 16px;
	}
}

.auto_trig {
	display: flex;

	&__sidebar {
		background: #F5F7FA; padding: 80px 0 20px; width: 190px;
	}

	&__content {
		width: calc(100% - 190px); padding: 30px 48px; display: flex; flex-direction: column; overflow-x: hidden; overflow-y: scroll; height: 510px;
	}

	&__menu_item {
		padding: 9px 30px 9px 57px; position: relative; transition: $transition; cursor: pointer; user-select: none;
		&:before {
			content: ''; position: absolute; top: 0; right: -10px; width: 0; height: 0; border-style: solid; border-width: 20px 0 20px 10px; border-color: transparent transparent transparent #3EBD93; opacity: 0; visibility: hidden; transition: $transition;
		}

		p {@include wordup(14px, 23px, $medium, 0.014em); color: #3C4C5A;}

		.wf-fill, .wf-stroke, .wf-green {transition: $transition;}

		&--active, &:hover {
			background: #3EBD93; transition: $transition;
			&:before {opacity: 1; visibility: visible; transition: $transition;}
			p {color: #fff;}

			.wf-fill, .wf-stroke, .wf-green {transition: $transition;}
			.wf-stroke {stroke: #fff;}
			.wf-green {fill: #3EBD93;}
			.wf-fill {fill: #fff;}
		}
	}

	&__icon {
		width: 16px; display: flex; align-items: center; position: absolute; display: block; top: 50%; transform: translateY(-50%); left: 32px;
		svg {display: block;}
	}

	&__block {
		padding-bottom: 40px;
		&:last-child {padding-bottom: 0;}
	}

	&__title {@include wordup(14px, 17px, $medium, 0.014em); color: #3C4C5A; padding-bottom: 18px;}
	&__link, &__link a {@include wordup(14px, 17px, $regular, 0.0125em); color: #63707E; text-decoration: none;}
	&__link {
		padding-bottom: 17px;
		&:last-child {padding-bottom: 0;}
		&--active {
			color: $blue;
			a {color: $blue;}
		}
	}
}

.auto_if_else {
	position: relative; margin-bottom: 30px;

	&:before {content: ''; border-left: solid 1px #B5BDC5; height: 100%; position: absolute; top: 0; left: 50%;}

	&__conditions {
		padding: 16px 16px 12px 16px; background: #F5F7FA; position: relative;

		&--removable {
			padding-top: 40px;
		}
	}

	&__selects {
		display: flex; position: relative;

		.form_elem__select {
			width: calc(((100% - 30px) / 100) * 36);
			&:nth-child(2) {width: 26.6%; margin: 0 15px;}
		}

		&--removable {
			padding-right: 20px;
		}
	}

	&__selects, &__and_or {
		margin-bottom: 16px;
	}

	&__and_or, &__logic {
		.btn {min-width: auto; width: 50px; padding: 6px 5px 7px;}
	}

	&__logic {
		padding: 32px 0; display: flex; justify-content: center;
	}

	&__remove {
		position: absolute; top: 13px; right: -2px;
		svg {display: block;}

		&--condition {top: 8px; right: 8px;}
	}

	&__add {
		cursor: pointer; user-select: none; margin-top: -5px;
		p {@include wordup(14px, 17px, $normal, 0.0125em); color: $blue;}
		span {margin-right: 5px;}
	}

	&__add_segment_group {
		padding-top: 48px; display: flex; justify-content: center; position: relative; cursor: pointer; user-select: none;
	}

	&__border_btn {
		width: 100%; max-width: 367px; border: dashed 1px #B5BDC5; background: #fff; text-align: center; padding: 20px;
		p {@include wordup(14px, 26px, $normal, 0.014em); display: flex; align-items: center; justify-content: center;}
		svg {display: block;}
	}

	&__plus {margin-right: 5px; display: inline-block;}

}

.auto_flow {
	display: flex; width: 100%; height: 100%; position: relative; overflow: scroll; padding-top: 60px;
	// justify-content: center;

	&__container {
		position: absolute; display: flex; flex-direction: column; min-width: 100%;
	}

	&__zoom {
		position: fixed; top: 200px; right: 30px; display: flex; z-index: 9;
	}
	&__zoom_btn {
		border: solid 1px #B5BDC5; display: flex; align-items: center; justify-content: center; height: 40px; width: 38px; cursor: pointer; user-select: none; background: #fff;
		&--plus {border-top-left-radius: 2px; border-bottom-left-radius: 2px;}
		&--minus {border-top-right-radius: 2px; border-bottom-right-radius: 2px; margin-left: -1px;}
		svg {display: block;}
	}

	&__row {
		display: flex; justify-content: center;
	}

	&__cell {
		padding: 48px 24px; position: relative;

		&:before {
			content: ''; height: 48px; width: 100%; position: absolute; left: 0; bottom: 0; border-bottom: solid 1px #B5BDC5;
		}
		&:after {
			content: ''; height: 48px; position: absolute; bottom: 0; left: 50%; border-left: solid 1px #B5BDC5;
		}

		&:first-child, &:last-child {
			&:before {
				content: ''; height: 48px; width: 50%; position: absolute; bottom: 0; border: solid 1px #B5BDC5;
			}
			&:after {display: none;}
		}
		&:first-child {
			&:before {
				left: 50%; border-top: 0; border-right: 0; border-bottom-left-radius: 10px;
			}
		}
		&:last-child {
			&:before {
				right: 50%; border-top: 0; border-left: 0; border-bottom-right-radius: 10px;
			}
		}

		&--no_line {
			&:before, &:after {display: none;}
		}

		&--circline_left {
			&:before {
				content: ''; top: 0; bottom: auto; width: 50%; height: 48px; border: solid 1px #B5BDC5!important; border-top-left-radius: 10px!important; border-bottom-left-radius: 0!important; border-bottom: 0!important; border-right: 0!important;
			}
		}

		&--circline_middle {
			&:before {
				content: ''; left: auto!important; top: 0; bottom: auto; width: 50%; height: 48px; border: 0!important; border-right: solid 1px #B5BDC5!important; border-top-right-radius: 0!important; border-bottom-right-radius: 0!important;
			}
		}

		&--circline_right {
			&:before {
				content: ''; left: auto!important; top: 0; bottom: auto; width: 50%; height: 48px; border: solid 1px #B5BDC5!important; border-top-right-radius: 10px!important; border-bottom-right-radius: 0!important; border-bottom: 0!important; border-left: 0!important;
			}
		}

		&--no_pad_top {padding-top: 0;}
	}

	&__col {
		position: relative;
		&:before {
			content: ''; position: absolute; top: 0; width: calc(50% - 20px); border-top: solid 1px #B5BDC5;
		}

		&--left {
			&:before {right: 0;}
		}
		&--middle {
			&:before {left: 0; width: 100%;}
		}
		&--right {
			&:before {left: 0;}
		}
	}

	&__placeholder {
		width: 387px; max-width: 100%; height: 68px; cursor: pointer; user-select: none; position: relative;
		&--active {
			.af_block__menu--placeholder {display: flex;}
		}
	}

	&__start {
		background: #3EBD93; border-radius: 2px; padding: 10px 20px; text-align: center; position: relative;
		&:before {
			content: ''; width: 0; height: 0; border-style: solid; border-width: 8px 8.5px 0 8.5px; border-color: #3EBD93 transparent transparent transparent; position: absolute; bottom: -8px; left: 50%; transform: translateX(-50%);
		}
		p {color: #fff; @include wordup(16px, 24px, $medium, 0);}
	}

	&__add {
		position: absolute; top: -16px; left: 50%; transform: translateX(-50%); padding: 5px; cursor: pointer; user-select: none; z-index: 2;
		svg {display: block;}

		&--bottom {
			bottom: -16px; top: auto;
		}
	}

	&__line {
		position: absolute; border-left: solid 1px #B5BDC5; height: 48px; position: absolute; left: 50%; display: block;
		&--top {top: 0;}
		&--bottom {bottom: 0;}
	}
}

.af_block {
	min-width: 387px; padding: 21px 30px; background: $blue; border-radius: 2px; box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); border: solid 1px #B5BDC5; background: #F5F7FA; position: relative; display: flex; align-items: center; justify-content: center;
	box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);

	&__actions {
		position: absolute; top: 2px; right: 2px;
		&--active {
			.af_block__menu {display: flex;}
		}
	}

	&__dots {
		padding: 5px; cursor: pointer; user-select: none;
		svg {display: block;}
	}

	&__menu {
		background: #fff; border: solid 1px #CBD2D9; border-radius: 2px; display: flex; flex-direction: column; padding: 2px 12px; position: absolute; top: 12px; left: 4px; z-index: 9; min-width: 95px; display: none;

		&--placeholder {
			left: 50%; transform: translateX(-50%); top: 85%; filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.16)); border-radius: 0;
			&:before {
				content: ''; position: absolute; top: -9px; left: 50%; transform: translateX(-50%); width: 0; height: 0; border-style: solid; border-width: 0 7px 9px 7px; border-color: transparent transparent #CBD2D9 transparent;
			}
			&:after {
				content: ''; position: absolute; top: -8px; left: 50%; transform: translateX(-50%); width: 0; height: 0; border-style: solid; border-width: 0 6px 8px 6px; border-color: transparent transparent #fff transparent;
			}
		}
	}
	&__menu_item {
		padding: 7px 0;
		a {
			@include wordup(14px, 15px, $normal, 0.0125em); color: #3C4C5A; text-decoration: none; display: flex; align-items:  center;
		}

		&--delete {
			svg {margin-left: 5px;}
		}
		&--blue a {color: $blue;}
	}

	&__label {display: flex; align-items: center;}
	&__icon {
		display: flex; align-items: center; justify-content: center; margin-right: 10px; position: relative;
		&--circle {
			border-radius: 100%; width: 32px; height: 32px; background: #9AA5B0; margin: -5px 10px -5px 0;

			.af_block__icon--disabled {
				position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);
			}
		}
	}
	&__p {@include wordup(14px, 24px, $normal, 0.014em); color: #3D4C59;}

	&__circle {
		display: flex; align-items: center; justify-content: center; width: 48px; height: 48px; border: solid 2px #B5BDC5; border-radius: 100%;
		.af_block__p {text-transform: uppercase;}

		&--yes {
			border-color: #27AB83;
			.af_block__p {color: #0C6A58}
		}
		&--no {
			border-color: #E12D39;
			.af_block__p {color: #831003}
		}
		&--label {
			.af_block__p {font-size: 20px;}
		}
	}

	&--green {
		border-color: #8EEDC7; background: #EFFCF6;
		.af_block {
			&__p {color: #014D40; font-size: 16px;}
			&__dots svg circle {fill: #9bc3b3;}
		}
	}

	&--blue {
		border-color: #7CC4FA; background: #E5F6FF; min-width: auto;
		.af_block {
			&__p {color: #01215A; font-size: 16px;}
		}
	}

	&--small {
		background: #fff; min-width: auto; padding-top: 11px; padding-bottom: 11px;
	}

	&--disabled {
		border-color: #E1E4E7; background: #FAFBFC;
		&.af_block--green {
			border-color: #D1F7E8; background: #F8FDFB;
		}

		.af_block__icon--circle {background: #D3D8DD;}
	}

}
