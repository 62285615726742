.f_builder {
	display: flex; max-width: 840px;

	&__col {
		width: 370px; margin-left: calc(100% - (370px * 2));
		&:first-child {margin-left: 0;}
	}

	&__item {
		position: relative; padding: 10px 15px 20px 44px; border: dashed 1px transparent; transition: $transition; margin-bottom: 12px;

		.tb_edit__move, .tb_edit__actions {opacity: 0; visibility: hidden; transition: $transition;}

		&--active, &:hover {
			border-color: #47A3F3;
			.f_builder__delete, .f_builder__add, .f_builder__moveable, .tb_edit__actions {opacity: 1; visibility: visible; transition: $transition;}
		}

		&--active {
			.f_builder__search {opacity: 1; visibility: visible;}
		}

		&--placeholder {
			border-color: #B5BDC5; padding: 20px; text-align: center; cursor: pointer; user-select: none;
			p {
				display: flex; align-items: center; justify-content: center;
				svg {margin-right: 10px;}
			}
		}

		&--no_hover {
			&:hover {border-color: transparent;}
		}

		&--checkbox {
			.f_builder__move {top: 38px; bottom: auto;}
		}

		&--textarea {
			.f_builder__move {bottom: 63px;}
		}

		&--margin_fix {margin-left: -15px; margin-right: -15px;}

		&--no_dots {
			padding: 10px 15px 20px; margin-bottom: 0;
			p:last-of-type {padding-bottom: 0;}
			.form_elem__wrap {margin-bottom: 0;}
		}

	}

	&__inner {
		position: relative; padding-top: 16px; //margin-bottom: 16px;

		.form_elem__wrap {position: relative; z-index: 2;}

		.tb_edit__move, .tb_edit__actions {opacity: 0; visibility: hidden; transition: $transition;}

		&--cont {
			position: absolute; width: calc((16px * 2) + 100%); height: 100%; top: 0; left: -16px; border: dashed 1px transparent; transition: $transition;
		}

		&--active, &:hover {
			.f_builder__inner--cont {border-color: #47A3F3; transition: $transition;}
			.f_builder__delete, .f_builder__add, .f_builder__moveable, .tb_edit__actions {
				opacity: 1; visibility: visible; transition: $transition;
			}
		}
	}

	&__move {
		position: absolute; bottom: 30px; left: 12px; user-select: none; cursor: grab; padding: 5px;
		svg {display: block;}
	}

	&__moveable {
		position: absolute; top: 50%; left: 0; transform: translate(-23px, -50%); background: #47A3F3; border-bottom-left-radius: 5px; border-top-left-radius: 5px; width: 23px; height: 23px; display: flex; align-items: center; justify-content: center; cursor: grab; user-select: none; opacity: 0; visibility: hidden;
	}

	&__delete {
		position: absolute; top: 50%; right: 0; transform: translate(100%, -50%); cursor: pointer; user-select: none;
		background: #EF4F4E; border-radius: 2px; border-top-right-radius: 4px; border-bottom-right-radius: 4px; padding: 5px 5px 5px 7px;
		opacity: 0; visibility: hidden;
		svg {display: block;}
	}

	&__add {
		position: absolute; bottom: 0; left: 50%; transform: translate(-50%, 50%); cursor: pointer; user-select: none;
		opacity: 0; visibility: hidden;
		svg {display: block;}
	}

	&__search {
		position: absolute; top: calc(100% + 23px); left: 0; width: 100%; border: solid 1px #CBD2D9; padding: 25px 15px 30px; background: #ffffff; z-index: 2; box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
		opacity: 0; visibility: hidden; transition: $transition;
		&:before, &:after {
			content: ''; position: absolute; width: 0; height: 0; border-style: solid; border-width: 0 7.5px 9px 7.5px; left: 50%;  transform: translateX(-50%);
		}
		&:before {border-color: transparent transparent #CBD2D9 transparent; top: -9px;}
		&:after {border-color: transparent transparent #fff transparent; top: -8px;}
	}

	&__results {
		padding: 15px 10px 0;
	}

	&__field {
		position: relative; display: flex; align-items: center; justify-content: space-between; margin-bottom: 10px;
		&:last-child {margin-bottom: 0;}
		p, a {@include wordup(14px, 26px, $normal, 0.015em);}
		p {color: #3D4C59;}
		a {color: $blue; text-decoration: none;}
	}

	&__checkboxes {
		padding-top: 10px;
		.form_elem__wrap {
			padding-bottom: 10px;
			&:last-child {padding-bottom: 0;}
		}
	}

	&__modal {
		.form_elem {
			&__wrap {display: flex; align-items: center;}
			&__label {width: 120px; padding-right: 20px; margin-bottom: 0;}
			&__range, &__input {width: calc(100% - 120px);}
			&__colour .form_elem__input {width: 100%;}
			&__range .range__bar {width: 100%;}
		}
	}

	&__range_margin {margin-bottom: 32px;}

	&__button_preview {
		border: solid 1px #DDE1E5; padding: 20px 60px 20px 20px; display: flex; align-items: center; justify-content: space-between; margin-bottom: 18px;
		p {font-size: 14px;}
	}
}
