.breadcrumb {
	display: block; padding: 8px 12px; margin: 30px 0 45px; position: relative; background: #F5F7FA;

	ul {
		display: flex;
		li {
			@include wordup(12px, 18px, $medium, 0.0125em); position: relative; padding-right: 17px; margin-right: 11px;
			&:after {
				content: ''; @extend .rcon; @extend .rcon--arr_right; display: block; position: absolute; top: 4px; right: 0;
			}
			&:last-child {
				padding-right: 0; margin-right: 0;
				&:after {display: none;}
			}

			a {color: $blue; text-decoration: none;}
		}
	}
}
