.btn {
	display: inline-block; min-width: 95px; text-align: center; padding: 10px 24px 11px; border: 0; box-shadow: 0px 3px 8px 0px rgba(31, 31, 31, 0); @include wordup(14px, 17px, $medium, 0); border-radius: 2px; outline: none; text-decoration: none; cursor: pointer; @include main_font;

	background: $btn-default; border: solid 1px $btn-default; color: $btn-default-color;
	transition: $transition;

	&:focus, &.focus {
		// box-shadow: 0px 4px 12px 0px rgba(31, 31, 31, 0.3);
	}

	&:hover, &.active {
		text-decoration: none;
		border-color: $btn-default-hover;
		background: $btn-default-hover;
	}

	&:disabled, &.disabled {
		opacity: 0.35; cursor: not-allowed; pointer-events: none;
	}

	&__group {
		display: flex; flex-wrap: wrap;

		&--stacked {
			flex-direction: column;
			.btn {
				margin-bottom: 15px;
				&:last-child {margin-bottom: 0;}
			}
		}

		&--align_right {
			justify-content: flex-end;
		}

		&--v_center {
			align-items: center;
		}

		&--space_between {
			justify-content: space-between;
		}

		&--center_between {align-items: center; justify-content: space-between;}

		&--flex_grow {
			.btn {flex-grow: 1;}
		}

		&--grouped {
			& > .btn:not(:first-child) {
				border-top-left-radius: 0; border-bottom-left-radius: 0;
			}
			& > .btn:not(:last-child) {
				border-top-right-radius: 0; border-bottom-right-radius: 0;
			}
			& > .btn:not(:first-child) {
				margin-left: -1px;
			}
		}


	}

	&__arr {
		display: flex; align-items: center; cursor: pointer; user-select: none;
		&--arr {
			margin-left: 8px;
			svg {display: block;}
		}
	}


	&--primary {
		background: $orange; color: #fff; box-shadow: 0px 3px 8px 0px rgba(31, 31, 31, 0.3); border-color: $orange;
		&:hover, &.active {
			background: $btn-primary-hover; border-color: $btn-primary-hover; box-shadow: 0px 2px 6px 0px rgba(31, 31, 31, 0.3);
		}
	}

	&--delete {
		background: $btn-delete; border-color: $btn-delete; color: #fff; box-shadow: 0px 3px 8px 0px rgba(31, 31, 31, 0.3);
		&:hover, &.active {
			background: $btn-delete-hover; border-color: $btn-delete-hover; box-shadow: 0px 2px 6px 0px rgba(31, 31, 31, 0.3);
		}
	}

	&--outline {
		background: #ffffff; position: relative; border-color: $border;
		&:hover, &.active {
			background: #52606D; color: #ffffff;
		}
	}

	&--outline_light {
		background: #fff; position: relative; border-color: #B5BDC5; color: #63707E; font-weight: $normal;
		&:hover, &.active {
			background: #E4E7EA; border-color: #B5BDC5; color: #63707E;
		}
	}

	&--outline_clear {
		background: transparent; position: relative; border-color: $border;
		&:hover, &.active {
			background: $btn-default; border-color: $btn-default;
		}
		&.btn--white_text {
			&:hover, &.active {
				color: $btn-default-color;
			}
		}
	}

	&--outline_clear_dark {
		background: transparent; position: relative; border-color: #01215A; color: #01215A;
		&:hover, &.active {
			background: #01215A; border-color: #01215A; color: #fff;
		}
	}

	&--clear {
		background: transparent; position: relative; border-color: transparent;
		&:hover, &.active {
			background: transparent; border-color: transparent;
		}
	}

	&--link {
		box-shadow: none; background: none; color: $blue; border-color: transparent;
		&:hover {
			background: none; border-color: transparent;
		}
	}

	&--cancel {
		color: $red; font-weight: $medium;
	}

	&--red_text {
		color: $red;
	}

	&--white_text {
		color: #fff;
	}

	&--wide {
		width: 100%;
	}

	&--half {
		width: 50%;
	}

	&--icon {
		padding: 2px; cursor: pointer; user-select: none; border: 0; outline: none; background: transparent;
	}
	&--icon_center {
		display: flex; align-items: center;
		svg {display: block;}
	}
	&--flip_icon_y {
		svg {transform: scaleY(-1); transform-origin: 50% 50%;}
	}

	&--no_border {
		border-color: transparent;
	}

	&--small {
		padding: 8px 17px 9px; min-width: 80px;
	}

	&--v_align_middle {vertical-align: middle;}

	&--no_padding {
		padding: 11px 0 12px;
		&.btn--small {padding: 8px 0 9px;}
	}
	&--no_minwidth {min-width: auto;}

	&--link_text {
		@include wordup(14px, inherit, $medium, 0); text-decoration: none;
		&:hover {text-decoration: none;}
	}
}

.x_close {
	display: inline-block; width: 24px; height: 24px; position: relative; cursor: pointer; user-select: none;
	&:before, &:after {
		content: ''; display: block; position: absolute; top: 50%; left: 50%; width: 133.1%; border-bottom: solid 2px #ffffff; transform-origin: center;
	}
	&:before {transform: translate(-50%, -50%) rotate(45deg); }
	&:after {transform: translate(-50%, -50%) rotate(-45deg); }
}
