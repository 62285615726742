.temp_nav {
	position: fixed; top: 0; left: 0; width: 350px; height: 100%; overflow-y: scroll; z-index: 999; padding: 30px 20px;
	transform: translate3d(-100%, 0, 0); background: $blue; @include trans(100ms);

	&__trigger {
		position: fixed; top: 44px; left: 0; padding: 5px; z-index: 998; background: $orange; cursor: pointer; user-select: none; color: #fff; font-weight: bold;
	}

	ul {
		// padding: 30px 20px;
		li {
			padding-bottom: 5px;
			&:last-child {padding-bottom: 0;}
			&.active a {
				padding-left: 10px; text-decoration: underline;
				&:before {
					content: ''; display: block; position: absolute; top: 7px; left: 0; width: 5px; border-bottom: solid 1px #fff;}
			}
			a {color: #ffffff; font-size: 14px; text-decoration: none; position: relative;}
		}
	}

	p {padding: 10px 0 5px; color: #ffffff; font-size: 14px; font-weight: bold; text-transform: uppercase;}

	&--open {
		.temp_nav {transform: translate3d(0, 0, 0);}
		.site_wrap, .login_welcome {filter: blur(4px); transition: $transition;}
	}

	.links {
		a {color: #fff; display: block; font-size: 13px; margin-bottom: 5px; text-decoration: none;}
	}
}
