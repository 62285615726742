.form_add_new {

	max-width: 760px;

	&__max_width {max-width: 760px;}

	&__step {
		padding-left: 57px; padding-bottom: 40px; position: relative;

		&:after {
			content: ''; position: absolute; top: 0; left: 10px; border-left: solid 2px #CBD2D9; height: 100%;
		}

		&:nth-child(1) {
			.form_add_new__counter {top: 10px;}
			&:after {top: 10px;}
		}

		&:last-child {
			&:after {height: 40px;}
		}

		&.active {
			.form_add_new__counter {background: $blue;}
		}

		&--number_offset {
			&:nth-child(1) {
				.form_add_new__counter {top: 37px;}
				&:after {top: 37px;}
			}
		}
	}

	&__counter {
		position: absolute; top: 36px; left: 0; background: #CBD2D9; border-radius: 100%; width: 22px; height: 22px; color: #ffffff; text-align: center; font-size: 15px; line-height: 22px; z-index: 2;
	}

	&__radio {
		.form_add_new__title {padding-bottom: 5px;}
		input {
			display: none;
			&:checked + label {
				&:before {border-color: #9AA5B0;}
				&:after {opacity: 1;}
			}
		}

		label {
			cursor: pointer; user-select: none; position: relative; display: block; padding: 30px 100px 30px 25px; border: solid 1px #DDE1E5;
			&:before {
				content: ''; display: block; position: absolute; top: 39px; right: 34px; width: 16px; height: 16px; border: solid 1px #CBD2D9; border-radius: 100%;
			}
			&:after {
				content: ''; display: block; position: absolute; top: 43px; right: 38px; width: 8px; height: 8px; background: $blue; border-radius: 100%; opacity: 0;
			}
		}
	}

	&__or {color: #AAAAAA; @include wordup(20px, 34px, $normal, 0); font-style: italic; padding: 5px 25px;}

	&__title {
		@include wordup(20px, 34px, $normal, 0); color: $body; padding-bottom: 10px;
	}
	&__p {
		@include wordup(14px, 26px, $normal, 0.015em); color: #3D4C59; padding-bottom: 20px;
		&:last-child {padding-bottom: 0;}
	}

	&__border {
		padding: 30px 25px; border: solid 1px #DDE1E5;

		.form_add_new__title {padding-bottom: 20px;}

		.form_elem__wrap {margin-bottom: 0;}

		.form_elem__search {max-width: 348px; margin-bottom: 10px;}

		&--reg {
			.form_add_new__title {padding-bottom: 5px;}
		}

		&--no_pad_border {
			padding: 0; border: 0;
		}

		&--form_builder {
			.form_add_new__title {padding-left: 15px; padding-bottom: 10px;}
		}
	}

	&__owner {
		max-width: 512px; position: relative;
		p {letter-spacing: 0.0125em; color: $body;}
		.remove {position: absolute; top: 9px; right: 9px; display: block; cursor: pointer; user-select: none;}
	}

	&__code {
		background: #F5F7FA; display: block; width: 100%; border: solid 1px #D8DCE1; outline: none; resize: none; @include wordup(12px, 34px, $normal, 0em); color: #989898; padding: 20px 30px; height: 160px; margin-bottom: 20px; word-break: break-all;
	}

	&--form_builder {
		max-width: 100%;

		.form_add_new__step:nth-child(1) {
			&:after {top: 36px;}
			.form_add_new__counter {top: 37px;}
		}
	}

	&--permissions {
		max-width: 100%;
		.form_add_new__counter {top: 12px;}
		.form_add_new__step {
			padding-bottom: 54px; max-width: 955px;
			&.form_add_new__step--permissions_sets {max-width: $max-width;}
		}
		.form_add_new__step:last-child:after {height: 30px;}
	}

	&--calls {
		max-width: 100%;
		.form_add_new__counter {top: 38px;}
		.form_add_new__step {max-width: 955px;}
	}

}

.shared_owner {
	max-width: 512px;

	&__add {
		border-top: solid 1px #E4E7EA; padding: 14px 0 13px; margin-top: 23px; @include wordup(14px, 17px, $normal, 0.0125em); color: $blue; cursor: pointer; user-select: none;
	}

	&__owner {
		display: flex; margin-bottom: 16px;
		&:last-child {margin-bottom: 0;}
		.form_elem__search {width: calc(100% - 164px); margin-bottom: 0;}
		.form_elem__select {width: 120px; margin-left: 8px; margin-right: 8px;}
	}

	&__remove {
		display: flex; align-items: center; justify-content: center; width: 28px;
		svg {cursor: pointer; user-select: none;}
	}

	&__user {
		width: calc(100% - 164px); display: flex; align-items: center;
		p {letter-spacing: 0.0125em; color: $body;}
	}

	&--media {
		.shared_owner {
			&__add {border-top: 0; margin-top: 0; padding-top: 0;}
		}
	}

	&--wide {width: 100%;}
	&--mr_auto {margin-right: auto;}
}

.fdf {
	&__link {
		padding-top: 30px;
		&.active, &:hover {
			svg g {fill: $blue;}
		}
		svg {
			cursor: pointer; user-select: none;
			g {transition: $transition;}
		}
	}

	&__row {
		display: flex; justify-content: space-between; padding-top: 20px; margin-top: 20px; border-top: solid 1px #D8DDE2;
		&:first-child {margin-top: 0;}

		.form_elem__label {font-size: 12px;}

		.form_elem__wrap {
			&:first-of-type {
				width: 175px;
				.form_elem__label {margin-bottom: 8px;}
			}
			&:last-of-type {width: 274px;}
		}
	}

	&__inner {width: 100%; max-width: 640px; display: flex; justify-content: space-between;}

}
