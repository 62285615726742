.lead_scoring {
	&__slider_wrap {
		padding: 23px; border: solid 1px #E1E4E8; margin-bottom: 26px;
	}

	&__intro {
		padding-bottom: 39px; @include wordup(14px, 26px, $normal, 0.014em); max-width: 695px;
	}
}

.lead_scoring_slider {
	padding-bottom: 23px;
	&__title {
		color: #7E8996; @include wordup(12px, 18px, $normal, 0.014em); text-align: center; padding-bottom: 10px;
	}
	&__wrap {
		position: relative; height: 16px; width: 100%;
	}
	&__slide {
		display: block; border: solid 1px #B5BDC5; background: #fff; border-radius: 100%; width: 16px; height: 16px; position: absolute; box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16); cursor: pointer; user-select: none; z-index: 10;
		transform: translateX(-50%);
		.lead_scoring_slider__value {
			display: inline-block; left: 50%; transform: translateX(-50%); cursor: auto;
		}
	}
	&__bar {
		height: 8px; width: 100%; display: block; z-index: 0; position: absolute; left: 0; top: 4px; border-bottom: solid 2px;
		&--blue {
			background: #7CC4FA; border-color: #47A3F3; z-index: 3; border-top-left-radius: 3px; border-bottom-left-radius: 3px;
			.lead_scoring_slider__info {
				&:before {border-color: #7CC4FA transparent transparent transparent;}
				&:after {background: #7CC4FA;}
			}
		}
		&--yellow {
			background: #F7C948; border-color: #DE911D; z-index: 2;
			.lead_scoring_slider__info {
				&:before {border-color: #F7C948 transparent transparent transparent;}
				&:after {background: #F7C948;}
			}
		}
		&--red {
			background: #F35627; border-color: #C52706; border-top-right-radius: 3px; border-bottom-right-radius: 3px;
			.lead_scoring_slider__info {
				&:before {border-color: #F35627 transparent transparent transparent;}
				&:after {background: #F35627;}
			}
		}
		&--multi {
			border-bottom: 0; border-radius: 3px;
			background: rgb(100,213,172);
			background: linear-gradient(90deg, rgba(100,213,172,1) 0%, rgba(246,200,71,1) 50%, rgba(242,85,38,1) 100%);

			// .lead_scoring_slider__info {
			// 	&:before {border-color: #F35627 transparent transparent transparent;}
			// 	&:after {background: #F35627;}
			// }
		}
		&:hover {
			.lead_scoring_slider__info {
				opacity: 1; visibility: visible; transform: translateX(-50%) translateY(0px); transition: $transition;
			}
		}
	}
	&__value {
		color: #1F2933; @include wordup(12px, 18px, $normal, 0.014em); position: absolute; bottom: -27px;
		&--min {left: 0; transform: translateX(-50%);}
		&--max {right: 0; transform: translateX(50%);}
	}
	&__info {
		@include wordup(11px, 13px, $normal, 0.014em); color: #fff; position: absolute; bottom: 6px; left: 50%; padding: 5px 8px 11px; cursor: pointer; user-select: none; z-index: 1;
		opacity: 0; visibility: hidden; transition: $transition; transform: translateX(-50%) translateY(5px);
		strong {font-weight: $medium;}
		&:before {
			content: ''; width: 0; height: 0; border-style: solid; border-width: 7px 9px 0 0; position: absolute; bottom: 0; left: 0;
		}
		&:after {
			content: ''; width: 100%; height: calc(100% - 7px); border-radius: 4px; border-bottom-left-radius: 0; display: block; position: absolute; top: 0; left: 0; z-index: -1;
		}
	}
}

.ls_slider {
	display: flex; justify-content: space-between; margin-top: 30px;

	&__tile {
		width: 30%; padding: 37px 32px 41px 36px; display: flex; justify-content: space-between; flex-wrap: wrap; position: relative; z-index: 1;
		&:before, &:after {
			content: ''; position: absolute; top: 0; left: 0; height: 100%;
		}
		&:before {
			 width: 4px; border-top-left-radius: 2px; border-bottom-left-radius: 2px; z-index: -1;
		}
		&:after {
			width: 100%; border-radius: 2px; border: solid 1px #CBD2D9; z-index: -2;
		}

		&--cold {
			&:before {background: #7CC4FA;}
			.ls_slider__points {color: #7CC4FA;}
		}

		&--warm {
			&:before {background: #F7C948;}
			.ls_slider__points {color: #F7C948;}
		}

		&--hot {
			&:before {background: #F35627;}
			.ls_slider__points {color: #F35627;}
		}
	}

	&__title {
		padding-bottom: 16px; line-height: 21px; width: 100%;
	}

	&__points {position: absolute; top: 10px; right: 12px; @include wordup(12px, 15px, $medium, 0.014em);}

	&__input {
		display: flex;
		input {
			width: 41px; height: 41px; @extend .form_elem__input; font-size: 14px; padding: 0 10px; border-top-right-radius: 0; border-bottom-right-radius: 0;
		}
	}

	&__input_symbol {
		background: #F5F7FA; width: 40px; height: 41px; display: block; color: #63707E; border: solid 1px #B5BDC5; border-radius: 2px; border-left: 0; border-top-left-radius: 0; border-bottom-left-radius: 0; @include wordup(14px, normal, $normal, 0.025em); display: flex; align-items: center; justify-content: center;
	}
}

.ls_tabs {
	border: solid 1px #E1E4E8; margin-bottom: 40px;

	&__head {
		border-bottom: solid 1px #E1E4E8; display: flex; padding: 0 24px;
	}

	&__tab {
		padding: 0 0 0 48px;

		&:first-child {padding-left: 0;}

		p {
			position: relative; padding: 19px 0; cursor: pointer; user-select: none;
			&:before {
				content: ''; position: absolute; bottom: 0; left: 0; border-bottom: solid 2px $blue; width: 100%; opacity: 0; visibility: hidden; transition: $transition;
			}
			&:hover {
				&:before {opacity: 1; visibility: visible; transition: $transition;}
			}
		}

		&--active {
			p:before {
				opacity: 1; visibility: visible; transition: $transition;
			}
		}
	}

	&__body {
		padding: 20px 24px;
	}

	&__add {
		color: $blue; @include wordup(14px, 17px, $normal, 0.0125em); cursor: pointer; user-select: none;
		svg {margin-right: 2px;}
	}

	&__add_rule {
		height: 68px; display: flex; align-items: center; justify-content: center; cursor: pointer; user-select: none;
		// border: dashed 1px #B5BDC5;
		background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23B5BDC5' stroke-width='1' stroke-dasharray='5' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

		span, svg {vertical-align: middle; display: inline-block;}
		svg {height: 21px; width: 21px; margin-right: 5px;}
		span {padding-left: 5px;}
	}

	&__content {
		table {
			width: 100%; margin: 13px 0;
			tr {
				td {
					padding-bottom: 24px;
					// &:nth-child(1) {width: 20%;}
					&:nth-child(2) {width: 172px;}
					// &:nth-child(3) {width: 20%;}
					&:nth-child(4) {width: 117px;}
					&:nth-child(5) {width: 41px;}
					&:nth-child(6) {width: 26px;}
				}
				&:last-child td {padding-bottom: 0;}
			}
		}
		.form_elem__select {margin-right: 8px;}
		.form_elem__input {font-size: 14px;}
	}

	&__delete {padding-left: 15px;}

	&--media {
		border: 0; margin-bottom: 0;
		.ls_tabs {
			&__tab {
				padding: 0 0 0 58px;
				&:first-child {padding-left: 0;}
			}
			&__head {padding: 0 30px;}
			&__body {padding: 30px;}
		}
	}
}

.lead_score_graphic {
	position: relative; display: inline-block;
	&__percent {position: absolute; top: 3px; right: -4px; color: #1F2933; @include wordup(5px, 6px, $normal, 0.013em);}
	&__val {
		position: absolute; bottom: 3px; left: 0; width: 100%; text-align: center; @include wordup(16px, 21px, $normal, 0.013em); color: #1F2933;
	}
	&__number {position: relative; left: -1px;}

	&__label {
		color: #7E8996; @include wordup(12px, 18px, $normal, 0.014em); position: absolute; bottom: 0; left: 0; width: 100%; text-align: center;
	}

	&--detail {
		padding-bottom: 10px;
		.lead_score_graphic {
			&__val {bottom: 17px; font-size: 32px; line-height: 41px;}
			&__percent {font-size: 11px; line-height: 14px; top: 6px; right: -9px;}
		}
	}
}

.ls_bar {
	display: flex;

	&__text {
		@include wordup(14px, 17px, $normal, 0.014em); color: #7E8996;
		a {color: $blue; text-decoration: none;}
	}
}

.ls_chart {
	&__item {
		padding: 30px; border: solid 1px #DBE1E8; margin-bottom: 30px; max-width: 970px;
		&--engagement {
			padding: 15px; margin-bottom: 0;
			.ls_chart__heading {padding-bottom: 17px;}
			.ls_chart_key {
				height: auto; width: auto;
				position: relative; top: -10px; // NOTE - temp, shadow of img offset ?
				&__item {width: 100%;}
			}
		}
	}

	&__cont {display: flex; align-items: center;}
	&__wrap {
		position: relative;
		&--pie {padding-right: 48px;}
	}

	&__heading {@include wordup(16px, 34px, $normal, 0); color: #1F2933; padding-bottom: 50px;}
}

.ls_chart_key {
	display: flex; flex-wrap: wrap; height: 163px; width: 370px; flex-direction: column; margin: -14px 0; align-content: space-between;
	&__item {
		position: relative; display: flex; align-items: baseline; width: 48%; margin: 14px 0;
		&--yellow {
			color: #B44D12;
			.ls_chart_key__colour {background: #F0B52C;}
		}
		&--purple {
			color: #44056E;
			.ls_chart_key__colour {background: #7B18CC;}
		}
		&--green {
			color: #2C4004;
			.ls_chart_key__colour {background: #94C844;}
		}
		&--blue {
			color: #01337D;
			.ls_chart_key__colour {background: #48A2F1;}
		}
	}
	&__colour {background-color: inherit; width: 4px; height: 13px; display: block; position: absolute; top: 3px; left: 0;}
	&__value {padding-left: 8px; color: inherit; @include wordup(16px, 18px, $normal, 0.04em);}
	&__name {padding-left: 4px; @include wordup(12px, 18px, $normal, 0.014em);}

	&__device {
		width: 25px; position: absolute; top: 0; left: -35px; display: flex; justify-content: center;
		svg {display: block;}
	}

	&--device {
		height: auto; width: auto;
		.ls_chart_key {
			&__item {width: 100%; margin-left: 20px;}
		}
	}

	&--one_col {
		height: auto;
		.ls_chart_key {
			&__item {width: 100%; margin: 10px 0;}
			&__name {padding-left: 20px;}
		}
	}
}
