.notification_sidebar {
	position: fixed; top: 0; right: 0; width: 400px; height: 100%; background: #FFFFFF; z-index: 3; transform: translate3d(100%, 0, 0); border-left: solid 1px #707070;
	transition: $transition;

	&__bg {
		position: fixed; top: 0; left: 0; width: 100%; height: 100%; user-select: none; cursor: pointer; z-index: 2; background: #1F2933;
		opacity: 0; visibility: hidden; transition: $transition;
	}

	&__header {
		background: $bg_grey; padding: 16px; position: relative; border-bottom: solid 1px $border-grey;

		.rcon--close {position: absolute; top: 22px; right: 13px;}
		.heading__secondary {padding-bottom: 7px;}
		.form_elem__select {width: 86px;}
	}

	&__info {
		background: $bg_grey; padding: 8px 16px; display: flex; justify-content: space-between; align-items: center; border-bottom: solid 1px $border-grey;

		.form_elem__wrap {width: 30px;}
	}

	&__body {
		position: relative; display: flex; flex-direction: column;
	}

	&__scroll {
		overflow-y: scroll; overflow-x: hidden;
	}

	&__item {
		border-bottom: solid 1px $border-grey; padding: 14px 16px; display: flex; transition: $transition;
		.form_elem__wrap {width: 33px;}

		&:hover {
			.notification_sidebar__title {transition: $transition; text-decoration: underline;}
		}

		&--unread {
			background: #E5F6FF;
		}
	}

	&__content {
		width: 290px;
	}

	&__date {
		margin-left: auto;
	}

	&__title {
		@include wordup(14px, 20px, $normal, 0.015em); color: $body; padding-bottom: 3px; margin-top: -2px; cursor: pointer; user-select: none;
	}
	&__text {
		@include wordup(12px, 16px, $normal, 0.015em); color: #6E7A87; padding-bottom: 3px;
		&:last-of-type {padding-bottom: 0;}
		&--blue {color: $blue;}
	}

	&__tier2 {
		position: fixed; top: 0; left: 100%; height: 100%; background: #FFFFFF; z-index: 3; transform: translate3d(100%, 0, 0); padding-right: 400px; transition: $transition;
		width: 1149px; // width: 80%;
	}
}

.ntier2 {
	&__cont {
		height: 100%;
	}
	&__header {
		padding: 17px 55px; background: #F5F7FA;
	}

	&__body {
		padding: 40px 55px 0; display: flex; flex-direction: column;

		.heading__secondary {padding-bottom: 8px; margin-bottom: 20px; border-bottom: solid 1px #E4E7EA;}
	}

	&__scroll {overflow-y: auto; overflow-x: hidden;}

	&__trigger {
		position: absolute; top: 16px; left: 0; transform: translateX(-100%); background: #CBD2D9; border-top-left-radius: 15px; border-bottom-left-radius: 15px; width: 26px; height: 34px; cursor: pointer; user-select: none; opacity: 0; visibility: hidden;
		svg {display: block; position: absolute; top: 50%; left: 56%; transform: translate(-50%, -50%);}
	}

	&__block {
		padding: 22px 0 22px 22px; border-bottom: solid 1px #E4E7EA; position: relative;

		&--tags {
			padding-left: 0; display: flex; align-items: center; justify-content: space-between;
			p, a {
				@include wordup(12px, 15px, $normal, 0.014em);
			}
			a {text-decoration: none; color: $blue;}
		}

		&--attendees {
			.ntier2__icon {top: 32px; transform: none;}
			.form_elem__wrap {margin-bottom: 0; padding-top: 20px;}
			.form_elem__label {margin-bottom: 0; color: #1F2933;}
			.form_elem__checkbox + label {font-size: 14px;}
		}

		&--reminder {
			.ntier2__icon {top: 24px; transform: none;}
			.event_date_time__checkbox {position: absolute; top: 23px; right: 0; width: auto; padding-left: 0;}
		}

		&--location {
			display: flex; justify-content: space-between; align-items: center;
			.form_elem__input {width: calc(100% - 170px);}
			.form_elem__toggle {width: auto;}
		}

		&--location, &--agenda {
			padding-top: 10px; padding-bottom: 10px;
			.form_elem__input {border: 0; padding: 0 5px; font-size: 14px;}
		}

		&--actions {
			padding-top: 32px;
			.btn--delete {padding-left: 0; padding-right: 0; min-width: 0;}
		}

		&--call_heading {
			position: relative; padding: 0; border-bottom: 0;
			.heading__secondary {border-bottom: 0; padding-bottom: 0; margin-bottom: 0;}
			.btn {position: absolute; top: -2px; right: 0;}
		}

		&--call_details {
			padding-left: 0;
			.form_elem__input {font-size: 14px;}
		}

		&--call_inputs {
			display: flex; flex-wrap: wrap; max-width: 578px;
			.form_elem__wrap {
				width: 47%; margin-left: 6%; margin-bottom: 0; margin-top: 18px;
				&:nth-child(odd) {margin-left: 0;}
				&:nth-child(-n+2) {margin-top: 0;}
			}
		}

		&--add_note {
			padding-left: 0;
			textarea.form_elem__input {
				font-size: 14px; line-height: 22px; height: 115px; padding: 12px 15px;
			}
		}
		&--add_note_trigger {color: #63707E; font-size: 14px; line-height: 17px; letter-spacing: 0.025em; cursor: pointer; user-select: none;}
		&--note_wrap {
			margin-top: -16px;
			p {text-align: right; font-size: 12px; line-height: 20px; letter-spacing: 0.016em; color: #7E8996; padding-bottom: 3px;}
			.btn__group {padding-top: 20px;}
		}

		&--task_priority {
			padding: 0; border: 0;
			.heading__secondary {margin-bottom: 0;}
			.event_date_time__checkbox {position: absolute; top: 8px; right: 0; width: auto; padding-left: 0;}
		}

		&--task_inputs {
			max-width: 348px;
			.form_elem__wrap:last-child {margin-bottom: 0;}
		}

		&--no_border {border-bottom: 0;}
		&--no_pb {padding-bottom: 0;}

	}

	&__icon {
		position: absolute; top: 50%; left: 0; display: block; transform: translateY(-50%);
	}
}

// Blur elements when active
.notifications_active {
	.site_wrap {filter: blur(4px);}
	body {position: relative; overflow: hidden;}

	.notification_sidebar {
		transform: translate3d(0, 0, 0); transition: all 400ms cubic-bezier(0.17, 0.67, 0.64, 0.96);
		&__bg {opacity: 0.5; visibility: visible;}

		&__tier2 {
			transform: translate3d(-400px, 0, 0); transition: all 400ms cubic-bezier(0.17, 0.67, 0.64, 0.96);
		}
	}
}

.ntier2_active {
	.notification_sidebar {
		&__tier2 {
			transform: translate3d(-100%, 0, 0); transition: all 400ms cubic-bezier(0.17, 0.67, 0.64, 0.96);
		}
	}
	.ntier2__trigger {opacity: 1; visibility: visible;}
}


.event_tags {
	margin: 0 -4px; display: flex; flex-wrap: wrap;
	&__item {
		margin: 2px 4px; border-radius: 4px; border: solid 1px #CBD2D9; padding: 4px 10px 5px 20px; display: block; position: relative; transition: $transition; cursor: pointer; user-select: none;
		p {color: #3C4C5A; @include wordup(14px, normal, $normal, 0.014em);}

		&:before {
			content: ''; position: absolute; top: 10px; left: 7px; width: 6px; height: 6px; border-radius: 100%;
		}

		&--purple {
			&:before {background: $purple;}
			&.event_tags__item--active {
				background: $purple-fill; border-color: $purple-fill;
			}
		}
		&--green {
			&:before {background: $green;}
			&.event_tags__item--active {
				background: $green-fill; border-color: $green-fill;
			}
		}
		&--pink {
			&:before {background: $pink;}
			&.event_tags__item--active {
				background: $pink-fill; border-color: $pink-fill;
			}
		}
		&--yellow {
			&:before {background: $rcal_video_call;}
			&.event_tags__item--active {
				background: $rcal_video_call_light; border-color: $rcal_video_call_light;
			}
		}
	}
}

.event_attendees {
	margin: 0 -9px -14px; display: flex; flex-wrap: wrap;

	&__person {
		border-radius: 50px; max-width: 243px; height: 38px; padding: 0 38px 2px 50px; position: relative; display: flex; justify-content: center; flex-direction: column; margin: 0 9px 14px;
	}

	&__email {@include wordup(12px, 15px, $normal, 0.028em);}

	&__status {@include wordup(10px, 12px, $normal, 0.028em); color: #7E8996;}

	&__img {
		position: absolute; top: 0; left: 0; border-radius: 100%; width: 38px; height: 38px; overflow: hidden;
		img {position: absolute; top: 0; left: 0; width: 100%; height: 100%; object-fit: cover; display: block;}
	}

	&__arr {
		position: absolute; top: 12px; right: 10px; cursor: pointer; user-select: none; padding: 5px;
		svg {display: block;}
	}

	&__more {
		padding-top: 10px; margin-left: 5px;
		p {color: #6E7A87; @include wordup(12px, 15px, $normal, 0.028em);}
	}

	&--attending {background: $green-light;}
	&--not_attending {background: #E4E7EA; opacity: 0.5;}
	&--maybe_attending {background: #FFFBEA;}
	&--awaiting_response {background: #E4E7EA;}

}

.event_date_time {
	display: flex; align-items: center;

	&__select {
		color: #1F2933; position: relative; cursor: pointer; user-select: none;
		select {
			-webkit-appearance: none; outline: none; border: 0; @include wordup(14px, 17px, $normal, 0.028em); color: #1F2933; background: transparent; padding-right: 12px; position: relative; z-index: 2;
		}
		svg {position: absolute; top: 7px; right: 0; display: block; z-index: 1;}
	}

	&__date {

	}

	&__time, &__date {
		display: inline-flex; margin-left: 16px; padding-right: 12px; position: relative;
		svg {position: absolute; top: 7px; right: 0; display: block; z-index: 1;}
	}

	&__time {
		&--active {
			.f_builder__search {opacity: 1; visibility: visible;}
		}

		.f_builder__search {
			width: 138px; top: calc(100% + 10px); left: 50%; transform: translateX(-50%); z-index: 9; padding: 17px 15px 7px;
		}
	}

	&__time_val, &__date_val {
		@include wordup(14px, 17px, $normal, 0.028em); cursor: pointer; user-select: none; white-space: nowrap;
	}

	&__input {
		color: #63707E; display: flex; align-items: center; margin: -8px 0;
		&--before {margin-right: 10px;}
		&--after {margin-left: 10px;}
		.form_elem__input {width: 48px; height: 33px; text-align: center; font-size: 14px;}
	}

	&__to {color: #63707E;}

	&__checkbox {
		padding-left: 49px;
		.form_elem__checkbox + label {font-size: 14px;}
		label {color: #1F2933;}
	}

	&__week {
		display: flex; margin: -4px -2px; padding-left: 16px;
	}

	&__day {
		margin: 0 2px; position: relative;
		input {
			opacity: 0; position: absolute;
			&:checked + label {
				background: #52606D; color: #fff;
			}
		}
		label {
			display: block; width: 24px; height: 24px; border-radius: 100%; line-height: 24px; color: #1F2933; @include wordup(12px, 24px, $normal, 0.016em); transition: $transition; background: #CBD2D9; text-align: center; cursor: pointer; user-select: none;
		}
	}

	&__toggle {padding-left: 45px; margin: -6px 0;}

	&--no_margin_left {margin-left: 0;}

	p {
		@include wordup(14px, 17px, $normal, 0.028em); padding-left: 16px;
		&:first-child {padding-left: 0;}
	}

	.form_elem__label {color: #1F2933; margin-bottom: 0;}
}

.edt {
	display: flex; justify-content: space-between;
	&:before {
		content: ':'; position: absolute; top: 24px; left: 50%; transform: translateX(-50%); @include wordup(14px, 17px, $normal, 0);
	}

	&__hour, &__minute {
		width: 42px;
		a {@include wordup(12px, 15px, $normal, 0.025em); margin-top: 8px; text-decoration: none; display: block;}
	}
	&__hour {
		a {color: #7E8996;}
	}
	&__minute {
		a {color: $blue; text-align: right;}
	}

	.form_elem__input {font-size: 14px; line-height: 32px; height: 32px; text-align: center;}
}

.reminder {
	&__wrap {
		display: flex; flex-wrap: wrap;
		.event_date_time__select {line-height: 17px; margin-left: 5px;}
	}
	&__p {@include wordup(14px, 17px, $normal, 0.028em); color: #1F2933; padding-bottom: 6px;}
	&__add {@include wordup(12px, 15px, $normal, 0.028em); color: #6E7A87; cursor: pointer; user-select: none;}
}
