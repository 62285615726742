.events {
	display: flex; justify-content: space-between; width: 100%;

	&__sidebar {
		padding-top: 11px; width: 270px;

		.form_elem__toggle--small label {font-weight: $medium;}
	}

	&__main {width: calc(100% - 310px);}

	&__header {
		display: flex; justify-content: space-between; padding-bottom: 24px;
	}

	&__active {
		display: flex; align-items: center;
		p {@include wordup(24px, 31px, $normal, 0); color: #1F2933; padding: 0 32px;}
	}

	&__paginate {
		display: flex; align-items: center; justify-content: space-between; width: 47px;
		&--back, &--forward {
			padding: 2px; cursor: pointer; user-select: none;
			svg {display: block;}
		}
	}

	&__controls {
		display: flex;
		.btn--outline {
			font-weight: $normal; letter-spacing: 0.014em; padding: 10px 20px 11px; min-width: 89px;
			&:hover, &.active {
				background: #E4E7EA; color: #63707E;
			}
		}
	}
}

.event_calendar {
	max-width: 190px; border-radius: 2px; border: solid 1px #CBD2D9;

	&__heading {
		position: relative; background: #F5F7FA; padding: 7px 10px 8px;
		p {@include wordup(14px, 17px, $normal, 0.014em); color: #1F2933;}
		.btn--icon {
			display: inline-block;
			svg {display: block;}
		}
	}

	&__controls {
		position: absolute; top: 9px; right: 9px; width: 29px; display: flex; justify-content: space-between;
		&--left, &--right {
			@extend .btn--icon;
		}
		svg {display: block;}
	}

	&__body {
		padding: 5px 8px;
	}

	&__table {
		width: 100%;

		thead {
			th {
				@include wordup(12px, 11px, $normal, 0.016em);
			}
		}

		tbody {
			td {@include wordup(11px, 11px, $normal, 0.018em);}
		}

		th, td {
			color: #1F2933; text-align: center; width: 14.28%; padding: 6px 5px;

			&.event_calendar--grey {color: #9AA5B0;}
			&.event_calendar--orange {
				color: #fff;
				span:before {background: $orange}
			}

			span {
				position: relative; z-index: 2; cursor: pointer; user-select: none;
				&:before {
					content: ''; border-radius: 100%; width: 18px; height: 18px; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: -1; transition: $transition;
				}
			}
		}

		td {
			transition: $transition;
			&:hover {
				color: #fff;
				span:before {background: $blue}
			}
		}
	}

	&--full_height {

		& > .container {height: 100%;}

		.events__main {height: 100%; display: flex; flex-direction: column;}
		.r_cal_month {height: 100%;}
		.r_cal_month__days {height: calc(100% - 23px);}
		// .r_cal_month__day {height: 16.66%;}

		@media only screen and (min-height: 881px) {
			height: calc(100vh - 44px);
			.r_cal_month__day {height: 16.66%;}
		}
	}

	&--auto_height {height: auto;}
}

.event_accordion {
	&__trigger {
		cursor: pointer; user-select: none; padding: 5px 20px; position: relative;
		p {@include wordup(14px, 23px, $medium, 0.0125em); color: #3C4C5A;}
	}
	&__arr {
		position: absolute; top: 14px; left: 0;
		svg {display: block; transform-origin: center; transform: rotate(-90deg);}
	}
	&__key {
		font-weight: $bold; color: #63707E;	font-size: 12px; padding-left: 3px;
	}

	&__content {
		padding: 10px 20px; display: none;
	}

	&--open {
		.event_accordion {
			&__arr svg {transform: rotate(0);}
			&__content {display: block;}
		}
	}
}

$cal: #E4E7EA;

$annual-bg: #F9E1E9;
$annual-dot: #E8368F;
$annual-time: #A30664;

$meeting-bg: #E9DDFD;
$meeting-dot: #871AE0;
$meeting-time: #6C4C85;

$personal-bg: #E5F5CC;
$personal-dot: #7BB026;
$personal-time: #5A6F34;

.r_cal_month {

	&__header {
		border-top: solid 2px #CBD2D9; display: flex; height: 23px; position: relative; z-index: 1;
		&--day {
			padding: 6px 0 0 7px; width: calc(100% / 7); border-left: solid 1px $cal;
			&:last-child {border-right: solid 1px $cal;}
			span {@include wordup(12px, 15px, $normal, 0.025em); color: #1F2933;}
		}
	}

	&__days {
		display: flex; flex-wrap: wrap;
	}

	&__date {
		padding: 2px 5px 6px; position: relative; z-index: 2; cursor: pointer; user-select: none; display: block;

		&--circle {
			position: relative; @include wordup(14px, 17px, $normal, 0.01em); color: #1F2933;
			&:before {
				content: ''; border-radius: 100%; width: 24px; height: 24px; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: -1;
			}
		}
	}

	&__day {
		width: calc(100% / 7); height: 114px; border-left: solid 1px $cal; border-bottom: solid 1px $cal; padding: 3px; position: relative;
		&:before {
			content: ''; position: absolute; bottom: 0; left: 0; width: 100%; height: 100%; transition: $transition;
		}
		&:nth-child(-n+7):before {height: calc(100% + 23px);}
		&:nth-child(7n) {border-right: solid 1px $cal;}

		&:hover {
			&:before {background: #E5F6FF;}
			.r_cal_month__date--circle {color: #03449F;}
		}

		&--today {
			.r_cal_month__date--circle {
				color: #fff;
				&:before {background: $orange;}
			}
			&:hover {
				.r_cal_month__date--circle {color: #fff;}
			}
		}

		&--other_month {
			.r_cal_month__date--circle {color: #9AA5B0;}
		}
	}
}

.r_cal_entries {
	&__wrap {
		margin: -1px; display: flex; flex-direction: column; position: relative;

		&--hour {
			& > .r_cal_entries__item {margin-bottom: 3px;}
		}

		&--nested {
			padding-top: 4px;
			.r_cal_entries__item {border: solid 1px #fff;}
		}

		&--inline {display: flex; margin-bottom: 2px;}
		&--two_col {
			.r_cal_entries__item {width: calc(50% - 2px);}
		}
		&--three_col {
			.r_cal_entries__item {width: calc(33.33% - 2px);}
		}
		&--stretch {
			margin-bottom: 0;
			.r_cal_entries__item {height: 100%;}
		}

	}

	&__item {
		padding: 2px 6px 2px 4px; border-radius: 2px; position: relative; margin: 1px; z-index: 1; height: 20px; cursor: pointer; user-select: none; text-align: left;

		&:after {
			content: ''; display: none; position: absolute; top: 0%; width: 100%; height: 100%; z-index: -1;
		}

		&--extend_left {
			&:after {display: block; left: -4px;}
		}
		&--extend_right {
			&:after {display: block; right: -4px;}
		}
		&--extend_both {
			&:after {display: block; left: -4px; width: calc(100% + 8px);}
		}

		&--one_hour {
			height: 43px;
			.r_cal_entries__title {white-space: normal;}
		}
		&--custom_duration {
			display: flex; flex-direction: column;
			.r_cal_entries__title {white-space: normal;}
		}

		&--annual_leave {
			background: $annual-bg;

			> .r_cal_entries__title {
				&:before {background: $annual-dot;}
				.r_cal_entries__time {color: $annual-time;}
			}

			&:after {background: $annual-bg;}
		}
		&--meeting {
			background: $meeting-bg;

			> .r_cal_entries__title {
				&:before {background: $meeting-dot;}
				.r_cal_entries__time {color: $meeting-time;}
			}

			&:after {background: $meeting-bg;}
		}
		&--personal {
			background: $personal-bg;

			> .r_cal_entries__title {
				&:before {background: $personal-dot;}
				.r_cal_entries__time {color: $personal-time;}
			}

			&:after {background: $personal-bg;}
		}

		&--video_call {
			background: $rcal_video_call_light;

			> .r_cal_entries__title {
				&:before {background: $rcal_video_call;}
				.r_cal_entries__time {color: $rcal_video_call;}
			}

			&:after {background: $rcal_video_call_light;}
		}

		&--partial_day {
			background-color: transparent;
		}
	}

	&__title {
		@include wordup(12px, 15px, $normal, 0.014em); color: #1F2933; position: relative; padding-left: 8px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;
		&:before {
			content: ''; display: block; position: absolute; top: 6px; left: 1px; width: 4px; height: 4px; border-radius: 100%;
		}

		&--text {
			 overflow: hidden; white-space: nowrap; text-overflow: ellipsis; position: relative;
		}

		&--block {
			.r_cal_entries__title--text, .r_cal_entries__time {display: block;}
		}
	}

	&__time {
		overflow: hidden; white-space: nowrap; text-overflow: ellipsis; position: relative;
		&--smaller {
			font-size: 11px; line-height: 14px; letter-spacing: 0.015em;
		}
	}

	&__key {
		font-weight: $bold; font-size: 8px; color: #63707E; padding-right: 3px;
	}

	&__more {
		@include wordup(12px, 15px, $normal, 0.014em); color: #7E8996; margin-top: 5px; display: block;
		&--dark {color: #1F2933;}
	}
}

.r_cal_week {

	&__header {
		border-top: solid 2px #CBD2D9; display: flex;
		&--col {
			padding: 6px 3px 3px; width: calc(100% / 7); border-left: solid 1px $cal; border-bottom: solid 1px $cal;
			&:last-child {border-right: solid 1px $cal;}
		}
		&--day {
			display: block; @include wordup(12px, 15px, $normal, 0.025em); color: #1F2933; margin-bottom: 4px; text-align: center;
		}
		&--date {
			display: block; @include wordup(16px, 21px, $normal, 0.025em); color: #1F2933; position: relative; cursor: pointer; user-select: none; text-align: center; width: 22px; height: 22px; margin: 0 auto;
			&:before {
				content: ''; border-radius: 100%; width: 22px; height: 22px; position: absolute; top: 0; left: 0; z-index: -1;
			}
		}
		&--all_day {
			margin-top: 6px;
		}

		&--today {
			color: #fff;
			&:before {background: $orange;}
		}
	}

	&__days {
		display: flex;
	}

	&__day {
		border-left: solid 1px $cal; width: calc(100% / 7); counter-reset: hour;
		&:first-child {
			.r_cal_week__hour {
				&:not(:first-child) {
					counter-increment: hour;
				}
				&:before {
					content: counters(hour, ":", decimal-leading-zero) ':00';  display: block;
				}
			}
		}
		&:last-child {border-right: solid 1px $cal;}
	}

	&__hour {
		height: 48px; border-bottom: solid 1px $cal; position: relative; padding: 2px; //z-index: 1;
		&:before {
			@include wordup(10px, 12px, $normal, 0); color: #1F2933; position: absolute; right: 100%; padding-right: 5px; transform: translateY(-50%);
		}
		&:after {
			content: ''; display: block; position: absolute; top: 50%; left: 0; width: 100%; z-index: -1; border-top: dotted 1px $cal;
		}
	}

	&__current_time {
		position: absolute; left: 0; top: 0; display: block; border-bottom: solid 1px $orange; width: 100%;
	}

	&--day_view {
		.r_cal_week {
			&__day {width: 100%;}
			&__header {
				&--col {width: 100%; padding-left: 11px; padding-right: 11px;}
				&--day {text-align: left;}
				&--date {display: inline-block;}
			}
		}
	}

}

.r_cal_event_list {

	border-top: solid 1px #CBD2D9;

	&__day {
		margin-bottom: -1px;

		&--open {
			padding: 40px 0;
			&:first-child {padding-top: 0;}

			.r_cal_event_list {
				&__events {display: block;}
				&__heading_arr svg {transform: rotate(0);}
			}

			& + .r_cal_event_list__day--open {padding-top: 0;}
		}
	}

	&__heading {
		padding: 17px 16px; background: #F2F8FD; border-top: solid 1px #DBE1E8; border-bottom: solid 1px #DBE1E8; display: flex; align-items: center; position: relative; cursor: pointer; user-select: none;
	}
	&__heading_text {color: #1F2933; @include wordup(16px, 21px, $normal, 0.013em);}
	&__heading_qty {color: #7E8996; @include wordup(11px, 14px, $normal, 0); margin-left: 12px;}
	&__heading_arr {
		position: absolute; top: 50%; transform: translateY(-50%); right: 16px; display: block;
		svg {display: block; transform: rotate(180deg); transform-origin: center;}
	}

	&__events {
		display: none;
		table {
			width: 100%;
			td {
				padding: 18px 8px; border-bottom: solid 1px #DBE1E8;

				&.r_cal_event_list__events {
					&--time {
						padding-left: 16px; width: 90px; color: #1F2933; @include wordup(11px, 14px, $normal, 0.014em); text-transform: uppercase;
					}
					&--tag {width: 110px;}
					&--title {color: #3D4C59; @include wordup(14px, 16px, $normal, 0.0125em);}
				}
			}
		}

		.r_cal_entries__wrap {display: inline-flex;}


	}
}

.r_cal_invite_info {
	padding: 15px 0;

	&__item {
		padding-left: 22px; margin-bottom: 34px; position: relative;
		&:last-child {margin-bottom: 0;}

		p {
			color: #3C4C5A; @include wordup(14px, 17px, $normal, 0.028em);

			&.r_cal_invite_info {
				&__attendee {color: #1F2933;}
				&__more {color: #7E8996; @include wordup(12px, 15px, $normal, 0.028em); cursor: pointer; user-select: none;}
			}
		}

		&--who {
			margin-bottom: 20px;
		}
	}

	&__icon {
		position: absolute; top: 0; left: 0;
		svg {display: block;}
	}

	&__map_link {
		color: $blue; @include wordup(12px, 15px, $normal, 0.028em); text-decoration: none; margin-top: 5px; display: block;
	}

	&__organiser {
		padding-bottom: 10px;
		p {color: #1F2933;}
		span {color: #7E8996; @include wordup(12px, 15px, $normal, 0.028em); padding-left: 5px;}
	}

	&__attendees {}
	&__attendee {padding-bottom: 10px;}

	&__attending {
		background: #F5F7FA; padding: 24px 32px 17px; border-top: solid 1px #E4E7EA; display: flex; justify-content: space-between;

		&--left {
			padding-top: 10px;
			p {color: #1F2933; @include wordup(14px, 17px, $normal, 0.028em);}
		}

		&--right {
			display: flex; flex-direction: column; justify-content: flex-end;
			.btn {font-weight: $normal;}
		}

		&--links {
			margin: 0 -12px; display: flex; justify-content: flex-end; padding-top: 13px;
			a {margin: 0 12px; color: $blue; @include wordup(12px, 15px, $normal, 0.028em); text-decoration: none;}
		}
	}

	&--add_event_popup {
		.form_elem__input_lrg {border-color: #E4E7EA;}
	}

}
