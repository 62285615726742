/*
Theme Name: PS Website Design
Theme URI: http://www.pswebsitedesign.com
Description: A minimalist theme by PS.
Author: PS Website Design Ltd
Author URI: http://www.pswebsitedesign.com
Version: 2.0
*/

// Mixins
@import "_mixins.scss";

// Variables + Reset
@import "_variables.scss";
@import "_grid_reset.scss";

// Components
@import "_form.scss";
@import "_buttons.scss";
@import "_tables.scss";
@import "_alerts.scss";

// Global
@import "_header.scss";
@import "_footer.scss";
@import "_layout.scss";
@import "_sidebar.scss";
@import "_rcons.scss"; // Custom Icons
@import "_text.scss"; // Heading Styles
@import "_modals.scss";
@import "_pagination.scss";
@import "_breadcrumb.scss";
@import "_dropdown.scss";
@import "_tooltip.scss";

// Areas
@import "_login_welcome.scss";
@import "_profile.scss";
@import "_template_builder.scss";
@import "_campaign.scss";
@import "_forms.scss";
@import "_form_builder.scss";
@import "_notifications_sidebar.scss";
@import "_lead_scoring.scss";
@import "_lead_detail.scss";
@import "_lead_add.scss";
@import "_media.scss";
@import "_settings.scss";
@import "_events.scss";
@import "_video_conference.scss";
@import "_pipeline.scss";
@import "_engagement.scss";
@import "_automation.scss";
@import "_admin.scss";
@import "_dashboard.scss";

// Activities
@import "activities/_calls.scss";

// Browser Fixes
@import "_browser_fixes.scss";

// Temp Navigation
@import "_temp_nav.scss";