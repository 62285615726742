.campaign_header {
	border-bottom: solid 1px #DBE1E8; padding-bottom: 30px; width: 100%;
	.rcon {cursor: pointer; user-select: none;}
	.heading__primary {padding-bottom: 0;}

	&--search {
		.container {
			position: relative;
			.form_elem__search {
				position: absolute; top: 50%; right: 30px; transform: translateY(-50%); width: 348px;
			}
		}
	}

	&--margin_bottom {margin-bottom: 30px;}
}

.campaign_nav {
	background: #F2F8FD; border-bottom: solid 1px #DBE1E8; box-shadow: 0 3px 6px 0 #D9DADB;
	.container {
		display: flex; justify-content: space-between; align-items: center;
	}
	ul {
		display: flex;
		li {
			padding: 24px 0; position: relative; margin-left: 58px;

			&:first-child {margin-left: 0;}

			&:before {
				content: ''; display: block; position: absolute; bottom: 0; left: 0; width: 100%; border-bottom: solid 2px $blue; transition: $transition; opacity: 0; visibility: hidden;
			}

			&.complete {
				a:before {background-image: url('../img/rcon-circle_tick_complete.svg');}
			}

			&:hover, &.active {
				&:before {opacity: 1; visibility: visible; transition: $transition;}
			}

			a {
				display: block; @include wordup(16px, 21px, $normal, 0.013em); text-decoration: none; padding-left: 20px; position: relative;
				&:before {
					content: ''; background: no-repeat center center url('../img/rcon-circle_tick_incomplete.svg'); width: 12px; height: 12px; background-size: 12px 12px; position: absolute; top: 3px; left: 0; display: block;
				}
				.blue {color: $blue;}
			}
		}
	}

	&--no_icons {
		ul {
			li {
				&.complete {background-image: none;}
				a {
					padding-left: 0;
					&:before {display: none;}
				}
			}
		}
	}

	&--add_leads {
		ul {
			li {
				&.complete {
					background-image: none;
					a {color: #1F2933;}
					svg {
						g {fill: #3EBD93; stroke: #3EBD93;}
						path {stroke: #fff;}
					}
				}
				&.active {
					a {color: #1F2933;}
				}
				a {
					padding-left: 0; color: #B5BDC5;
					&:before {display: none;}
				}
				svg {margin-left: 5px;}
			}
		}
	}
}

.campaign_actions {
	display: flex; justify-content: space-between; width: 100%; padding: 40px 0;

	&__left, &__right {
		display: flex; align-items: flex-start;

		& > div {
			margin-left: 24px;
			&:first-child {margin-left: 0;}
		}

		.form_elem__select {width: 203px;}
		.form_elem__search input {width: 308px;}
	}

	&__p {
		color: #7E8996; font-size: 14px; line-height: 17px; letter-spacing: 0.015em; padding: 0 15px;
		a {color: $blue; text-decoration: none;}
	}

	&--align_left {justify-content: flex-start;}
	&--no_pad_top {padding-top: 0;}
	&--vcenter {align-items: center;}

	&--pipeline {
		padding: 25px 0;
		
		.campaign_actions {
			&__left, &__right {
				.form_elem__select {width: auto;}
			}
		}
	}
}

.email_tiles {
	display: flex; width: 100%; flex-wrap: wrap; padding-bottom: 20px;

	&__item {
		border-radius: 2px; margin-top: 6%; cursor: pointer; user-select: none; position: relative;
		// width: 377px; margin-left: calc((100% - (377px * 3)) / 2);
		width: 28.845%; margin-left: 6.7325%;
		// min-width: 377px;
		max-width: 400px;
		box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16); transition: box-shadow 400ms cubic-bezier(0.17, 0.67, 0.64, 0.96), margin 300ms cubic-bezier(0.17, 0.67, 0.64, 0.96), opacity 200ms cubic-bezier(.17,.67,.64,.96);

		&:before {
			content: ''; position: absolute; top: 0; left: 0; width: 100%; height: 100%; border: solid 1px #CBD2D9; border-radius: 2px; display: block; pointer-events: none; z-index: 1; transition: $transition;
		}

		&:nth-child(-n+3) {margin-top: 0;}

		&:nth-child(3n+1) {margin-left: 0;}

		&:hover {
			box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.16);
		}

		figure {
			border-top-left-radius: 2px; border-top-right-radius: 2px; position: relative; overflow: hidden;
			img {display: block; width: 100%; height: auto;}
		}

		@media only screen and (min-width: 1448px) and (max-width: 1599px) {
			margin-top: calc((100% - (400px * 3)) / 2); margin-left: calc((100% - (400px * 3)) / 2);
		}

		@media only screen and (min-width: 1600px) and (max-width: 1877px) {
			width: 22%; margin-left: 4%; margin-top: 4%;
			&:nth-child(3n+1) {margin-left: 4%;}
			&:nth-child(4n+1) {margin-left: 0;}
			&:nth-child(-n+4) {margin-top: 0;}
		}

		@media only screen and (min-width: 1878px) and (max-width: 2282px) {
			width: 18%; margin-left: 2.5%; margin-top: 2.5%;
			&:nth-child(3n+1) {margin-left: 2.5%;}
			&:nth-child(5n+1) {margin-left: 0;}
			&:nth-child(-n+5) {margin-top: 0;}
		}

		@media only screen and (min-width: 2283px) {
			width: 18%; margin-top: calc((100% - (400px * 5)) / 4); margin-left: calc((100% - (400px * 5)) / 4);
			&:nth-child(3n+1) {margin-left: calc((100% - (400px * 5)) / 4);}
			&:nth-child(5n+1) {margin-left: 0;}
			&:nth-child(-n+5) {margin-top: 0;}
		}

		&--selected {
			&:before {
				border: solid 2px $green-border; transition: $transition;
			}
			.email_tiles__cat {
				border-color: #707070; transition: $transition;
			}
		}

		&--fade {opacity: 0.19;}

	}

	&__info {
		text-align: center; padding: 27px 20px 13px;
	}

	&__title {@include wordup(16px, 20px, $normal, 0.0125em); padding-bottom: 6px;}
	&__cat {
		@include wordup(14px, 16px, $normal, 0.0125em); color: #7E8996; padding-bottom: 23px; margin-bottom: 12px; border-bottom: solid 1px #E4E7EA; transition: $transition;
	}
	&__links {
		@include wordup(16px, 18px, $normal, 0.0125em); color: #7E8996;
		a {
			text-decoration: none; color: #7E8996; margin-right: 34px; position: relative;
			&:after {content: ''; height: 13px; border-left: solid 1px #7E8996; position: absolute; top: 4px; right: -17px;}
			&:last-child {
				margin-right: 0;
				&:after {display: none;}
			}
			&.email_tiles__links--edit:hover {color: $blue;}
			&.email_tiles__links--delete:hover {color: $red;}
		}
	}

	&__selected_text {
		display: flex; justify-content: center; align-items: center;
		svg {margin-right: 5px;}
	}

	&--admin {
		margin: -21px; width: auto;
		.email_tiles {
			&__item {margin: 21px; width: calc(50% - 42px);}
			&__title, &__cat {padding-left: 10px; padding-right: 10px;}
			&__links {
				a {
					font-size: 12px;
					&:after {height: 10px;}
				}
				&--edit {color: $blue;}
				&--delete {color: $red;}
			}
			&__info {padding: 10px 0;}
			&__cat {padding-bottom: 10px;}
		}
	}

}

.campaign_view {

	@media only screen and (max-width: 1040px) {
		.campaign_actions {
			flex-wrap: wrap;
			&__left, &__right {width: 100%;}
			&__left {padding-bottom: 20px;}
		}
	}

}

.campaign_lists {
	width: 100%;

	&__header {
		display: flex; padding-bottom: 14px;
		.form_elem__wrap {margin: 0; width: 40px;}
	}

	&__label {
		@include wordup(14px, 17px, $normal, 0.0125em); width: calc(50% - 20px); color: #1F2933; padding-left: 20px;
		.rcon {cursor: pointer; user-select: none; margin-left: 5px;}
	}

	&__row {
		display: flex; padding-bottom: 16px;
		.form_elem__wrap {margin: 0; width: 40px; display: flex; align-items: center;}

		&--indent {
			padding-left: 104px;
			.campaign_lists {
				// &__item:before {background: $border;}
				&__name {width: calc(50% - 124px);}
				&__subscribers {width: calc(50% + 32px);}
			}
		}
	}

	&__item {
		display: flex; align-items: center; justify-content: space-between; border: solid 1px $border; border-radius: 2px; position: relative; padding: 25px 20px; width: calc(100% - 40px);
		box-shadow: $tb_box_shadow; transition: $transition-slow;

		&:before {content: ''; position: absolute; top: 0; left: 0; height: 100%; width: 4px; background: $border; transition: $transition;}

		&.active {
			box-shadow: 0 3px 10px 0 rgba(31, 41, 51, 0.16); border-color: #3EBD93; background: $green-light;
			&:before {background: #3EBD93;}
			&:hover {
				&:before {background: #3EBD93;}
			}
			.btn--outline_clear {border-color: #3EBD93;}
		}

		&:hover {
			box-shadow: $tb_box_shadow-hover;
			&:before {background: $blue;}
		}
	}

	&__title {
		@include wordup(14px, 17px, $normal, 0.0125em); color: #1F2933; padding-bottom: 10px;
	}

	&__info {
		@include wordup(12px, 20px, $normal, 0.016em); color: #7E8996;
	}

	&__name, &__subscribers {width: calc(50% - 20px);}

	&__subscribers {
		display: flex; align-items: center;
		p {
			color: #9AA5B0;
			a {color: $blue;}
		}
		p, p a {@include wordup(14px, 17px, $normal, 0.0125em);}
		a {text-decoration: none;}
		.btn {margin-left: auto;}
		.rcon {margin-left: 40px; cursor: pointer; user-select: none;}
	}

}

.send_details {
	border: solid 1px #E4E7EA; padding: 47px; margin-top: 40px; width: 100%;

	&__input_actions {
		position: absolute; bottom: 0; right: 0; display: block; height: 40px; padding-right: 9px; display: flex; align-items: center;
	}

	&__emoji, &__tags {
		cursor: pointer; user-select: none; padding: 5px 4px;
		svg {display: block;}
	}

	&__tags {
		position: relative;
		&:hover {
			.f_builder__search {opacity: 1; visibility: visible;}
		}

		.f_builder__search {width: 370px; left: 50%; transform: translateX(-50%); top: calc(100% + 13px);}
	}

	.form_elem__wrap {
		max-width: 570px; margin-bottom: 40px;
		&:last-child {margin-bottom: 0;}
	}
	.form_elem__label svg {display: inline-block; vertical-align: middle; margin-left: 3px;}
}

.review_send {
	border-radius: 2px; border: solid 1px #E4E7EA; display: flex; width: 100%; margin-top: 40px;

	&__email {
		background: #EDEDED; width: calc(100% - 408px);
	}

	&__sidebar {
		width: 408px;
	}

	&__block {
		padding: 43px 49px; border-top: solid 1px #E4E7EA;
		&:first-child {border-top: 0;}

		&--schedule {
			.review_send__heading {padding-bottom: 20px;}
			.btn__group {margin-top: 30px;}
		}
	}

	&__info {
		padding-bottom: 43px;
		&:last-child {padding-bottom: 0;}
	}

	&__heading {@include wordup(20px, 30px, $normal, 0); color: #1F2933; padding-bottom: 5px;}

	&__sub {@include wordup(16px, 21px, $normal, 0.014em); color: #63707E; margin-top: -3px;}

	&__radio {
		margin-bottom: 10px; position: relative;
		input {
			position: absolute; top: 0; left: 0; opacity: 0;
			&:checked {
				& + label {
					&:before {border: 2px solid #3EBD93;}
					figure {background: #3EBD93;}
				}
			}
		}
		label {
			padding: 26px 30px 30px 66px; border-radius: 2px; display: block; transition: $transition; position: relative; z-index: 1;
			&:before {
				content: ''; border: solid 1px #CBD2D9; position: absolute; top: 0; left: 0; width: 100%; height: 100%; display: block; z-index: -1; transition: $transition; border-radius: 2px;
			}

			.review_send__heading {padding-bottom: 7px;}
		}
		figure {
			position: absolute; top: 47px; left: 24px; border: solid 1px #CBD2D9; border-radius: 100%; width: 18px; height: 18px; display: flex; justify-content: center; align-items: center;
		}
	}

	&__options {
		padding-top: 26px;
		.review_send__select_wrap {
			margin-bottom: 16px;
			&:last-child {margin-bottom: 0;}
		}
	}

	&__select_wrap {
		display: flex;
		p {color: #63707E; @include wordup(14px, 17px, $normal, 0.025em); padding-right: 8px;}
	}

	&__select {
		display: inline-block; position: relative;
		select {
			-webkit-appearance: none; outline: none; border: 0; font-size: 14px; line-height: 17px; font-weight: 400; letter-spacing: 0.028em; color: #1F2933; background: transparent; padding-right: 12px; position: relative; z-index: 2;
		}
		svg {
			position: absolute; top: 7px; right: 0; display: block; z-index: 1;
		}
	}
}

.records_selected {
	background: #E5F6FF; padding: 22px 30px; text-align: center; position: relative;

	&__label {
		@include wordup(16px, 28px, $normal, 0.04em); color: #00215A;
	}

	.btn {position: absolute; top: 50%; transform: translateY(-50%); right: 50px;}

}

.criteria {
	background: #F5F7FA; position: relative; padding: 22px 15px 25px; margin-bottom: 10px;

	&__wrap {padding: 10px 0 10px;}

	&__close {position: absolute; top: 8px; right: 8px; display: block; cursor: pointer; user-select: none;}

	&__logic {
		margin-bottom: 10px;
		.form_elem__select {width: 78px;}
	}

	.form_elem__wrap {
		margin-bottom: 13px;
		&:last-of-type {margin-bottom: 0;}
	}
	.form_elem__label {font-size: 12px; margin-bottom: 2px;}

}

.campaign_datepicker {

	&__wrap {
		position: relative;
	}

	&__input {
		@extend .form_elem__input; background: #F5F7FA; color: #1F2933; display: inline-flex; align-items: center; width: auto; font-size: 14px; z-index: 2; cursor: pointer; user-select: none; padding: 0 13px;
		span {padding-left: 8px;}

		&--small {
			height: 32px; line-height: 32px;
		}
	}

	&__modal {
		position: absolute; top: calc(100% + 8px); left: 0; width: 435px; padding: 20px; background: #ffffff; border: solid 1px $border1; border-radius: 2px; box-shadow: 0 0 5px 0 rgba(#1F2933, 0.16); z-index: 2;

		.form_elem__label {font-size: 12px; line-height: 14px;}

		.btn__group--grouped {
			width: 325px;
			.btn {min-width: 0; width: 65px;}
		}

		.btn__group--align_right {margin-top: 25px;}
	}

	&__days {
		margin-bottom: 20px;
	}

	&__range {
		margin-bottom: 10px;
	}
	&__picker {
		margin-top: 2px;
		img {display: block;}
	}

	&--grey {color: #7E8996;}

}

.bulk_email {
	width: 100%;

	&__item {
		border: solid 1px $border1; border-radius: 2px; box-shadow: 0 3px 5px 0 rgba(#1F2933, 0.16); padding: 46px; position: relative; display: flex; justify-content: space-between; margin-bottom: 16px;

		.btn__group {position: absolute; top: 16px; right: 21px;}

		.heading__secondary {padding-bottom: 27px; margin-top: -8px;}
	}

	&__checkbox {position: absolute; top: 21px; left: 16px;}

	&__thumbnail {
		border: solid 1px #CBD2D9; border-radius: 2px; cursor: pointer; user-select: none; align-self: flex-start;

		img {display: block;}
		figcaption {
			border-top: solid 1px $border1; color: #7E8996; text-align: center; font-size: 16px; line-height: 18px; padding: 12px 20px;
		}
	}

	&__placeholder {
		height: 274px; width: 224px; background: #EAEAEA; display: flex; align-items: center; justify-content: center;
	}

	&__thumb_wrap {
		height: 274px; width: 224px;
	}

	&__chart {
		position: relative; margin-top: 20px;
		img {display: block;}
	}
	&__chart_message {
		position: absolute; top: 43%; left: 50%; transform: translate(-50%, -50%);
		p {font-size: 20px; color: #63707E;}
		span {padding-right: 5px;}
	}

	&__body {
		width: calc(100% - 266px);
	}

	&__title {line-height: 24px; letter-spacing: 0.015em; color: #63707E;}
	&__list {color: $blue; line-height: 21px; letter-spacing: 0.015em;}

	&__text {max-width: 700px; min-height: 69px;}
}

.list_averages {
	display: flex; flex-wrap: wrap; margin: 40px -50px;

	&__item {margin: 0 50px;}
	&__title {color: #63707E; @include wordup(14px, 17px, $normal, 0.014em); padding-bottom: 3px;}
	&__percent {color: #1F2933; @include wordup(24px, 30px, $normal, 0);}
}

.list_chart {
	padding: 40px 33px; border: solid 1px #DBE1E8; border-radius: 2px;
	img {display: block;}
}
