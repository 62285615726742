.video_call {
	padding: 40px 30px;

	&__start {
		display: flex; background: #F5F7FA; height: 460px; display: flex; justify-content: center; align-items: center;
	}

	&__start_inner {
		display: flex; align-items: center; flex-direction: column;
		p {margin: 20px 0 30px; font-size: 20px; line-height: 30px; color: #63707E;}
	}
	&__start_icon {
		svg {display: block;}
	}

	&__table_block {
		padding: 30px 0;
	}

	&--max_width {max-width: 1082px;}
}
